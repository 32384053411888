import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";

import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { authenticationService } from "../../../services/auth.service";
import { PaymentType } from "../../../models/enums/PaymentType";
import IPersonEventDTO from "../../../models/IPersonEvent";
import { dateMask, displayDateTime, handleFocus, numberMask } from "../../../utils/masks";
import { getValidator } from "../../../utils/validatorsYup";
import Modal from "../../../components/modal";

interface Params {
  person?: IPersonEventDTO;
  add: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

interface IPaidPerson {
  id: number;
  paidDate: Date;
  paidValue: number;
  paymentType?: string;
}

export default function EventPayment(props: Params) {
  const { person, add, afterHandleSubmit, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState(false);
  const [isReadOnly, setReadOnly] = useState<boolean>(true);  
  const [personPaid, setPersonPaid] = useState<IPaidPerson>();

  const schema = yup.object().shape({
    paidDate: getValidator({isDate: true}),
    paidValue: yup.number().required('Valor obrigatório'),
    paymentType: yup.string().required("Tipo obrigatório")
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setReadOnly(authenticationService.isReadOnly());
    setModelOpen(add);

    if (person) {
      let paidValue = person.isIndividual ? 50 : 70;

      setPersonPaid({
        id: person.id,
        paidDate: new Date(),
        paidValue: paidValue
      });

      setValue("paidDate", personPaid?.paidDate);
      setValue("paidValue", personPaid?.paidValue);
    }
  }, [person, add]);

  const closeModal = () => {
    handleCloseModal();
    reset();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setModelOpen(false);
    afterCloseModal();
  };

  const onSubmit = async (data: IPaidPerson, e: any) => {
    try {
      if (personPaid) {
        data.id = personPaid.id;
        data.paidDate = personPaid.paidDate;
        await api.post(`/events/person/${personPaid.id}/payment`, data);
        toast.success("Pagamento realizado com sucesso!", toastOptions);
        handleCloseModal();
        afterHandleSubmit(personPaid.paidDate);
      }
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Pagamento Inscrição</h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={handleCloseModal}
        />
        <form onSubmit={handleSubmit<IPaidPerson>(onSubmit)} onReset={reset}>
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-1">
                <label className="uk-form-label" htmlFor="name">
                  {person?.name}
                </label>
              </div>
              <div className="uk-width-1-3@s">
                <label className="uk-form-label" htmlFor="paidDate">Data Pagamento</label>
                <input type="text" 
                  id="paidDate" 
                  name="paidDate" 
                  placeholder="99/99/9999" 
                  className="uk-input uk-form-small"
                  defaultValue={displayDateTime(personPaid?.paidDate)}
                  onFocus={handleFocus}
                  ref={register}
                  disabled={true}
                  onChange={(e) => { e.target.value = dateMask(e.target.value); }}
                />
                <small className="uk-text-danger">{errors.paidDate?.message}</small>
              </div>
              <div className="uk-width-1-3@s">
                <label className="uk-form-label" htmlFor="paidValue">Valor</label>
                <input type="text" 
                  id="paidValue" 
                  name="paidValue" 
                  placeholder="Valor" 
                  className="uk-input uk-form-small"
                  defaultValue={parseFloat(personPaid?.paidValue?.toString() ?? '')}
                  ref={register}
                  onFocus={handleFocus}
                  onChange={(e) => { e.target.value = numberMask(e.target.value) }}
                />
                <small className="uk-text-danger">{errors.paidValue?.message}</small>
              </div>              
              <div className="uk-width-1-3@s">
                <div className="uk-form-controls">
                  <label className="uk-form-label" htmlFor="paymentType">Pago via</label>
                  <div
                    uk-form-custom="target: > * > span:first-child"
                    className="uk-width-1"
                  >
                    <select
                      className="uk-form-small"
                      id="paymentType"
                      placeholder="Perfil"
                      name="paymentType"
                      defaultValue={person?.paymentType}
                      ref={register}
                    >
                      <option value="">Selecione...</option>
                      <option value={PaymentType.PIX}>PIX</option>
                      <option value={PaymentType.CASH}>Dinheiro</option>
                      <option value={PaymentType.CREDIT_CARD}>Cartão de Crédito</option>
                    </select>
                    <button
                      className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                      style={{ textTransform: "none" }}
                      type="button"
                      tabIndex={-1}
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
                <small className="uk-text-danger">{errors.paymentType?.message}</small>
              </div>                         
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={handleCloseModal}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={formState.isSubmitting || isReadOnly}
                className="uk-button uk-button-primary uk-margin-left"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
