import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from '../routes';
import Navbar from './Navbar';
import { authenticationService } from '../services/auth.service';
import { useEffect, useState } from 'react';
import { AuthProvider } from './auth/AuthContext';
import Login from '../pages/login/Login';
import NewPassword from '../pages/new-password/NewPassword';
import Home from '../pages/home/Home';
import ProtectedRoute from './auth/ProtectedRoute';
import MemberCredentialRequestList from '../pages/member/member-credential-request-list/MemberCredentialRequestList';
import MemberBaptismList from '../pages/member/member-baptism-list/MemberBaptismList';
import NotFound from '../pages/not-found/NotFound';
import MemberDeletedList from '../pages/member/member-deleted-list/MemberDeletedList';
import MemberAddEdit from '../pages/member/member-add-edit/MemberAddEdit';
import MemberList from '../pages/member/member-list/MemberList';
import AnnuityList from '../pages/secretary/annuity-list/AnnuityList';
import DashboardSecretary from '../pages/dashboard-secretary/DashboardSecretary';
import CongregationAddEdit from '../pages/congregation/congregation-add-edit/CongregationAddEdit';
import CongregationList from '../pages/congregation/congregation-list/CongregationList';
import EventPersonEdit from '../pages/event-person/event-person-edit/EventPersonEdit';
import EventPersonList from '../pages/event-person/event-person-list/EventPersonList';
import EventPrintList from '../pages/event-person/event-print-list/EventPrintList';
import EventRunning from '../pages/event-person/event-running/EventRunning';
import DashboardEvent from '../pages/event-person/dashboard-event/DashboardEvent';
import UserList from '../pages/user/user-list/UserList';
import MeetingMemberList from '../pages/meeting/meeting-member-list/MeetingMemberList';
import MeetingAdd from '../pages/meeting/meeting-add/MeetingAdd';
import MeetingRunning from '../pages/meeting/meeting-running/MeetingRunning';
import MeetingList from '../pages/meeting/meeting-list/MeetingList';
import MeetingEdit from '../pages/meeting/meeting-edit/MeetingEdit';
import ScheduleCalendar from '../pages/schedule/schedule-calendar/ScheduleCalendar';
import LetterList from '../pages/secretary/letter-list/LetterList';

function RoutesComponent() {
    const [userLogged, setUserLogged] = useState(false);

    useEffect(()=> {
        authenticationService.currentUser.subscribe(value => {
            setUserLogged(value != null);
        });
    }, []);

    return (
        <AuthProvider>
            <Router>
                { userLogged && <Navbar></Navbar> }
                <div className={userLogged ? 'uk-container': ''}>
                    <Routes>
                        <Route path="/entrar" element={<Login />} />
                        <Route path="/nova-senha" element={<NewPassword />} />
                        <Route path="/" element={<Home />} />
                        <Route element={<ProtectedRoute />} >
                            <Route path="/inicio" element={<Home />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/membros/credenciais-solicitadas" element={<MemberCredentialRequestList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/membros/batismo/lista" element={<MemberBaptismList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/membros/removidos" element={<MemberDeletedList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/membros/novo" element={<MemberAddEdit />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/membros/:id" element={<MemberAddEdit />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/membros" element={<MemberList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/secretaria/anuidades" element={<AnnuityList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/secretaria/cartas" element={<LetterList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/secretaria" element={<DashboardSecretary />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/congregacoes/novo" element={<CongregationAddEdit />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/congregacoes/:id" element={<CongregationAddEdit />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/congregacoes" element={<CongregationList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/eventos/:idEvent/pessoas/:id" element={<EventPersonEdit />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/eventos/:id/pessoas" element={<EventPersonList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/eventos/:id/impressoes" element={<EventPrintList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/eventos/:id/em-andamento" element={<EventRunning />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/eventos" element={<DashboardEvent />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/usuarios" element={<UserList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/reuniao/:id/membros" element={<MeetingMemberList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/reuniao/nova" element={<MeetingAdd />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/reuniao/em-andamento" element={<MeetingRunning />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/reuniao/lista" element={<MeetingList />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/reuniao/:id" element={<MeetingEdit />} />
                        </Route>
                        <Route element={<ProtectedRoute />} >
                            <Route path="/calendario" element={<ScheduleCalendar />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default RoutesComponent;