import { useEffect, useState } from "react";
import './autocomplete.css';
import IAutoCompleteObject from "../models/IAutoCompleteObject";
import api from "../services/api";

interface Params {
    suggestions: IAutoCompleteObject[];
    urlFetch: string;
    defaultValue?: any;
    onAfterChange?: any;
}

const AutoComplete = ({ suggestions, urlFetch, defaultValue, onAfterChange }: Params) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState(Array<IAutoCompleteObject>());
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [delayedSearchTerm, setDelayedSearchTerm] = useState('');

    useEffect(() => {
        const delaySearch = setTimeout(() => {
          setDelayedSearchTerm(searchTerm);
        }, 500); // You can adjust the delay time according to your preferences
    
        return () => clearTimeout(delaySearch);
    }, [searchTerm]);
    
    useEffect(() => {
        // Perform the search operation with delayedSearchTerm
        // This will be called only when the user stops typing for the specified delay time
        onChange(delayedSearchTerm);
    }, [delayedSearchTerm]);

    const handleInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const onChange = (userInput: string) => {
        if (!searching) setSearchTerm(userInput);

        if (userInput && userInput.length > 2) {
            setSearching(true);

            try {
                api.get(`${urlFetch}${userInput.toUpperCase()}`).then(result => {
                    suggestions = result.data.data.map((d: any) => { return { id: d.id, label: d.name } });
                    setSearching(false);

                    filterResults(userInput);
                });
            } catch (error) {
                setSearching(false);
            }
        }
    };

    const filterResults = (userInput: string) => {
        // Filter our suggestions that don't contain the user's input
        const unLinked = suggestions.filter(
            (suggestion) =>
            suggestion?.label.toUpperCase().indexOf(userInput.toUpperCase()) > -1
        );
    
        setFilteredSuggestions(unLinked ?? []);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);

        const ele = document.getElementsByTagName("input")[0];
        if (ele) {
            ele.focus();
        }
    }

    const onClick = (suggestion: any) => {
        setFilteredSuggestions([]);
        setSearchTerm(suggestion.label);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);

        afterSelect(suggestion);
    };

    const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
            <ul className="suggestions">
                {                    
                    filteredSuggestions.map((suggestion: IAutoCompleteObject, index) => {
                        let className;
                        // Flag the active suggestion with a class
                        if (index === activeSuggestionIndex) {
                            className = "suggestion-active";
                        }
                        return (
                            <li className={className} key={suggestion.id} onClick={()=> onClick(suggestion)}>
                                {suggestion.label}
                            </li>
                        );
                    })
                }
            </ul>
        ) : (
            <div className="no-suggestions">            
            </div>
        );
    };

    const onKeyDown = (key: any) => {
        if (key.keyCode === 40) { //down arrow
            setActiveSuggestionIndex(activeSuggestionIndex+1);
        }

        if(key.keyCode === 13 || key.keyCode === 9){
            const l = filteredSuggestions[activeSuggestionIndex]
            setSearchTerm(l.label)
            setFilteredSuggestions([]);
            afterSelect(l);
        }
    }

    const onEnter = (e: any) => {
        // onChange(e);
        // setShowSuggestions(true);
    }

    const afterSelect = (inputValue?: IAutoCompleteObject) => {
        if (onAfterChange) {
            onAfterChange(inputValue);
        }
    }

    return (
        <>
            <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={onKeyDown}
                className="uk-input uk-form-small"
                style={{textTransform: 'uppercase'}}
                onFocus={onEnter}
                onBlur={()=> { 
                    setTimeout(() => {
                        setShowSuggestions(false);
                    }, 5000); 
                }}
                disabled={searching}
            />
            {showSuggestions && <SuggestionsListComponent />}
        </>
    );
};
export default AutoComplete;
