import { useEffect, useState } from "react";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import "react-toastify/dist/ReactToastify.css";
import UserLogin from "../../../models/UserLogin";
import { displayDateTime } from "../../../utils/masks";
import MemberNoteModal from "../member-note-modal/MemberNoteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Params {
  memberId: number;
  canChange: boolean;
}

interface MemberNoteDTO {
  id: number;
  note: string;
  modifiedDate: Date;
}

export default function MemberNoteHandle(props: Params) {
  const { memberId, canChange } = props;

  const [refresh, setRefresh] = useState<boolean>();
  const [addNote, setAddNote] = useState<boolean>(false);

  const columns = [
    {
      name: "Observação",
      sortable: true,
      grow: 2,
      cell: (row: MemberNoteDTO) => <span>{row.note}</span>
    },
    {
      name: "Data",
      sortable: true,
      cell: (row: MemberNoteDTO) => <span>{displayDateTime(row.modifiedDate)}</span>
    }
  ];

  useEffect(() => {
  }, []);

  const fetchNotes = async (page: number, limit: number) => {
    try {
      const response = await api.get(`/members/${memberId}/notes`, {
        params: {
          page: page,
          limit: limit,
          user: authenticationService.isAuxSec()
            ? (authenticationService.currentUserValue as UserLogin).id
            : null
        }
      });

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleAdd = () => {
    setAddNote(true);
  }

  const afterHandleSubmit = () => {
    setAddNote(false);
    setRefresh(!refresh); 
  }

  const afterCloseModal = () => {
    setAddNote(false);
  }

  return (
    <div id="page-member-note-handle">
      <label className="uk-form-label" htmlFor="note">Observações</label>
      {
        (canChange) && (
          <button 
            id="btn-handle-note"
            className="uk-button uk-button-primary uk-button-small uk-margin-left" 
            type="button"
            onClick={handleAdd}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Observação
          </button>
        )
      }
      <TablePagination
        columns={columns}
        fetchData={fetchNotes}
        refresh={refresh}
        pagingOpt={[3, 5, 15, 25]}
        messageEmpty="Sem observações"
        />
      <MemberNoteModal
        add={addNote}      
        memberId={memberId}
        afterHandleSubmit={afterHandleSubmit}
        afterCloseModal={afterCloseModal}
        />
    </div>
  );
}
