import axios from 'axios';
import environment from '../environment';
import { authenticationService } from './auth.service';

const api = axios.create({
    baseURL: environment.apiUrl
});

api.interceptors.request.use(function (config) {
    if (authenticationService.currentUserValue && config.url !== 'login') {
        config.headers = {
            'Authorization': `Bearer ${authenticationService.currentUserValue?.token }`,
            'Access-Control-Allow-Origin': `${environment.url}`
        }
    }
    return config;
}, async function (error) {
    if (error.response.status === 401) {
        await authenticationService.logout(window.location.pathname);
    }
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    if (error.response?.status === 401) {
        await authenticationService.logout(window.location.pathname);
    }
    
    if (error.response?.status === 404) {
        return Promise.reject({messages: ['Recurso não encontrado']});
    }

    return Promise.reject(error.response?.data);
});


export default api;