import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TablePagination from '../../../components/TablePagination';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import './user.css';
import 'react-toastify/dist/ReactToastify.css';

import UserAddEdit from '../user-add-edit/UserAddEdit';
import toastOptions from '../../../utils/toastOptions';
import { authenticationService } from '../../../services/auth.service';
import UserLogin from '../../../models/UserLogin';
import { UserSystem } from '../../../models/enums/UserSystem';
import { faPencil, faPlus, faSearch, faTrash, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IDeleteDTO from '../../../models/IDeleteDTO';
import DialogConfirm from '../../../components/DialogConfirm';
import { displayDateTime } from '../../../utils/masks';

interface UserList {
    id: number;
    name: string;
    user: string;
    email: string;
    role: string;
    lastLoginDate: Date;
}

export default function UserList() {
    const navigate = useNavigate();
    const [refresh, doRefresh] = useState(false);  
    const [userId, setId] = useState<number>();
    const [add, doAdd] = useState(false);
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [termSearch, setTermSearch] = useState<string>('');
    const [systemSearch, setSystemSearch] = useState<string>(UserSystem.DASHBOARD);
    const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
    const [dialogDeleteText, setDialogDeleteText] = useState<string[]>([]);
    var userSelected = useRef(0);
    
    const columns = [
        {
            name: 'Nome',
            sortable: true,
            grow: 2,
            cell: (row: UserList) => (
                <div>
                    <span>{`${row.name}`}</span>
                </div>
            )
        },
        {
            name: 'Usuário',
            selector: 'user'
        },
        {
            name: 'E-mail',
            cell: (row: UserList) => (
                <div>
                    <span>{`${row.email}`}</span>
                </div>
            )
        },
        {
            name: 'Perfil',
            selector: 'role',
            sortable: false,
            right: true,
            cell: (row: UserList) => <div>{authenticationService.showRole(row.role)}</div>
        },
        {
            name: 'Dt. Último Login',
            cell: (row: UserList) => (
                <div>
                    <span>{`${row.lastLoginDate ? displayDateTime(row.lastLoginDate) : ''}`}</span>
                </div>
            )
        },
        {
            name: 'Ações',
            button: true,
            sortable: false,
            cell: (row: UserList) => (
                <div>
                    {
                        isReadOnly ?
                            (authenticationService.currentUserValue as UserLogin).email === row.email &&
                                <a href="#" uk-tooltip="title: Visualizar"
                                    className="uk-margin-right"
                                    onClick={(ev) => { ev.preventDefault(); handleEdit(row.id) }}
                                >
                                    <FontAwesomeIcon icon={faSearch} />
                                </a> 
                        : (
                            <div>
                                <a href="#" uk-tooltip={`title: ${authenticationService.isAdmin() ? 'Editar' : 'Visualizar'}`}
                                    className="uk-margin-right"
                                    onClick={(ev) => { ev.preventDefault(); handleEdit(row.id) }}
                                >
                                    {authenticationService.isAdmin() ? <FontAwesomeIcon icon={faPencil} /> : <FontAwesomeIcon icon={faSearch} />}
                                </a>
                                {
                                    authenticationService.isAdmin() && (
                                        <a href="#" uk-tooltip="title: Resetar Senha"
                                            className="uk-margin-right"
                                            onClick={(ev) => { ev.preventDefault(); handleResetPassword(row.id, row.user) }}
                                        >
                                            <FontAwesomeIcon icon={faUnlock} />
                                        </a>
                                    )
                                }
                                {
                                    (!isReadOnly) &&
                                        <a href="#" uk-tooltip="title: Inativar"
                                            className="uk-margin-right"
                                            onClick={(ev) => { ev.preventDefault(); handleRemove(row) }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </a>
                                }                                
                            </div>
                        )
                    }
                </div>
            )
        },
    ];  
    
    useEffect(()=>{
        setReadOnly(!authenticationService.canChange());
    }, []);
    
    const fetchUser = async (page: number, limit: number) => {
        try {
            if (termSearch) {
                const responseSearch = await api.get(`users/name/${termSearch.toUpperCase()}`, { 
                    params: { page: page, limit: limit, system: systemSearch } 
                });
                return responseSearch;
            }

            const response = await api.get('users', { params: { page: page, limit: limit, system: systemSearch } });
    
            return response;
        } catch {
            return {data: [], total: 0}
        }
    };

    const handleAddUser = () => {
        doAdd(true);        
    }

    const handleEdit = async (id: number) => {
        setId(id);        
    }

    const afterHandleSubmit = () => {
        doAdd(false); 
        setId(undefined);
        doRefresh(!refresh); 
    }

    const afterCloseModal = () => {
        doAdd(false);
        setId(undefined);
    }

    const handleResetPassword = async (id: number, userName: string) => {
        toast.info('Solicitação iniciada...', toastOptions);
        try {
            await api.post(`account/request-password/${id}`);
            toast.dismiss();
            toast.success(`O e-mail do usuário ${userName} para criação da nova senha foi enviado!`, toastOptions);
        } catch (e) {
            toast.dismiss();
            toast.warning('Problemas ao enviar e-mail!', toastOptions);            
        }
    }
    
    const handleSearch = async (search: string, system: string) => {
        await setSystemSearch(system);
        if (search?.length > 2 || search?.length === 0) {
            setTermSearch(search);
            doRefresh(!refresh);                
        }
    }
    
    const handleRemove  = async (row: UserList) => {
        setDialogDeleteText([`Deseja realmente inativar ${row.name}?`, 'Confirme sua senha se tiver certeza']);
        userSelected.current = row.id;
        setIsDialogDelete(true);
    }

    const dialogClose = () => {
        setIsDialogDelete(false);
    }

    const dialogConfirm = async (confirmationCode: string) => {
        if (!confirmationCode) {
            toast.warning('Informe a senha', toastOptions);
            return;
        }

        const dto = {} as IDeleteDTO;
        dto.confirmationCode = confirmationCode;
        dto.idDelete = +(userSelected.current);

        try {
            await api.post(`users/delete`, dto);
            setIsDialogDelete(false);
            toast.success('Usuário inativado com sucesso!', toastOptions);
            doRefresh(!refresh);
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }

    return (
        <div id="page-member">
            <UserAddEdit 
                add={add} 
                userId={userId} 
                afterHandleSubmit={afterHandleSubmit}
                afterCloseModal={afterCloseModal}
            />
            <DialogConfirm 
                open={isDialogDelete} 
                text={dialogDeleteText} 
                afterClose={dialogClose}
                confirmTextLabel={'Informe sua senha'}
                handleConfirmation={(confirmationCode) => dialogConfirm(confirmationCode)}
            />
            <div className="uk-text-large">
                <div className="uk-flex uk-flex-middle">
                    Usuários Ativos
                    {
                        (!isReadOnly) &&
                            <div className="uk-flex uk-margin-small-left">
                                <button className="uk-button uk-button-primary" onClick={handleAddUser}>
                                    <div className="uk-inline-clip uk-transition-toggle">
                                        <div className="uk-transition-scale-up uk-transition-opaque">
                                            {`Adicionar `} <FontAwesomeIcon icon={faPlus} size='xl' />
                                        </div>
                                    </div>                        
                                </button>
                            </div>   
                    }
                </div>    
            </div>
            <div className="uk-margin-top uk-grid-small" uk-grid="true" id="search-context">
                <div className="uk-search uk-search-default uk-width-1-2@m">
                    <span className="uk-search-icon-flip" uk-search-icon="true"></span>
                    <input 
                        className="uk-search-input uk-form-small" 
                        type="search" 
                        placeholder="Pesquise por nome..."
                        onChange={(ev)=>{ handleSearch(ev.target.value, systemSearch); }}
                    />
                </div> 
                {
                    authenticationService.isAdmin() && (

                        <div className="uk-width-1-4@s">                                   
                            <div className="uk-form-controls">
                                <label className="uk-form-label" htmlFor="role">Sistema</label>
                                <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                    <select className="uk-form-small"
                                        id="system" 
                                        placeholder="Sistema"
                                        name="system"
                                        defaultValue={systemSearch}
                                        onChange={(ev)=>{ handleSearch(termSearch, ev.target.value); }}
                                    >
                                        <option value="">Todos</option>
                                        <option value={UserSystem.DASHBOARD}>Dashboard</option>
                                        <option value={UserSystem.CETADEL}>Cetadel</option>
                                    </select>
                                    <button 
                                        className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                        style={{textTransform: 'none'}} 
                                        type="button" 
                                        tabIndex={-1}
                                    >
                                        <span></span>
                                        <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </div>   
                    )
                } 
            </div>
            <TablePagination
                columns={columns}
                fetchData={fetchUser}
                refresh={refresh}
            />          
        </div>
    )
}