import { UserRole } from './models/enums/UserRole';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import MeetingAdd from './pages/meeting/meeting-add/MeetingAdd';
import MeetingEdit from './pages/meeting/meeting-edit/MeetingEdit';
import DashboardSecretary from './pages/dashboard-secretary/DashboardSecretary';
import MeetingMemberList from './pages/meeting/meeting-member-list/MeetingMemberList';
import MeetingRunning from './pages/meeting/meeting-running/MeetingRunning';
import MemberAddEdit from './pages/member/member-add-edit/MemberAddEdit';
import MemberList from './pages/member/member-list/MemberList';
import NewPassword from './pages/new-password/NewPassword';
import UserList from './pages/user/user-list/UserList';
import NewsAddEdit from './pages/news/news-add-edit/NewsAddEdit';
import ScheduleCalendar from './pages/schedule/schedule-calendar/ScheduleCalendar';
import MemberCredentialRequestList from './pages/member/member-credential-request-list/MemberCredentialRequestList';
import MemberDeletedList from './pages/member/member-deleted-list/MemberDeletedList';
import MeetingList from './pages/meeting/meeting-list/MeetingList';
import MemberBaptismList from './pages/member/member-baptism-list/MemberBaptismList';
import CongregationList from './pages/congregation/congregation-list/CongregationList';
import CongregationAddEdit from './pages/congregation/congregation-add-edit/CongregationAddEdit';
import AnnuityList from './pages/secretary/annuity-list/AnnuityList';
import EventPersonList from './pages/event-person/event-person-list/EventPersonList';
import EventPersonEdit from './pages/event-person/event-person-edit/EventPersonEdit';
import DashboardEvent from './pages/event-person/dashboard-event/DashboardEvent';
import NotFound from './pages/not-found/NotFound';
import EventPrintList from './pages/event-person/event-print-list/EventPrintList';
import EventRunning from './pages/event-person/event-running/EventRunning';
import LetterList from './pages/secretary/letter-list/LetterList';

const route = [
    {
        path: '/',
    },
    {
        path: '/inicio',
        icon: 'home',
        label: 'Início',
        component: Home
    },
    {
        path: '/entrar',
        component: Login
    },
    {
        path: '/membros/credenciais-solicitadas',
        component: MemberCredentialRequestList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/membros/batismo/lista',
        component: MemberBaptismList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/membros/removidos',
        component: MemberDeletedList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/membros/novo',
        component: MemberAddEdit,
        denied: [UserRole.ROUTINE]
    },
    {
        path: '/membros/:id',
        component: MemberAddEdit,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/membros',
        icon: 'users',
        label: 'Membros',
        component: MemberList,
        denied: [UserRole.ROUTINE]
    },
    {
        path: '/secretaria/anuidades',
        component: AnnuityList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/secretaria/cartas',
        component: LetterList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/secretaria',
        icon: 'folder',
        label: 'Secretaria',
        component: DashboardSecretary,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/congregacoes/novo',
        component: CongregationAddEdit
    },
    {
        path: '/congregacoes/:id',
        component: CongregationAddEdit,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/congregacoes',
        icon: 'location',
        label: 'Congregações',
        component: CongregationList,
        denied: [UserRole.AUX_SECRETARY, UserRole.SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/eventos/pessoas/:id',
        component: EventPersonEdit,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/eventos/pessoas',
        component: EventPersonList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/eventos/impressoes',
        component: EventPrintList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/eventos/em-andamento',
        component: EventRunning
    },
    {
        path: '/eventos',
        icon: 'rss',
        label: 'EBO',
        component: DashboardEvent,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    // {
    //     path: '/aplicativo',
    //     icon: 'phone',
    //     label: 'Aplicativo',
    //     component: DashboardApp,
    //     denied: [UserRole.AUX_SECRETARY, UserRole.SECRETARY]
    // },
    {
        path: '/usuarios',
        icon: 'user',
        label: 'Usuários',
        component: UserList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/nova-senha',
        component: NewPassword
    },
    {
        path: '/reuniao/:id/membros',
        component: MeetingMemberList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/reuniao/nova',
        component: MeetingAdd,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/reuniao/em-andamento',
        component: MeetingRunning
    }, 
    {
        path: '/reuniao/lista',
        component: MeetingList,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/reuniao/:id',
        component: MeetingEdit,
        denied: [UserRole.AUX_SECRETARY, UserRole.ROUTINE]
    },
    ,
    {
        path: '/noticias/nova',
        component: NewsAddEdit,
        denied: [UserRole.AUX_SECRETARY, UserRole.SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/noticias/:id',
        component: NewsAddEdit,
        denied: [UserRole.AUX_SECRETARY, UserRole.SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '/calendario',
        component: ScheduleCalendar,
        denied: [UserRole.AUX_SECRETARY, UserRole.SECRETARY, UserRole.ROUTINE]
    },
    {
        path: '*',
        component: NotFound
    }
];

export default route;