import React from "react";
import Modal from "../modal";

interface PdfImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileUrl?: string; // URL PDF or image
  text?: string;
}

const ViewerModal: React.FC<PdfImageModalProps> = ({ isOpen, onClose, fileUrl, text }) => {
  const isPdf = fileUrl?.toLowerCase().endsWith(".pdf");

  const render = () => {
    if (fileUrl) {
      if (isPdf) {
        return <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`}
          width="100%"
          height="100%"
          style={{ border: "none", marginTop: 20 }}
        />
      } else {
        return <img src={fileUrl} alt="Visualização" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain", display: "block", margin: '0 auto' }} />
      }      
    } else if (text) {
      return <div className="uk-width-1-1@s uk-margin-bottom">
        <div style={{ textAlign: "justify", overflowY: 'auto', height: '100vh' }} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={()=> onClose()}
      contentLabel="Visualizador de Arquivo"
      style={{
        content: {
          width: "80%",
          height: "80%",
          margin: "auto",
          overflow: "hidden"      
        }
      }}
    >
      <div className="uk-modal-header">
        <button onClick={()=> onClose()}
          style={{ position: "absolute", right: 10, top: 10, zIndex: 999 }}
          className="uk-button uk-button-small uk-button-primary"
        >
          Fechar
        </button>
      </div>
      
      {render()}      
    </Modal>
  );
};

export default ViewerModal;