import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import "react-toastify/dist/ReactToastify.css";

export default function MemberCredentialCard() {
  const [totalMembers, setTotalMembers] = useState<number>();
  const [totalNextBaptism, setTotalNextBaptism] = useState<number>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    fetchMembers();
  };

  const fetchMembers = async () => {
    try {
      let params = {
        page: 1,
        limit: 1,
      } as any;

      const response = await api.get(`members/credentials-requested`, {
        params: params,
      });
      
      setTotalMembers(response.data.total);

      const { data: baptismResp } = await api.get(`members/next-baptism`, { 
        params: { 
            page: 1, 
            limit: 1
        } 
      });

      setTotalNextBaptism(baptismResp.total);

      return response;
    } catch {
      return { total: 0 };
    }
  };

  return (
    <div
      className="uk-card uk-card-warning uk-card-small uk-card-hover"
      style={{ minHeight: "100%" }}
    >
      <div className="uk-card-header">
        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
          <div className="uk-width-expand">
            <h3 className="uk-card-title uk-margin-remove-bottom">
              Credenciais Solicitadas
            </h3>
          </div>
        </div>
      </div>
      <div className="uk-card-body">
        {totalMembers == undefined ? (
          <div style={{ color: "#FFFFFF" }} uk-spinner="true; ratio:2">
            Carregando...
          </div>
        ) : (
          <h1 className="uk-heading-xlarge">{totalMembers}</h1>
        )}
      </div>
      <div className="uk-card-footer uk-margin-medium-top uk-flex uk-flex-column">
        <Link to="/membros/credenciais-solicitadas">
          Visualizar <span uk-icon="search"></span>
        </Link>
        {
          (totalNextBaptism && totalNextBaptism > 0) ? (
            <Link to="/membros/batismo/lista">
              {totalNextBaptism} Membro(s) na Lista de Batismo
              <span uk-icon="arrow-right"></span>
            </Link>
          ) : ""
        }
      </div>
    </div>
  );
}
