import { faBirthdayCake, faGift, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import TablePagination from "../../../components/TablePagination";
import IMemberListDTO from "../../../models/IMemberListDTO";
import UserLogin from "../../../models/UserLogin";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";
import { utils } from "../../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import { displayDate } from "../../../utils/masks";

export default function MemberBirthdayList() {
  const [isRunningBirthdayCard, setRunningBirthdayCard] = useState<boolean>(false);
  const [ixRunningBirthdayCard, setIxRunningBirthdayCard] = useState<number>(-1);

  const columnsBirthdays = [
    {
        name: 'Nome',
        selector: 'name',
        sortable: true,
        grow: 2,
        cell: (row: IMemberListDTO) => (
            <div>
                <span>{`${row.ecclesiasticalFunction.id != 1 ? row.ecclesiasticalFunction.description : ''} ${row.name}`}</span>
            </div>
        )
    },
    {
        name: 'Data',
        selector: 'birthDate',
        cell: (row: IMemberListDTO) => {
            const isToday = new Date(row?.birthDate?.toString()).getDate() == new Date().getDate();

            return <div className={`${isToday && 'uk-text-primary'}`}>
                <label className='uk-margin-small-right'>
                    {`${displayDate(row?.birthDate?.toString())}`}
                </label>
                <a href="#"                         
                    uk-tooltip="title: Aniversário Hoje!"
                    onClick={(ev) => { ev.preventDefault(); }}
                >
                    {isToday && <FontAwesomeIcon icon={faBirthdayCake}/>}
                </a>
            </div>
        }
    },
    {
        name: 'Congregação',
        sortable: true,
        cell: (row: IMemberListDTO) => (
            <div>
                <span>{`${row.congregation.description}`}</span>
            </div>
        )
    },
    {
        name: '-',
        button: true,
        sortable: false,
        cell: (row: IMemberListDTO, index: number) => (
            <div>
                <a href={`/membros/${row.id}`} target={'blank'}
                    uk-tooltip="title: Visualizar"
                    className="uk-margin-right"
                >
                    <FontAwesomeIcon icon={faSearch}/>
                </a>
                {
                    row.hasAcceptedCongrats && (
                        <a href="#"
                            onClick={(ev) => { ev.preventDefault(); handleBirthCard(row, index); }}
                            className="uk-margin-right"
                            uk-tooltip="title: Cartão de Aniversário"
                        >
                            {(isRunningBirthdayCard && ixRunningBirthdayCard == index) ? <div uk-spinner="true; ratio:0.5"></div> : 
                                <FontAwesomeIcon icon={faGift} />}
                        </a>
                    )
                }
            </div>
        )
    },
  ];  

  const fetchBirthdays = async (page: number, limit: number) => {
    try {
        const response = await api.get(`members/birthdays`, { 
            params: { 
                page: page, 
                limit: limit, 
                user: authenticationService.isAuxSec() ? (authenticationService.currentUserValue as UserLogin).id : null
            } 
        });
            
        return response;
    } catch {
        return {data: [], total: 0}
    }
  };
  
  const handleBirthCard = async(member: IMemberListDTO, ix: number) => {
    if (!member.hasAcceptedCongrats) {
        toast.info('Membro optou por não receber cartões de aniversário');
        return;
    }

    setRunningBirthdayCard(true);
    setIxRunningBirthdayCard(ix);
    try {
        const { data } = await api.get(`members/${member?.id}/birthday-card`);
        reportDone();
        
        const name = `${member?.name.toLocaleLowerCase().split(' ')[0]}-aniversário-${new Date().getTime()}.png`;
        utils.printPNG(name, data.item);
    } catch (e: any) {
        reportDone();
        e.messages?.forEach((m: string) => {
            toast.warning(m, toastOptions);
        });
    }
  }

  const reportDone = () => {
    setRunningBirthdayCard(false);
    setIxRunningBirthdayCard(-1);
  }

  return <>
    <div className="uk-card uk-card-default uk-card-small uk-card-hover">
        <div className="uk-card-header">
            <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Aniversariantes próximos 15 dias</h3>
                </div>
            </div>
        </div>
        <div className="uk-card-body">
            <TablePagination 
                columns={columnsBirthdays}
                fetchData={fetchBirthdays}  
                pagingOpt={[5, 15, 25, 50, 100, 500]}
            />
        </div>
    </div>
  </>
}