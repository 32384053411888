import { createContext, useContext, useEffect, useState } from 'react';
import environment from '../../environment';

interface IContext {
  isAuthenticated: boolean;
  login: any;
  logout: any;
}

const AuthContext = createContext({} as IContext);

export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    return storedAuth === 'true';
  });

  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false');
  };

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === 'isAuthenticated') {
        setIsAuthenticated(event.newValue === 'true');
      }
    };

    window.addEventListener(`${environment.userStorage}-context`, handleStorageChange);

    return () => {
      window.removeEventListener(`${environment.userStorage}-context`, handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
