import Routes from './components/Routes';
import './pages/shared/site.css';
import Modal from 'react-modal';

function App() {
    Modal.setAppElement('#root');    
    return <Routes/>;    
}

export default App;
