import { useEffect, useState } from "react";

import "react-toastify/dist/ReactToastify.css";
import TablePagination from "../../../components/TablePagination";
import UserLogin from "../../../models/UserLogin";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import "./presence-list.css";

interface Params {
  idEvent?: number;
}

export default function PresenceList(props: Params) {
  const [refresh, setRefresh] = useState<boolean>();
  
  const columns = [
    {
      name: "Título",
      sortable: true,
      grow: 2,
      cell: (row: any) => <span>{row.title}</span>
    },
    {
      name: "Qtd. Pessoas",
      sortable: true,
      cell: (row: any) => <span>{row.qtyPeople}</span>
    }
  ];

  useEffect(() => {}, []);

  const fetchPresenceList = async (page: number, limit: number) => {
    try {
      const response = await api.get(`/events/${props.idEvent}/presence/list`, {
        params: {
          page: page,
          limit: limit,
          user: authenticationService.isAuxSec()
            ? (authenticationService.currentUserValue as UserLogin).id
            : null,
        },
      });

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  return (
    <div id="page-presence-list">
      <div
        className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom"
      >
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Listas de Presença
              </h3>
            </div>
          </div>
        </div>
        <div className="uk-card-body">
          {
            props.idEvent &&
              <TablePagination
                columns={columns}
                fetchData={fetchPresenceList}
                refresh={refresh}
              />
          }
        </div>
      </div>
    </div>
  );
}
