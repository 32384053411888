import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { authenticationService } from "../../services/auth.service";

import './new-password.css';

import logo from '../../assets/images/logo-ad-belem.webp';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import toastOptions from '../../utils/toastOptions';
import { useNavigate } from 'react-router-dom';

interface PasswordChange {
    password: string;
    passwordConfirmation: string;
}

export default function NewPassword() {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('chave');

    const navigate = useNavigate();
    const [keyIsChecked, setKeyIsChecked] = useState<boolean>(false);
    const [userFound, setUserFound] = useState<any>();
    const opt = toastOptions;

    const schema = yup.object().shape({
        password: yup.string().required('Senha obrigatória')
            .matches(
                /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                "Pelo menos 6 caracteres, 1 número e 1 caracter especial"
            )
            .min(6, (val) => `Mínimo ${val.min} caracteres`),
        passwordConfirmation: yup.string()
            .test('passwords-match', 'Senhas não coincidem', function() {
                const confirm = this.parent.passwordConfirmation;
                return this.parent.password === confirm;
            })
    })

    const { register, handleSubmit, reset, errors, formState } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=> {
        const init = ()=> {
            authenticationService.removeUserLogged();
            
            opt.onClose = () => {
                navigate('/');                    
            }

            if (!key) {                
                toast.error("Você não foi permitido para acessar essa função", opt);
                return;
            }
    
            api.get(`account/valid-reset/${key}`).then(({data}) => {
                setUserFound(data.item);
                setKeyIsChecked(true);                
            }).catch(error => {
                error.messages?.forEach((m: string) => {
                    toast.error(m, opt);
                });
            });            
        }

        init();
    }, []);

    
    const onSubmit = async (password: PasswordChange) => {
        try {
            
            await api.post('account/new-password', {
                password: password.password,
                passwordConfirmation: password.passwordConfirmation,
                id: userFound.id,
                key: key
            });

            setKeyIsChecked(false);
            opt.delay = 3;
            toast.success("Senha alterada com sucesso! Você será direcionado...", opt);
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m);
            });
        }
    }

    return (
        <>
            <div id="container-login">
                <div className="uk-margin-top uk-height-small">
                    <img className="uk-height-small" width={200} height={250} src={logo} alt="Logo AD Belém"></img>
                </div>
                <div className="uk-flex uk-flex-center uk-margin-top" id="container-form-login">
                    <div className="uk-card uk-card-default uk-card-body">
                        {!userFound ? 
                            <div className="uk-flex uk-flex-center">
                                <div uk-spinner="true; ratio:2">Carregando...</div>
                            </div>
                            :
                            <form onSubmit={handleSubmit<PasswordChange>(onSubmit)} onReset={reset}>
                                <div className="uk-margin" uk-grid="true">
                                    <label className="uk-form-label uk-width-1-1" htmlFor="password">Nova Senha</label>                            
                                    <div className="uk-inline uk-width-1-1 uk-margin-remove-top">
                                        <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
                                        <input 
                                            className="uk-input" 
                                            type="password"
                                            name="password"
                                            id="password"
                                            ref={register}
                                        />
                                    </div>
                                    <small className="uk-text-danger uk-margin-remove-top uk-text-break">
                                        {errors.password?.message}
                                    </small>
                                </div>

                                <div className="uk-margin" uk-grid="true">
                                    <label className="uk-form-label uk-width-1-1" htmlFor="passwordConfirmation">Confirmar a Senha</label>
                                    <div className="uk-inline uk-width-1-1 uk-margin-remove-top">
                                        <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: check"></span>
                                        <input 
                                            className="uk-input" 
                                            type="password"
                                            name="passwordConfirmation"
                                            id="passwordConfirmation"
                                            ref={register}
                                        />
                                    </div>
                                    <small className="uk-text-danger uk-width-1-1 uk-margin-remove-top">
                                        {errors.passwordConfirmation?.message}
                                    </small>
                                </div>
                                <div className="uk-text-center">  
                                    <button 
                                        disabled={formState.isSubmitting || !keyIsChecked}
                                        className="uk-button uk-button-primary uk-text-center" 
                                        type="submit">     
                                        <div className="uk-inline-clip uk-transition-toggle">
                                            {formState.isSubmitting && <div uk-spinner="true"></div>}
                                            <div className="uk-transition-scale-up uk-transition-opaque uk-flex uk-flex-middle">
                                                <span uk-icon="check"></span>
                                                Definir Senha
                                            </div>
                                        </div>                               
                                    </button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}