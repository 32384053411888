import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import "react-toastify/dist/ReactToastify.css";
import TablePagination from "../../../components/TablePagination";
import moment from "moment";
import { TypeHistory } from "../../../models/enums/TypeHistory";

interface ListDTO {
  id: number;
  createdDate: Date;
  createdBy: string;
  member: {
    nameAndFunction: string;
  };
  note: string;
}

export default function LetterList() {
  const [refresh, setRefresh] = useState<boolean>();
  const [totalLetters, setTotalLetters] = useState(0);
  const [termSearch, setTermSearch] = useState<string>("");
  const [type, setType] = useState<string>();
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: ListDTO) => <span>{row.member.nameAndFunction}</span>
    },
    {
      name: "Emitida",
      sortable: false,
      cell: (row: ListDTO) => <span>
        Em {moment(row.createdDate).format('DD/MM/yyyy HH:mm:ss')} <br/>
        Por {row.createdBy}
      </span>
    },
    {
      name: "Obs.",
      sortable: false,
      cell: (row: ListDTO) => <span>
        {(row.note.split('-')[0])}
        {row.note.split('-').length > 1 && <div>
          <br/>
          {row.note.split('-')[1]}
        </div>}
      </span>
    }
  ];

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    setRefresh(!refresh);
  }, [delayedSearchTerm]);

  const fetchLetters = async (page: number, limit: number) => {
    try {
      setTotalLetters(0);

      let params = {
        page: page,
        limit: limit,
        type: type
      } as any;

      if (termSearch) {
        params = { ...params, name: termSearch?.toUpperCase() };
      }

      const response = await api.get(`letters/issued`, { params: params });

      setTotalLetters(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleSearch = async (search: string) => {  
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handleType = async(selectedType: string) => {
    setType(selectedType);
    setRefresh(!refresh);
  }
  
  return (
    <div id="page-letter-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Cartas Emitidas
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/secretaria"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-flex uk-flex-middle">
            <div className="uk-margin-right">Total: {totalLetters}</div>            
          </div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearch(ev.target.value);
                }}
              />
            </div>
            <div className="uk-width-1-2@m">
              <div className="uk-flex uk-flex-middle">                
                <label className="uk-form-label uk-margin-small-right" htmlFor="type">Tipo</label>
                <div uk-form-custom="target: > * > span:first-child" 
                  className="uk-width-1"
                  style={{backgroundColor: '#FFF'}}>
                  <select className="uk-form-small"
                    id="type" 
                    placeholder="Tipo"
                    name="type"
                    value={type}
                    onChange={(e) => {handleType(e.target.value);}}
                  >                                    
                    <option value="">Todas</option>
                    <option value={TypeHistory.RECOMMENDATION_LETTER}>{TypeHistory.RECOMMENDATION_LETTER}</option>
                    <option value={TypeHistory.CHANGE_LETTER}>{TypeHistory.CHANGE_LETTER}</option>
                  </select>
                  <button 
                    className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                    style={{textTransform: 'none'}} 
                    type="button" 
                    tabIndex={-1}
                  >
                    <span></span>
                    <span uk-icon="icon: chevron-down"></span>
                  </button>
                </div>
              </div>
            </div>            
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchLetters}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
