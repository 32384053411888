import { useEffect, useState } from "react"
import toastOptions from "../../../utils/toastOptions";
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "../../../services/api";
import { useNavigate } from "react-router";
import IMeetingOpenDTO from "../../../models/IMeetingOpenDTO";

import './meeting-running.css';
import { CPFIsValid, cpfMask, displayDate, memberTitle, numberMask } from "../../../utils/masks";
import IMemberMeetingDTO from "../../../models/IMemberMeetingDTO";
import Countdown from "react-countdown";
import MemberMeeting from "../../../models/IMemberMeeting";
import DialogConfirm from "../../../components/DialogConfirm";
import { authenticationService } from "../../../services/auth.service";
import { Gender } from "../../../models/enums/Gender";

export default function MeetingRunning() {
    const navigate = useNavigate();
    const [meeting, setMeeting] = useState<IMeetingOpenDTO>();
    const [entry, setEntry] = useState("");
    const [memberMeeting, setMemberMeeting] = useState<MemberMeeting>();
    const [confirm, setConfirm] = useState<boolean>(false);
    const [refreshing, setRefresh] = useState<boolean>(false);
    const [closing, setClosing] = useState<boolean>(false);

    var input;

    const toastOptionsPage: ToastOptions = {
        pauseOnFocusLoss: false
    }; 

    useEffect(()=> {
        const init = async()=> {

            const { data } = await api.get('meetings/open');

            toastOptionsPage.onClose = () => {
                navigate('/');                    
            }

            if (!data.success) {
                toast.error('Problemas ao carregar reunião', toastOptionsPage);
                return;
            }
            
            if (!data.item) {
                toast.error('Nenhuma reunião ABERTA foi localizada', toastOptionsPage);
                return;
            }

            setMeeting(data.item);
            setEntry("");
            setMemberMeeting(undefined);
            setRefresh(false);

            const ele = document.getElementById("navbar-main");
            if (ele) {
                ele.className += ' uk-hidden';
            }
        }

        init();

        document.addEventListener('keydown', function(event){
            //Shift + F1
            if (event.key == 'F1' && event.keyCode == 112) {
                setConfirm(true);
            }
        });
    }, []);

    const handleEntry = async (ev: any) => {
        if (ev.charCode == 13) {
            if (!entry) return;

            setRefresh(true);
            const param = {
                idMeeting: meeting?.meeting.id                
            } as IMemberMeetingDTO;

            if (entry.length > 6) {
                if (!CPFIsValid(entry)) {
                    toast.warn("CPF Inválido", toastOptionsPage);
                    ev.target.select();
                    setRefresh(false);
                    return;
                }

                param.document = entry;
            }

            if (!param.document) {
                param.cardNumber = +(entry);
            }

            try {
                const { data } = await api.post('meetings/enter', param);

                if (!data.success) {
                    data.messages?.forEach((m: string) => {
                        toast.warn(m, toastOptionsPage);
                    });
                    ev.target.value = "";
                    setEntry("");
                    ev.focus();
                    setRefresh(false);
                    return;
                }

                setMemberMeeting(data.item);

                ev.target.value = "";
                setEntry("");
                setRefresh(false);
                
                ev.focus();
            } catch (e: any) {
                e.messages?.forEach((m: string) => {
                    toast.error(m, toastOptionsPage);
                });
                
                setRefresh(false);
            }
        }        
    }

    const handleEntryChange = (e: any) => {
        e.target.value = (e.target.value.length > 6 ? cpfMask(e.target.value) : numberMask(e.target.value));
        setEntry(e.target.value);
    }

    const rendererCountdown = (renderOptions: any) => {
        if (renderOptions.completed) {
            setMemberMeeting(undefined);
            return <span></span>;
        } else {
            return <span>Seus dados serão ocultados em {renderOptions.seconds} segundos </span>;
        }
    };

    const handleCloseMeeting = async (confirmationCode: string) => {
        if (!confirmationCode) {
            toast.warn("Informe a senha", toastOptions);
            return;
        }

        setConfirm(false);
        setRefresh(true);
        setClosing(true);

        try {
            const { data } = await api.post(`meetings/${meeting?.meeting.id}/close`, { confirmationCode: confirmationCode });

            if (!data.success) {
                data.messages?.forEach((m: string) => {
                    if (m.indexOf("FINALIZADA") > 0) {
                        toastOptions.onClose = () => {
                            setRefresh(false);
                            setClosing(false);
                            navigate('/');               
                        }
                    }
                    toast.error(m, toastOptions);
                });
                return;
            }
            
            toastOptionsPage.onClose = () => {
                setRefresh(false);
                setClosing(false);
                navigate('/');                    
            }

            setMemberMeeting(undefined);
            toast.success('Reunião sendo encerrada...', toastOptionsPage);
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });

            setRefresh(false);
            setClosing(false);
        }
    }

    const dialogClose = () => {
        setConfirm(false);
    }

    return (
        <>
        <DialogConfirm
            open={confirm}
            text={[
                ""
            ]}
            title="Finalizar Reunião"
            confirmTextLabel="Confirme a senha para finalizar a reunião"
            handleConfirmation={handleCloseMeeting}
            afterClose={dialogClose}
        ></DialogConfirm>
        <div className="uk-section" id="meeting-running">
            {
                (!meeting) ? (
                    <div className="uk-flex uk-flex-center">
                        <div uk-spinner="true; ratio:2">Carregando...</div>
                    </div>
                ) :
                <div>
                    {
                        authenticationService.isAdmin() &&
                            <button 
                                id="btn-close-meeting"
                                className="uk-button uk-button-primary uk-margin-bottom uk-button-small uk-flex uk-flex-middle" 
                                type="button"
                                disabled={confirm || closing}
                                onClick={()=> setConfirm(true)}
                            >                                                    
                                {   
                                    closing ?
                                    <div uk-spinner="true; ratio:0.5"></div> : 
                                    <span className="uk-margin-small-right" uk-icon={`icon: close;`}></span>
                                }
                                Fechar Reunião
                            </button>
                    }
                    <div className="uk-card uk-card-body uk-card-success uk-card-hover">
                        <div className="uk-inline-clip uk-transition-toggle">
                            <h1>Informe seu CPF ou Nº Carteirinha</h1>
                            <div className="uk-flex">                                
                                <input type="text" 
                                    id="entry" 
                                    name="entry" 
                                    placeholder="CPF ou Nº Carteirinha" 
                                    className="uk-input uk-form-large"
                                    autoComplete={"off"}
                                    onKeyPress={handleEntry}
                                    onChange={handleEntryChange}
                                    autoFocus={true}
                                    defaultValue={entry}
                                    disabled={refreshing}
                                    ref={ref => { input = ref; input?.focus()}}
                                />     
                                {refreshing && <div uk-spinner="true; ratio: 2"></div>}
                            </div>
                        </div>
                    </div>

                    {
                        memberMeeting &&     
                        <div className="uk-card uk-card-body uk-card-success uk-card-hover">
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-1@s">
                                    <h3>
                                        Bem-vind{ memberMeeting.member.gender == Gender.FEMALE ? 'a': 'o' } {memberTitle(memberMeeting.member)} {memberMeeting.member.name}
                                    </h3> 
                                </div>
                                <div className="uk-width-1-2@s">
                                    {                                    
                                        memberMeeting.member.photo ?
                                            <img
                                                style={{height:200, width: 200, marginBottom: 1}}
                                                src={`data:image/jpeg;base64,${memberMeeting.member.photo}`}
                                                alt="Imagem Membro"
                                            /> :
                                            <span uk-icon="icon: user; ratio: 5"></span>
                                    }
                                </div>
                                <div className="uk-width-1-2@s">
                                    <div className="uk-width-1-1@s">
                                        <h4>
                                            Congregação: {memberMeeting.member.congregation.description}
                                        </h4>
                                    </div>
                                    <div className="uk-width-1-1@s">
                                        <h4>
                                            Última Reunião Presente: {displayDate(memberMeeting.lastMeeting?.toString())}
                                        </h4>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s">
                                    <label className="uk-text-italic uk-text-small">
                                        <Countdown date={Date.now() + 5000}  
                                            renderer={(r) => rendererCountdown(r)}
                                        />                                        
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        </>
    )
}
