import { useState, useEffect } from "react";
import { displayDate } from "../../../utils/masks";
import Modal from "../../../components/modal";

interface Params {
  memberChanged: any;
  memberCurrent: any;
  open: boolean;
  afterCloseModal: any;
}

export default function MemberHistoricDetail(props: Params) {
  const { memberChanged, memberCurrent, open, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [member, setMember] = useState({} as any);

  useEffect(() => {
    setModelOpen(open);
    if (memberChanged) {
      setMember(JSON.parse(memberChanged));
    }
  }, [memberChanged, open]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setModelOpen(false);
    setMember({});
    afterCloseModal();
  };

  const handleFields = () => {
    return (
      <div className="uk-overflow-auto">
        <table className="uk-table uk-table-divider uk-table-striped uk-table-hover uk-table-justify uk-table-responsive">
          <thead>
            <th>Campo</th>
            <th>Valor Anterior</th>
            <th>Valor Atual</th>
          </thead>
          <tbody>
            {
              Object.keys(member).map((objKey) => {
                return <tr>{customFields(objKey)}</tr>;
              })
            }
          </tbody>
        </table>
      </div>
    );
  }

  const fieldsDisplay = {
    'baptismDate': 'Data Batismo',
    'since': 'Membro Desde',
    'birthDate': 'Nascimento',
    'cardNumber': "Nº Carteirinha",
    'ecclesiasticalFunction': "Função Eclesiástica",
    'congregation': "Congregação",
    'name': "Nome",
    'gender': "Sexo",
    'document': "CPF",
    'register': "RG",
    'stateBirth': "Estado Nasc.",
    'cityBirth': "Cidade Natal",
    'motherName': "Nome da Mãe",
    'fatherName': "Nome do Pai",
    'maritalStatus': "Estado Civil",
    'lifePartnerName': "Cônjuge",
    'baptismSpiritDate': "Data Batismo Espírito Santo",
    'zipCode': "CEP",
    'address': "Endereço",
    'numberAddress': "Número",
    'addressLine2': "Complemento",
    'neighborhood': "Bairro",
    'phone': "Telefone",
    'state': "Estado",
    'city': "Cidade",
    'email': "E-mail",
    'photo': "Foto",
    'note': "Observações"
  } as any;

  interface IField {
    display: any;
    previous: any;
    current: any;
  }

  const customFields = (objkey: any) => {
    const field = {} as IField;
    field.display = fieldsDisplay[objkey];

    if (['baptismDate', 'since', 'birthDate', 'baptismSpiritDate'].indexOf(objkey) > -1)
    {
      field.previous = displayDate(member[objkey]);
      field.current = displayDate(memberCurrent[objkey]);
    } else {
      field.previous = member[objkey];

      if (['congregation', 'ecclesiasticalFunction'].indexOf(objkey) > -1) {
        field.current = memberCurrent[objkey].description;  
      } else {
        field.current = memberCurrent[objkey];
      }
    }

    return <>
      <td>{field.display}</td>
      <td>
        {field.display == 'Foto' ? fieldPhoto(field.previous) : field.previous}
      </td>
      <td>
        {field.display == 'Foto' ? fieldPhoto(field.current) : field.current}
      </td>
    </>
  }

  const fieldPhoto = (photo: string) => {
    if (!photo) return '';
    if (photo?.indexOf('FOTO ADICIONADA') >= 0) return photo;
    return <img width={50} height={50} src={`data:image/jpeg;base64,${photo}`}></img>;
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-small"
      shouldCloseOnOverlayClick={false}
    >
      <div className="uk-modal-header">
        <h2 className="uk-modal-title">Alterações Realizadas</h2>
      </div>
      <button
        className="uk-modal-close-default"
        uk-close="true"
        type="button"
        onClick={handleCloseModal}
      />
      <div className="uk-modal-body">
        {
          !member && <div>Nenhuma Alteração Realizada</div>
        }
        {handleFields()}
      </div>
    </Modal>
  );
}
