export enum TypeHistory {
  CHANGE_CHURCH = "MUDANÇA MINISTÉRIO",
  LEAVE = "SAIU DA IGREJA",
  CHANGE_CITY = "MUDANÇA DE CIDADE",
  OTHER = "OUTRO",
  FIRST_CREDENTIAL = "1ª CREDENCIAL",
  CREDENTIAL_BAPTISM = "BATISMO",
  CREDENTIAL_LOST = "PERDEU CREDENCIAL",
  CREDENTIAL_DUE = "CREDENCIAL VENCIDA",
  CREDENTIAL_CHANGE_FUNCTION = "MUDANÇA DE FUNÇÃO",
  CREDENTIAL_AMEND = "CORREÇÃO CREDENCIAL",
  CREDENTIAL_RECEPTION = "CREDENCIAL RECEPÇÃO DE MEMBRO",
  CREDENTIAL_DELIVERY = "CREDENCIAL ENTREGUE",
  CREDENCIAL_OTHER = "CREDENCIAL OUTRO MOTIVO",
  DISCIPLINE = "MEMBRO DISCIPLINADO",
  ADD = "MEMBRO ADICIONADO",
  CHANGE_RECORDS = "ALTERAÇÃO DE DADOS",
  NOTE = "OBSERVAÇÃO",
  RECOMMENDATION_LETTER = "CARTA DE RECOMENDAÇÃO",
  CHANGE_LETTER = "CARTA DE MUDANÇA"
}