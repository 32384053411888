import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastOptions from '../../../utils/toastOptions';
import UserLogin from "../../../models/UserLogin";
import Param from "../../../models/Param";
import { storageService } from "../../../services/local-storage.service";
import environment from "../../../environment";
import MemberDialogConfirm from "../member-delete-dialog/MemberDialogConfirm";
import IMemberDeleteDTO from "../../../models/IMemberDeleteDTO";
import { faPencil, faPlus, faPrint, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utils } from "../../../utils/utils";
import { EcclesiasticalFunction } from "../../../models/enums/EcclesiasticalFunction";

interface MemberListDTO {
    id: number;
    name: string;
    ecclesiasticalFunction: any;
    congregation: any;
}

export default function MemberList() {
    const navigate = useNavigate();
    const [isReadOnly, setReadOnly] = useState<boolean>();
    const [isAuxSec, setAuxSec] = useState<boolean>();
    const [termSearch, setTermSearch] = useState<string>('');
    const [congSearch, setCongSearch] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>();
    const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
    const [dialogDeleteText, setDialogDeleteText] = useState<string[]>([]);
    const [congregations, setCongregations] = useState<Param[]>([]);
    var memberSelected = useRef(0);
    const [totalMembers, setTotalMembers] = useState(0);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [functions, setFunctions] = useState<Param[]>([]);
    const [functionSearch, setFunctionSearch] = useState<number>(0);
    const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
    
    const columns = [
        {
            name: 'Nome',
            sortable: true,
            grow: 2,
            cell: (row: MemberListDTO) => <span>{row.name}</span>
        },
        {
            name: 'Função',
            selector: 'ecclesiasticalFunction.description',
            sortable: true
        },
        {
            name: 'Congregação',
            sortable: true,
            cell: (row: MemberListDTO) => <span>{row.congregation.description}</span>
        },
        {
            name: 'Ações',
            button: true,
            sortable: false,
            cell: (row: MemberListDTO) => (
                <div>
                    {
                        (isReadOnly) ?
                            <Link to={`/membros/${row.id}`} 
                                uk-tooltip="title: Visualizar"
                                className="uk-margin-right"
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </Link>
                        :
                            <Link to={`/membros/${row.id}`} 
                                uk-tooltip="title: Editar"
                                className="uk-margin-right"
                            >
                                <FontAwesomeIcon icon={faPencil} />
                            </Link>
                            
                    }
                    {
                        (!isReadOnly) &&
                            <a href="#" uk-tooltip="title: Remover"
                                className="uk-margin-right"
                                onClick={(ev) => { ev.preventDefault(); handleRemove(row) }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </a>
                    }
                </div>
            )
        },
    ];   
    
    const keyStorageCongregationFilter = `${environment.storage}member-congregation`;
    const keyStorageFunctionFilter = `${environment.storage}member-function`;

    useEffect(()=>{
        setReadOnly(!authenticationService.canChange());
        setAuxSec(authenticationService.isAuxSec());
        init();
    }, []);

    useEffect(() => {
        const delaySearch = setTimeout(() => {
          setDelayedSearchTerm(termSearch);
        }, 500); // You can adjust the delay time according to your preferences
    
        return () => clearTimeout(delaySearch);
    }, [termSearch]);

    useEffect(() => {
        // Perform the search operation with delayedSearchTerm
        // This will be called only when the user stops typing for the specified delay time
        setRefresh(!refresh);
    }, [delayedSearchTerm]);

    const init = async ()=>{
        const dataCongregations = await api.get('congregations/dashboard');
        setCongregations(dataCongregations.data);
        
        const dataFunctions = await api.get(`params/ecclesiasticals`);
        setFunctions(dataFunctions.data);
    }
    
    const fetchMembers = async (page: number, limit: number) => {
        try {
            const filterCong = await storageService.get(keyStorageCongregationFilter);
            const filterFunction = await storageService.get(keyStorageFunctionFilter);

            setCongSearch(+(filterCong ?? 0));
            setFunctionSearch(+(filterFunction ?? 0));

            const filterUrl = `?congregation=${filterCong}&function=${filterFunction}`;

            setTotalMembers(0);
            if (termSearch) {
                const responseSearch = await api.get(`members/name/${termSearch.toUpperCase()}${filterUrl}`);
                setTotalMembers(responseSearch.data.total);
                return responseSearch;
            }

            const response = await api.get(`members${filterUrl}`, { 
                params: { 
                    page: page, 
                    limit: limit, 
                    user: authenticationService.isAuxSec() ? (authenticationService.currentUserValue as UserLogin).id : null
                } 
            });

            setTotalMembers(response.data.total);
                
            return response;
        } catch {
            return {data: [], total: 0}
        }
    };

    const handleRemove = async (row: MemberListDTO) => {
        setDialogDeleteText([`Deseja realmente excluir ${row.name}?`, 'Confirme sua senha se tiver certeza']);
        memberSelected.current = row.id;
        setIsDialogDelete(true);
    }

    const handleSearchByName = async (search: string) => {
        if (search.length > 2 || search.length === 0) {
            setTermSearch(search);
        }
    }

    const handleSearch = async (congregation: number, functionId: number) => {
        await setCongSearch(congregation);
        await setFunctionSearch(functionId);
        
        storageService.set(keyStorageCongregationFilter, `${congregation}`);
        storageService.set(keyStorageFunctionFilter, `${functionId}`);

        setRefresh(!refresh);            
    }

    const dialogClose = () => {
        setIsDialogDelete(false);
    }

    const dialogConfirm = async (dto: IMemberDeleteDTO) => {        
        if (dto.reason.trim() == "") {
            toast.warning('Informe o motivo', toastOptions);
            return;
        }

        if (!dto.confirmationCode) {
            toast.warning('Informe a senha', toastOptions);
            return;
        }

        try {
            await api.post(`members/${memberSelected.current}/delete`, dto);
            setIsDialogDelete(false);
            toast.success('Membro removido com sucesso!', toastOptions);
            setRefresh(!refresh);
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }

    const handlePrint = async () => {
        setIsRunning(true);
        try {
          const { data } = await api.post("/members/list/report", {
            name: termSearch,
            idCongregation: congSearch,
            idFunction: functionSearch,
          });

          if (!data.success) {
            data.messages?.forEach((m: string) => {
                toast.warn(m, toastOptions);
            });
            return;
          }

          let fileName = 'lista';

          let func = functions.filter(c => c.id == functionSearch).shift();

          if (func) {
            fileName += `-${func.description.toLocaleLowerCase()}`;
          } else if (functionSearch == EcclesiasticalFunction.LABORS) {
            fileName += '-obreiros';
          } else if (functionSearch == EcclesiasticalFunction.MINISTERS) {
            fileName += '-ministros';
          } else if (functionSearch == EcclesiasticalFunction.DEACONS) {
            fileName += '-diáconos-diaconisas';
          }

          let cong = congregations.filter(c => c.id == congSearch).shift();

          if (cong) {
            fileName += `-${cong.description.replace(/[^a-zA-Z0-9]/g, '-').toLocaleLowerCase()}`;
          }

          const name = `${fileName}-${new Date().getTime()}.pdf`;
          utils.printPDF(name, data.item);

        } catch (e: any) {
          e.messages?.forEach((m: string) => {
            toast.error(m, toastOptions);
          });
        } finally {
          setIsRunning(false);
        }
      };

    return (
        <div id="page-member">
            <div className="uk-text-large">
                <div className="uk-flex uk-flex-middle">
                    Membros Ativos
                    {
                        (!isReadOnly || isAuxSec) &&
                            <div className="uk-flex uk-margin-small-left">
                                <div className="uk-inline-clip uk-transition-toggle">
                                    <div className="uk-transition-scale-up uk-transition-opaque">
                                        <Link to="/membros/novo" className="uk-button uk-button-small uk-button-primary">
                                            {`Adicionar `} <FontAwesomeIcon icon={faPlus} size='xl' />
                                        </Link>
                                    </div>
                                </div>                        
                            </div>                    
                    }

                    <div className="uk-flex uk-margin-small-left">
                        <div className="uk-inline-clip uk-transition-toggle">
                            <div className="uk-transition-scale-up uk-transition-opaque">
                                <button
                                    type="button"
                                    className="uk-button uk-button-primary uk-button-small"
                                    disabled={isRunning || totalMembers == 0}
                                    onClick={() => handlePrint()}
                                >
                                    <span>
                                    Imprimir Lista
                                    </span>
                                    {isRunning ? (
                                    <div
                                        className="uk-margin-small-left"
                                        uk-spinner="true; ratio:0.5"
                                    ></div>
                                    ) : (
                                    <FontAwesomeIcon className="uk-margin-small-left" icon={faPrint} size='xl' />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div> 
                </div>    
            </div>
            <div className="uk-margin-top">
                <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-search uk-search-default uk-width-1-3@m">
                        <span className="uk-search-icon-flip" uk-search-icon="true"></span>
                        <input 
                            className="uk-search-input uk-form-small" 
                            type="search" 
                            placeholder="Pesquise por nome..."
                            style={{textTransform: 'uppercase'}}
                            onChange={(ev)=>{ handleSearchByName(ev.target.value); }}
                        />
                    </div>
                    <div className="uk-search uk-search-default uk-width-1-3@m">
                        <label className="uk-form-label uk-margin-right" htmlFor="congregationId">Congregação:</label>
                        <div uk-form-custom="target: > * > span:first-child" className="uk-width-2-3@m">
                            <select className="uk-form-small"
                                id="congregationId" 
                                name="congregationId"
                                value={congSearch}
                                onChange={(e)=> handleSearch(+(e.target.value), functionSearch)}
                            >
                                <option value="" key={`congregation0`}>Todas</option>
                                {
                                    congregations.map(f => {
                                        return <option value={f.id} key={`congregation${f.id}`}>{f.description}</option>
                                    })
                                }
                            </select>
                            <button 
                                className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                style={{textTransform: 'none'}} 
                                type="button" 
                                tabIndex={-1}
                            >
                                <span></span>
                                <span uk-icon="icon: chevron-down"></span>
                            </button>
                        </div>
                    </div>
                    <div className="uk-search uk-search-default uk-width-1-3@m">
                        <label className="uk-form-label uk-margin-right" htmlFor="functionId">Função:</label>
                        <div uk-form-custom="target: > * > span:first-child" className="uk-width-2-3@m">
                            <select className="uk-form-small"
                                id="functionId" 
                                name="functionId"
                                value={functionSearch}
                                onChange={(e)=> handleSearch(congSearch, +(e.target.value))}
                            >
                                <option value="" key={`function0`}>Todas</option>
                                <option value={EcclesiasticalFunction.LABORS} key={`function${EcclesiasticalFunction.LABORS}`}>Somente Obreiros</option>
                                <option value={EcclesiasticalFunction.MINISTERS} key={`function${EcclesiasticalFunction.MINISTERS}`}>Somente Ministros</option>
                                <option value={EcclesiasticalFunction.DEACONS} key={`function${EcclesiasticalFunction.DEACONS}`}>Diáconos e Diaconisas</option>
                                {
                                    functions.map(f => {
                                        return <option value={f.id} key={`function${f.id}`}>{f.description}</option>
                                    })
                                }
                            </select>
                            <button 
                                className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                style={{textTransform: 'none'}} 
                                type="button" 
                                tabIndex={-1}
                            >
                                <span></span>
                                <span uk-icon="icon: chevron-down"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                Membros: {totalMembers}
            </div>
            <TablePagination
                columns={columns}
                fetchData={fetchMembers}
                refresh={refresh}
            />
            <MemberDialogConfirm 
                open={isDialogDelete} 
                text={dialogDeleteText} 
                afterClose={dialogClose}
                confirmTextLabel={'Informe sua senha'}
                handleConfirmation={(confirmationCode) => dialogConfirm(confirmationCode)}
            />
        </div>
    )
}