import environment from '../environment';

export const utils = {
    printPDF,
    printPNG
}

export function printPDF(reportName: string, reportBase64: string, type: string = "application/pdf", forceNewTab: boolean = false) {
    const link = document.createElement('a');
    link.download = reportName;
    
    fetch(`data:${type};base64,${reportBase64}`)
    .then(function (resp) { return resp.blob() })
    .then(function (blob) {
        link.href = URL.createObjectURL(blob);
        if (environment.isDev || forceNewTab) {
            window.open(link.href);
        } else {
            link.click();
        }
        window.URL.revokeObjectURL(link.href);
        link.remove();
    });
}

export async function printPNG(reportName: string, reportPDFBase64: string) {
    printPDF(reportName, reportPDFBase64, "image/png");
}
