import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import UserLogin from "../../models/UserLogin";
import api from "../../services/api";
import { authenticationService } from "../../services/auth.service";
import toastOptions from "../../utils/toastOptions";
import MemberBirthdayList from "../member/member-birthday-list/MemberBirthdayList";

export default function DashboardAuxiliarSec() {
  const [activesMembers, setActivesMembers] = useState<number>();
  const [user, setUser] = useState<UserLogin>();

  useEffect(()=> {        
    async function countMembers() {
        try{
            const { data } = await api.get('members/actives/count');
            setActivesMembers(+(data));            
        } catch (ex) {
            toast.warning("Problemas ao contar membros", toastOptions);
        }
    }

    setUser(authenticationService.currentUserValue ?? undefined);

    countMembers();
  }, []);

  return <>
    <div className="uk-section uk-section-muted">
      <div className="uk-container">
        <div className="uk-grid-small uk-margin-small-left uk-margin-small-right" uk-grid="true">
          <div className="uk-width-1-2@m">
            <div>
                <div className="uk-card uk-card-primary uk-card-small uk-card-hover">
                    <div className="uk-card-header">
                        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                            <div className="uk-width-expand">
                                <h3 className="uk-card-title uk-margin-remove-bottom">Membros Ativos</h3>
                                {
                                  (user && user.churches) && (
                                    <h4 className="uk-margin-remove-top">({user.congregations?.map(c => c.description).join(', ')})</h4>
                                  )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="uk-card-body">
                        {
                            (activesMembers == undefined) ? 
                                <div uk-spinner="true; ratio:2">Carregando...</div>
                            :
                                <h1 className="uk-heading-xlarge">{activesMembers}</h1>
                        }
                    </div>
                    <div className="uk-card-footer">
                        <Link to="/membros">Acessar Membros <span uk-icon="arrow-right"></span></Link>
                    </div>
                </div>
            </div>
            <div className="uk-margin-top">                                    
              <div className="uk-card uk-card-body uk-card-success uk-flex uk-flex-middle uk-flex-center uk-card-hover">
                  <div className="uk-inline-clip uk-transition-toggle">
                      <div className="uk-transition-scale-up uk-transition-opaque">
                          <Link to="/membros/novo" className="uk-button">
                              <div className="uk-transition-scale-up uk-transition-opaque">
                                  <span uk-icon="icon: plus; ratio: 2"></span>
                                  <br/>
                                  Adicionar Membro
                              </div>            
                          </Link>
                      </div>
                  </div>
              </div>
            </div>                   
          </div>  
          <div className="uk-width-1-2@m">
            <MemberBirthdayList />
          </div>
        </div>
      </div>
    </div>
  </>
}