import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import {
  faMoneyBill,
  faPencil,
  faSearch,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayDate2, displayDateTime } from "../../../utils/masks";
import EventPayment from "../event-payment/EventPayment";
import IPersonEventDTO from "../../../models/IPersonEvent";
import DialogConfirm from "../../../components/DialogConfirm";

interface PersonListDTO {
  id: number;
  name: string;
  createdDate: Date;
  isIndividual: boolean;
  paidDate: Date;
}

type RouteParams = {
  id: string;
}

export default function EventPersonList() {
  const params = useParams<RouteParams>();
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [termSearch, setTermSearch] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>();
  const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
  const [dialogDeleteText, setDialogDeleteText] = useState<string[]>([]);
  var personSelected = useRef(0);
  const [delayedSearchTerm, setDelayedSearchTerm] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [addPayment, setAddPayment] = useState<boolean>(false);  
  const [person, setPerson] = useState<IPersonEventDTO>();
  const [totalEnrollments, setTotalEnrollments] = useState<number | undefined>(undefined);
  const [totalPaid, setTotalPaid] = useState<number | undefined>(undefined);
  const [totalCouple, setTotalCouple] = useState<number | undefined>(undefined);
  const [totalIndividual, setTotalIndividual] = useState<number | undefined>(undefined);
  const [totalPeople, setTotalPeople] = useState<number | undefined>(undefined);
  const [ixSend, setIxSent] = useState<number>(-1);

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: PersonListDTO) => <span>{row.name}</span>,
    },
    {
      name: "Inscrito em",
      sortable: true,
      cell: (row: PersonListDTO) => <span>{displayDateTime(row.createdDate)}</span>
    },
    {
      name: "Individual?",
      cell: (row: PersonListDTO) => <span>{row.isIndividual ? 'Sim' : 'Não'}</span>
    },
    {
      name: "Pago?",
      cell: (row: PersonListDTO) => <span>{displayDate2(row.paidDate)}</span>
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: IPersonEventDTO, index: number) => (
        <div className="uk-flex">
          {isReadOnly ? (
            <Link
              to={`/eventos/${params.id}/pessoas/${row.id}`}
              uk-tooltip="title: Visualizar"
              className="uk-margin-small-right"
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>
          ) : (
            <Link
              to={`/eventos/${params.id}/pessoas/${row.id}`}
              uk-tooltip="title: Editar"
              className="uk-margin-small-right"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Link>
          )}
          {
            !row.paidDate && [
              <a
                href="#"
                uk-tooltip="title: Pagamento"
                className="uk-margin-small-right"
                onClick={(ev) => {
                  ev.preventDefault();
                  handlePayment(row);
                }}
              >
                <FontAwesomeIcon icon={faMoneyBill} />
              </a>,
              <a
                href="#"
                uk-tooltip="title: Lembrete Pagamento"
                className="uk-margin-small-right"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleEmailPayment(row, index);
                }}
              >
                {
                  (ixSend == index) ? <div uk-spinner="true; ratio:0.5"></div> : <FontAwesomeIcon icon={faTriangleExclamation} />
                }
              </a>
            ]
          }
          {!isReadOnly && (
            <a
              href="#"
              uk-tooltip="title: Remover"
              className="uk-margin-small-right"
              onClick={(ev) => {
                ev.preventDefault();
                handleRemove(row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </a>
          )}
        </div>
      ),
    }
  ];

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());

    countStatistics();
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    setRefresh(!refresh);
  }, [delayedSearchTerm]);

  const countStatistics = async () => {
    const { data : enrollments } = await api.get(`events/${params.id}/count/enrollments`);
    const { data : paids } = await api.get(`events/${params.id}/count/paids`);
    const { data: couple } = await api.get(`events/${params.id}/count/couple`);
    const { data: individual } = await api.get(`events/${params.id}/count/individual`);
    const { data: people } = await api.get(`events/${params.id}/count/people`);

    setTotalEnrollments(enrollments);
    setTotalPaid(paids);
    setTotalCouple(couple);
    setTotalIndividual(individual);
    setTotalPeople(people);
  }

  const fetchPeople = async (page: number, limit: number) => {
    try {
      let requestParams = {
        page: page,
        limit: limit,
        open: open
      } as any;

      if (termSearch) {
        requestParams = { ...requestParams, name: termSearch?.toUpperCase() };
      }

      const response = await api.get(`events/${params.id}/people/`, {
        params: requestParams
      });

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleRemove = async (row: IPersonEventDTO) => {
    setDialogDeleteText([
      `Deseja realmente remover ${row.name}?`
    ]);
    personSelected.current = row.id;
    setIsDialogDelete(true);
  };

  const dialogClose = () => {
    setIsDialogDelete(false);
  };

  const handleSearchByName = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handleOpen = async(selected: boolean) => {
    setOpen(selected);
    setRefresh(!refresh);
  }

  const handlePayment = async (row: IPersonEventDTO) => {
    setPerson(row);
    setAddPayment(true);
  };
  
  const afterHandleSubmit = (paidDate: Date) => {
    if (person) {
      person.paidDate = paidDate;
      countStatistics();
    }
    afterCloseModal();
  }

  const afterCloseModal = () => {
    setAddPayment(false);
    setPerson({} as IPersonEventDTO);    
  }

  const dialogConfirm = async () => {
    try {
      await api.delete(`/events/person/${personSelected.current}`);
      setIsDialogDelete(false);
      toast.success("Pessoa removida com sucesso!", toastOptions);
      setRefresh(!refresh);
      countStatistics();
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const handleEmailPayment = async (row: IPersonEventDTO, ix: number) => {
    try {            
      setIxSent(ix);
      await api.post(`/events/people/${row.id}/email/pending`);
      toast.success(`Cobrança enviada para ${row.name} com sucesso!`, toastOptions);
    } catch (e: any) {
        e.messages?.forEach((m: string) => {
            toast.error(m, toastOptions);
        });
    } finally {
        setIxSent(-1);
    }
  };

  return (
    <div id="page-event-person">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Inscrições Ativas
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/eventos"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-flex@m uk-flex-middle">
            <button 
              className="uk-button uk-button-primary uk-button-small uk-margin-small-right"
              type="button"
            >
              Inscrições: {totalEnrollments == undefined ? <div uk-spinner="true; ratio:0.5"></div> : totalEnrollments}
            </button>
            <button 
              className="uk-button uk-button-success uk-button-small uk-margin-small-right"
              type="button"
            >
              Pagas: {totalPaid == undefined ? <div uk-spinner="true; ratio:0.5"></div> : totalPaid}
            </button>
            <button 
              className="uk-button uk-button-danger uk-button-small uk-margin-small-right"
              type="button"
            >
              Casais: {totalCouple == undefined ? <div uk-spinner="true; ratio:0.5"></div> : totalCouple}
            </button>
            <button 
              className="uk-button uk-button-secondary uk-button-small uk-margin-small-right"
              type="button"
            >
              Individuais: {totalIndividual == undefined ? <div uk-spinner="true; ratio:0.5"></div> : totalIndividual}
            </button>
            <button 
              className="uk-button uk-button-warning uk-button-small"
              type="button"
            >
              Qtd. Pessoas: {totalPeople == undefined ? <div uk-spinner="true; ratio:0.5"></div> : totalPeople}
            </button>
          </div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearchByName(ev.target.value);
                }}
              />
            </div>
            <div className="uk-width-1-4@m uk-flex uk-flex-middle">
              <label className="uk-form-label uk-margin-small-right" htmlFor="type">Inscrições não Pagas?</label>
              <input className="uk-checkbox uk-margin-left" 
                type="checkbox"    
                id="open"
                checked={open}
                onChange={(e) => {
                  handleOpen(e.target.checked);
                }}/>
            </div>
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchPeople}
            refresh={refresh}
          />
        </div>
      </div>
      <EventPayment add={addPayment} 
        person={person}
        afterHandleSubmit={afterHandleSubmit} 
        afterCloseModal={afterCloseModal} />
      <DialogConfirm
        open={isDialogDelete}
        text={dialogDeleteText}
        afterClose={dialogClose}
        handleConfirmation={(confirmationCode) =>
          dialogConfirm()
        }
      />
    </div>
  );
}
