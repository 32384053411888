export const storageService = {
    set,
    get,
    remove
}

async function set(key: string, value: string) {
    const storagedItem = localStorage.getItem(key);
    if (storagedItem && storagedItem != value) {        
        localStorage.removeItem(key);
    }    
    if (storagedItem != value) {
        localStorage.setItem(key, value);
    }
    return true;    
}

async function get(key: string) {
    return localStorage.getItem(key);
}

async function remove(key: string) {
    localStorage.removeItem(key);
    return true;
}