import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { TypeHistory } from '../../../models/enums/TypeHistory';
import IMemberDeleteDTO from '../../../models/IMemberDeleteDTO';

interface Params {
    open: boolean;
    text: string[];
    title?: string;
    afterClose?: any;
    confirmTextLabel?: string; 
    handleConfirmation(dto: IMemberDeleteDTO): any;   
}

export default function MemberDialogConfirm(props: Params) {
    const { open, text, title, afterClose, confirmTextLabel, handleConfirmation } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [textInput, setTextInput] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [note, setNote] = useState<string>("");

    useEffect(() => {
        setIsOpen(open);
        setTextInput("");
        setReason(" ");
        setNote("");
    }, [open]);

    const handleCancel = () => {
        setIsOpen(false);
        if (afterClose) {
            afterClose();
        }
    };

    const handleConfirm = () => {
        if (handleConfirmation) {
            handleConfirmation({
                confirmationCode: textInput,
                reason: reason as TypeHistory,
                note: note
            });
        }
    };

    const handleReason = (value: any) => {
        setReason(value);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle id="alert-dialog-title">{title ? title : 'Confirmação'}</DialogTitle>
                <DialogContent>
                    {
                        text.map((t, i) => {
                            return (
                                <Typography gutterBottom key={`modal${i}`}>
                                    {t}
                                </Typography>
                            ) 
                        })
                    }
                                            
                    <InputLabel htmlFor="max-width">Motivo</InputLabel>
                    <Select
                        autoFocus
                        value={reason}
                        onChange={(e)=> handleReason(e.target.value)}
                        label="Motivo"
                        inputProps={{
                            name: 'max-width',
                            id: 'max-width',
                        }}
                        style={{
                            minWidth: 300
                        }}
                    >
                        <MenuItem value=" ">Selecione o motivo...</MenuItem>
                        <MenuItem value={TypeHistory.CHANGE_CHURCH}>{TypeHistory.CHANGE_CHURCH}</MenuItem>            
                        <MenuItem value={TypeHistory.CHANGE_CITY}>{TypeHistory.CHANGE_CITY}</MenuItem>            
                        <MenuItem value={TypeHistory.LEAVE}>{TypeHistory.LEAVE}</MenuItem>
                        <MenuItem value={TypeHistory.OTHER}>{TypeHistory.OTHER}</MenuItem>            
                    </Select>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label={"Observação"}
                        multiline={true}
                        rows={3}
                        fullWidth
                        autoComplete={"off"}
                        onChange={(e)=> setNote(e.target.value)}
                    />
                    {
                        confirmTextLabel && 
                            <form>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label={confirmTextLabel}
                                    type="password"
                                    fullWidth
                                    onChange={(e) => setTextInput(e.target.value)}
                                />
                            </form>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} color="primary">
                        Confirmar
                    </Button>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}