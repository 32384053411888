import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import IMeetingOpenDTO from '../../models/IMeetingOpenDTO';
import Member from '../../models/Member';
import api from '../../services/api';
import { authenticationService } from '../../services/auth.service';
import { displayDateTime } from '../../utils/masks';
import CongregationChart from '../congregation/congregation-chart/CongregationChart';
import MeetingChart from '../meeting-chart/MeetingChart';

import '../shared/card.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastOptions from '../../utils/toastOptions';
import MemberBirthdayList from '../member/member-birthday-list/MemberBirthdayList';
import { UserRole } from '../../models/enums/UserRole';

export default function DashboardMain() {
    const [activesMembers, setActivesMembers] = useState<number>();
    const [meetingOpened, setMeetingOpened] = useState<IMeetingOpenDTO>();
    const [hasMeetingOpen, setMeetingOpen] = useState<boolean | undefined>(undefined);
    const [lastMember, setLastMember] = useState<Member>();
    const [isReadOnly, setReadOnly] = useState<boolean>();

    useEffect(()=> {        
        async function countMembers() {
            try{
                setReadOnly(authenticationService.isReadOnly());

                const { data } = await api.get('members/actives/count');

                setActivesMembers(+(data));
                
                const { data: dataMeeting } = await api.get('meetings/open');

                setMeetingOpened(dataMeeting.item);
                
                if (authenticationService.isAdmin()) {
                    try {
                        const { data: lastMemberMeeting } = await api.get('/members/last-modified');
                        setLastMember(lastMemberMeeting.item);                    
                    } catch (error) {
                        toast.warning("Problemas ao carregar último modificado", toastOptions);
                    }
                }

                setMeetingOpen(
                    (!authenticationService.isAuxSec() && dataMeeting.success && dataMeeting.item?.meeting) ? true : false
                );

            } catch (ex) {
                toast.warning("Problemas ao carregar reunião", toastOptions);
            }
        }

        countMembers();
    }, []);

    return <>
        <div className="uk-section uk-section-muted">
            <div className="uk-container">
                <div className="uk-grid-small uk-margin-small-left uk-margin-small-right" uk-grid="true">
                    <div className="uk-width-1-2@m">
                        {
                            !authenticationService.isRoutine() &&
                                <div>
                                    <div className="uk-card uk-card-primary uk-card-small uk-card-hover">
                                        <div className="uk-card-header">
                                            <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                                <div className="uk-width-expand">
                                                    <h3 className="uk-card-title uk-margin-remove-bottom">Membros Ativos</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="uk-card-body">
                                            {
                                                (activesMembers == undefined) ? 
                                                    <div uk-spinner="true; ratio:2">Carregando...</div>
                                                :
                                                    <h1 className="uk-heading-xlarge">{activesMembers}</h1>
                                            }
                                        </div>
                                        <div className="uk-card-footer">
                                            <Link to="/membros">Acessar Membros <span uk-icon="arrow-right"></span></Link>
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className="uk-margin-top">
                            {
                                hasMeetingOpen == undefined && (
                                    <div className="uk-card uk-card-body uk-card-success uk-flex uk-flex-middle uk-flex-center uk-card-hover">
                                        <div className="uk-inline-clip uk-transition-toggle">
                                            <div className="uk-transition-scale-up uk-transition-opaque">
                                                <div style={{ color: "#FFFFFF" }} uk-spinner="true; ratio:2">
                                                    Carregando...
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                )
                            }
                            {
                                hasMeetingOpen == false &&
                                <div className="uk-card uk-card-body uk-card-success uk-flex uk-flex-middle uk-flex-center uk-card-hover">
                                    <div className="uk-inline-clip uk-transition-toggle">
                                        {
                                            isReadOnly ? 
                                                <div className="uk-transition-scale-up uk-transition-opaque">
                                                    <span><br/>Nenhuma Reunião em Andamento<br/><br/></span>
                                                </div>
                                            : 
                                                <Link to="/reuniao/nova" className="uk-button">
                                                    <div className="uk-transition-scale-up uk-transition-opaque">
                                                        <span uk-icon="icon: plus; ratio: 2"></span>
                                                        <br/>
                                                        Nova Reunião
                                                    </div>            
                                                </Link>                                    
                                        }
                                        
                                    </div>
                                </div>
                            }
                            {
                                hasMeetingOpen &&
                                <div className="uk-card uk-card-warning uk-card-small uk-card-hover">
                                    <div className="uk-card-header">
                                        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                                            <div className="uk-width-expand">
                                                <h3 className="uk-card-title uk-margin-remove-bottom">
                                                    Reunião em Andamento...
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card-body uk-margin-medium-top">
                                        <h3>
                                            <span uk-icon="icon: clock; ratio: 2"></span>
                                            <span className="uk-margin-left">
                                                Iniciada: {displayDateTime(meetingOpened?.meeting.createdDate)}
                                            </span>
                                        </h3>
                                        <h4>
                                            {meetingOpened?.qtyMembers} membro(s) presente(s)
                                        </h4>
                                    </div>
                                    {
                                        (authenticationService.canChange() || authenticationService.isRoutine()) && (
                                            <div className="uk-card-footer">
                                                {
                                                    !authenticationService.isRoutine() && (
                                                        <Link to={`/reuniao/${meetingOpened?.meeting.id}`}>
                                                            Editar <span uk-icon="pencil"></span>
                                                        </Link>
                                                    )
                                                }
                                                <br/>
                                                <Link to="/reuniao/em-andamento">
                                                    Acessar <span uk-icon="arrow-right"></span>
                                                </Link>           
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        </div>                   
                    </div>  
                    {
                        !authenticationService.isRoutine() && (
                            <div className="uk-width-1-2@m">
                                <MemberBirthdayList />
                            </div>
                        )
                    }
                </div>
                {
                    !authenticationService.isRoutine() && (
                        <div className="uk-grid-match uk-child-width-1-2@m uk-flex-center uk-text-center" uk-grid="true">
                            <div>
                                <CongregationChart/>              
                            </div>
                            <div>
                                <MeetingChart/>              
                            </div>
                        </div>
                )}
                {
                    (authenticationService.isAdmin() && lastMember) &&
                    <div className="uk-section uk-section-primary uk-light uk-padding-small uk-margin-top">
                        <div className="uk-container">
                            <div className="uk-margin-left uk-margin-right">
                                <div className="uk-text-left uk-text-small">
                                    <h4 className="uk-heading-line"><span>Último membro modificado</span></h4>
                                    <span className="uk-text-bolder">
                                        {`${lastMember.name} por ${lastMember.modifiedBy} em ${displayDateTime(lastMember.modifiedDate)}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </>
}