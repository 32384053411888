import { useEffect } from 'react';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import api from '../../services/api';
import { displayDate } from '../../utils/masks';

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        }
    },
};

export const data = {
    labels: [],
    datasets: [
        {
            label: 'Membros',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }
    ]
};


interface MeetingChartDTO {
    id: number;
    meetingDate: string;
    title: string;
    members: number;
}

const MeetingChart = () => {

    const [graphData, setGraphData] = useState<any>();
    const [hasError, setError] = useState<boolean>();

    useEffect(()=> {
        const init = async()=> {
            try{
                const { data: result } = await api.get('/meetings/chart/members');
    
                if (!result.success) {
                    setError(true);
                    return;
                }
                
                if (!result.item){
                    setError(true);
                    return;
                }
    
                setGraphData(result.item);
                
                data.labels = result.item.map((c: MeetingChartDTO) => { return displayDate(c.meetingDate); });
                data.datasets[0].data = result.item.map((c: MeetingChartDTO) => { return c.members; });
            } catch (ex) {
                console.log(ex);
                setGraphData(null);
                setError(true);
            }
        }
        init();
    }, []);

    return (
        <>
            <h3 className='uk-text-center uk-text-bold'>Reuniões x Presenças</h3>  
            {
                (hasError && hasError === true) ?
                    <span className="uk-label uk-label-danger uk-text-center">Não foi possível carregar o gráfico das reuniões</span>
                :
                (!graphData) ?
                    <div className="uk-text-center" uk-spinner="true; ratio:2">Carregando...</div>
                :                        
                    <Line data={data} options={options} />
            }
        </>
    );    
} 

export default MeetingChart;