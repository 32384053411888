import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import "react-toastify/dist/ReactToastify.css";
import Meeting from "../../../models/Meeting";
import { displayDateTime } from "../../../utils/masks";

export default function MeetingCard() {
  const [totalMembers, setTotalMembers] = useState<number>();
  const [lastMeeting, setLastMeeting] = useState<Meeting>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    fetchMembers();
  };

  const fetchMembers = async () => {
    try {
      let params = {
        page: 1,
        limit: 1,
      } as any;

      const response = await api.get(`/meetings`, {
        params: params,
      });

      const { data: responseChart } = await api.get('/meetings/chart/members');

      setTotalMembers(0);
      
      if (responseChart.item.length > 0) {
        setTotalMembers(responseChart.item[0].members);
      }

      setLastMeeting(response.data.data[0]);

      return response;
    } catch {
      return { total: 0 };
    }
  };

  return (
    <div
      className="uk-card uk-card-alternative uk-card-small uk-card-hover"
      style={{ minHeight: "100%" }}
    >
      <div className="uk-card-header">
        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
          <div className="uk-width-expand">
            <h3 className="uk-card-title uk-margin-remove-bottom">
              Última Reunião
            </h3>
          </div>
        </div>
      </div>
      <div className="uk-card-body">
        {totalMembers == undefined ? (
          <div style={{ color: "#FFFFFF" }} uk-spinner="true; ratio:2">
            Carregando...
          </div>
        ) : (
          <>     
            <h4>{lastMeeting?.title}</h4>
            {
              (lastMeeting && lastMeeting.timeClose) ? 
                <h5>Fechada em: {displayDateTime(lastMeeting.timeClose)}</h5> : 
                <h5>Em andamento...</h5>
            }
            <span className="uk-text-default">{totalMembers} Membro(s) Presente(s)</span>
          </>
        )}
      </div>
      <div className="uk-card-footer uk-flex uk-flex-column">
        <Link to={`/reuniao/${lastMeeting?.id}`}>
          Acessar Reunião <span uk-icon="arrow-right"></span>
        </Link>
        <Link to="/reuniao/lista">
          Visualizar Reuniões <span uk-icon="search"></span>
        </Link>
      </div>
    </div>
  );
}
