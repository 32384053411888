import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ISchedule from "../../../models/ISchedule";
import api from "../../../services/api";
import 'moment/locale/pt-br';

import './schedule-calendar.css';

export default function ScheduleCalendar() {
  const navigate = useNavigate();
  const [weekdayshort, setWeekDays] = useState([] as string[]);
  const [schedules, setSchedules] = useState([] as ISchedule[]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);  
  const [years, setYears] = useState([] as number[]);
  const initialYear = useRef<number>(2022);
  
  useEffect(()=>{
    window.scrollTo(0, 0);
    moment.locale('pt-br');
    setWeekDays(moment.weekdaysShort());    

    handleYears();

  },[]);

  const handleYears = () => {
    setYears([]);

    let initYear = currentYear;

    if (moment().month() >= 10) { //Desde novembro já liberar opção de ver 2025
      initYear++;
    }

    for (let i = initYear; i >= initialYear.current; i--) {
      setYears(array => [...array, i]);
    }

    fetchCalendar(currentYear);
  }

  const handleSearchYear = (newYear: number) => {
    setCurrentYear(newYear);
    fetchCalendar(newYear);    
  }

  const firstWeekDayOfMonth = (ix: number) => {
    let firstDay = moment(new Date(currentYear, ix, 1))
                 .startOf("month")
                 .format("d"); 
    return firstDay;
  };

  const daysInMonth = (ix: number) => {
    return moment(new Date(currentYear, ix, 1)).daysInMonth();
  };

  const fetchCalendar = async (year: number) => {
    try {    

      const response = await api.get(`schedules/calendar/${year}`);

      setSchedules(response.data.data);

      return response;
    } catch {
      return { data: [], total: 0 };
    } finally {
      setLoading(false);
    }
  };

  const monthDays = (month: number)=> {
    const firstWeekDay = +(firstWeekDayOfMonth(month));

    let blanks = [];
    let days = [];

    for (let i = 0; i < firstWeekDay; i++) {
      blanks.push(<td className="day empty pastDay">{""}</td>);
    }

    for (let d = 1; d <= daysInMonth(month); d++) {
      let objEvent = schedules.find(x => {
        let date = moment(x.date.toString());
        return date.date() == d && date.month() == month;
      });

      let hasEvent = objEvent ? "has-event" : "";

      days.push(
        <td key={d} className={`day ${hasEvent}`}>
          {
            hasEvent ? (
              <span uk-tooltip={`title: ${objEvent?.hour} - ${objEvent?.description}`}
                className={`${isBeforeToday(objEvent?.date)}`}
              >{d}</span>
            ) : <span className={`${isBeforeToday(new Date(currentYear, month, d))}`}>{d}</span>
          }
        </td>
      );
    }

    var totalSlots = [...blanks, ...days];

    let rows = [] as any[];
    let cells = [] as any[];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    return rows.map((d, i) => {
      return <tr>{d}</tr>;
    });
  }

  const isBeforeToday = (date?: Date) => {
    if (!date) return "";
    return moment(date).isBefore(moment(), "date") ? 'pastDay' : '';
  }

  return (
    <div id="page-schedule-calendar">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-card-body">
        <div className="uk-grid-small uk-flex uk-flex-middle" uk-grid="true">
          <div className="uk-width-3-4@s uk-flex uk-flex-middle">
            <label htmlFor="year" className="uk-margin-right">Ano</label>
            <div className="uk-form-controls">
              <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                <select className="uk-form-small"
                  id="year" 
                  placeholder="Ano"
                  name="year"
                  value={currentYear}
                  onChange={(ev) => handleSearchYear(+(ev.target.value))}
                >  
                  {
                    years?.map(y => {
                        return <option key={`ano${y}`} value={`${y}`}>{y}</option>
                    })
                  }
                </select>
                <button 
                  className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{textTransform: 'none'}} 
                  type="button" 
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="uk-width-1-4@s uk-flex uk-flex-right">
            <button className="uk-button uk-button-primary uk-button-small" onClick={()=> navigate(-1)}>Voltar</button>
          </div>
        </div>
        <div className="uk-section uk-section-muted uk-padding-small uk-margin-bottom uk-margin-top">
          <div className="uk-container" style={{textAlign: 'center'}}>
            {
              loading ? 
              (
                <div uk-spinner="true; ratio:2"></div>
              ) :
              (
                <div className="uk-grid-small" uk-grid="true">
                  {
                    moment.months().map((m, ix) => {                
                      return (
                        <div className="uk-width-1-3@s">
                          <div className="month" key={`month-${m}`}>
                            {m}
                            {weekdayshort.map(weekDay => {
                              return (
                                <th key={`${m}-${weekDay}`} className="week-day">
                                  {weekDay}
                                </th>
                              )
                            })}
                            {monthDays(ix)}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>       
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}