import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { authenticationService } from "../../../services/auth.service";
import Modal from "../../../components/modal";

interface Params {
  memberId: number;
  noteId?: number;
  add: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

interface MemberNote {
  id?: number;
  idMember: number;
  note: string;
}

export default function MemberNoteModal(props: Params) {
  const { memberId, noteId, add, afterHandleSubmit, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [memberNote, setMemberNote] = useState<MemberNote>();
  const [isReadOnly, setReadOnly] = useState<boolean>(true);

  const schema = yup.object().shape({
    note: yup.string().required("Observação é obrigatória")
  });

  const {
    register,
    handleSubmit,
    reset,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setReadOnly(authenticationService.isReadOnly());
    setModelOpen(add);
  }, [noteId, add]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setModelOpen(false);
    afterCloseModal();
  };

  const onSubmit = async (data: MemberNote) => {
    try {
      const formData = new FormData();

      data.id = memberNote?.id;
      data.idMember = memberId;

      await api.post(`/members/${memberId}/notes`, data);
      toast.success("Observação cadastrada com sucesso!", toastOptions);

      handleCloseModal();
      afterHandleSubmit();
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">
            Adicionar Observação
          </h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={handleCloseModal}
        />
        <form onReset={reset} onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            return handleSubmit<MemberNote>(onSubmit)(e)
          }}
        >
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1@s">
                <label className="uk-form-label" htmlFor="note">
                  Observação
                </label>
                <textarea
                  id="note"
                  name="note"
                  className="uk-textarea uk-form-small uk-text-uppercase"
                  rows={4}
                  style={{ resize: "none" }}
                  defaultValue={memberNote?.note}
                  autoComplete={"off"}
                  ref={register}
                />
                <small className="uk-text-danger">{errors.note?.message}</small>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={handleCloseModal}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={formState.isSubmitting || isReadOnly}
                className="uk-button uk-button-primary uk-margin-left"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
