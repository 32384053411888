import * as yup from 'yup';
import moment from 'moment';

export interface Validator {
    isDate: boolean,
    isOptionalDate: boolean,
    isNumeric: boolean,
    isTime: boolean,
    isCEP: boolean,
    message: string
}

export function getValidator(validator: Partial<Validator>) {
    let itemValidator;

    if (validator.isDate) {
        itemValidator = yup.date()
            .transform((value, originalValue) => {
                value = moment(originalValue, 'DD/MM/yyyy', true);
                return value.isValid() ? value.toDate() : new Date('');
            })
            .typeError(validator.message ? validator.message : 'Informe uma data');

        return itemValidator;
    }

    if (validator.isNumeric) {
        itemValidator = yup.number()
            .transform((value, originalValue) => { return isNaN(value) ? undefined : value; })
            .typeError('Informe apenas números')
            .max(1000000, `Máximo 999999 números`);
        return itemValidator;
    }

    if (validator.isOptionalDate) {
        itemValidator = yup.date().nullable().default(null)
            .transform((value, originalValue) => {
                if (value == 'Invalid Date' && originalValue == 'Invalid Date') return null;
                if (originalValue == '') return null;
                value = moment(originalValue, 'DD/MM/yyyy', true);
                return value.isValid() ? value.toDate() : new Date('');
            })
            .typeError(validator.message ? validator.message : 'Informe uma data');

        return itemValidator;
    }

    if (validator.isTime) {
        itemValidator = yup.string()
        .transform((value, originalValue) => {
            return moment(value, 'HH:mm').isValid() ? value : '';
        })
        .typeError('Informe uma hora válida');

        return itemValidator;
    }

    if (validator.isCEP) {
        itemValidator = yup.string()
            .required('CEP obrigatório')
            .matches(/^\d{5}-\d{3}$/, 'CEP inválido');            
        return itemValidator;
    }

    return yup.string();
}