import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Meeting from "../../../models/Meeting";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authenticationService } from "../../../services/auth.service";
import api from "../../../services/api";
import toastOptions from "../../../utils/toastOptions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { displayDateTime } from "../../../utils/masks";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './meeting-edit.css';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { utils } from "../../../utils/utils";
import MeetingDocList from "../meeting-docs-list/MeetingDocList";
import Editor from "../../../components/editor";

type MeetingParams = {
    id: string;
}

export default function MeetingEdit() {
    const params = useParams<MeetingParams>();
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [isRunningPrintAgenda, setRunningPrintAgenda] = useState<boolean>(false);
    const [isOficial, setIsOficial] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [meeting, setMeeting] = useState<Meeting>({
        title: `Reunião de Obreiros do Dia ${moment(new Date()).format('DD/MM/yyyy')}`,
        createdDate: new Date()
    });

    const schema = yup.object().shape({
        title: yup.string().required('Título obrigatório')
            .max(50, (val) => `Máximo ${val.max} caracteres`)
            .min(3, (val) => `Mínimo ${val.min} caracteres`),
        note: yup.string()
            .max(3000, (val) => `Máximo ${val.max} caracteres`)
    });

    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    useEffect(()=> {
        const init = async()=> {
            const id = +(params.id ?? 0);

            if (id > 0) {
                await handleEdit(id);
            }

            setReadOnly(authenticationService.isReadOnly());
        }

        const handleEdit = async (id: number) => {
            try {
                setIsLoading(true);

                const { data } = await api.get(`meetings/${id}`);

                setMeeting(data.item);

                if (data.item.agenda) {
                    const blocksFromHTML = convertFromHTML(data.item.agenda);
                    if (blocksFromHTML) {
                        const contentState = ContentState.createFromBlockArray(
                            blocksFromHTML.contentBlocks,
                            blocksFromHTML.entityMap,
                        );
                        setEditorState(EditorState.createWithContent(contentState));
                    }
                }

                setValue('title', data.item.title);
                setValue('isOficial', data.item.isOficial);
                setIsOficial(data.item.isOficial);
            } catch (e: any) {
                e.messages?.forEach((m: string) => {
                    toast.error(m, toastOptions);
                });
            } finally {
                setIsLoading(false);
            }
        }

        init();
    }, [params.id]);
    
    const { register, handleSubmit, reset, setValue, errors, formState } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: Meeting, e: any) => {
        try {
            if (editorState) {
                data.agenda = draftToHtml(convertToRaw(editorState?.getCurrentContent()));
            }
            
            data.isOficial = isOficial;
            data.id = meeting.id;
            await api.put('meetings', data);
            toast.success('Reunião atualizada com sucesso!', toastOptions);
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }

    const handleDownloadAgenda = async () => {
        setRunningPrintAgenda(true);
        try {
            const { data } = await api.get(`meetings/${meeting?.id}/agenda/report`);   
            const name = `${new Date(meeting.createdDate).toLocaleDateString().replaceAll('/', '-')}-ata-${new Date().getTime()}.pdf`;
            utils.printPDF(name, data.item);            
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.warning(m, toastOptions);
            });
        } finally {
            setRunningPrintAgenda(false);
        }
    }

    return (
        (
            (isLoading) ? (
                <div className="uk-flex uk-flex-center">
                    <div uk-spinner="true; ratio:2">Carregando...</div>
                </div>
            ) :
            <>
                <div id="page-edit-meeting">                    
                    <form onSubmit={handleSubmit<Meeting>(onSubmit)} onReset={reset}>
                        <div className="uk-modal-body">
                            <ul uk-accordion="true">
                                <li className="uk-open">
                                    <a className="uk-accordion-title" href="#">Reunião</a>
                                    <div className="uk-accordion-content">
                                        <div className="uk-grid-small" uk-grid="true">                           
                                            <div className="uk-width-1-2@s">
                                                <label className="uk-form-label" htmlFor="title">Título da Reunião</label>
                                                <textarea 
                                                    id="title" 
                                                    name="title" 
                                                    placeholder="Título" 
                                                    className="uk-textarea uk-form-small uk-text-uppercase"
                                                    defaultValue={meeting?.title}
                                                    style={{resize: 'none'}}
                                                    rows={2}
                                                    maxLength={100}
                                                    ref={register} />
                                                <small className="uk-text-danger">{errors.title?.message}</small>
                                                <br/>
                                                <div className="uk-margin-top">
                                                    <label>
                                                        Membros Presentes: {meeting.members?.length ?? 0}
                                                    </label>
                                                    <Link 
                                                        className="uk-button uk-button-primary uk-button-small uk-margin-left" 
                                                        to={`/reuniao/${meeting.id}/membros`}>
                                                        Membros Presentes <span uk-icon="arrow-right"></span>
                                                    </Link>   
                                                </div>
                                                <br/>
                                                {
                                                    meeting.timeClose != undefined && (
                                                        <label className="uk-margin-top">
                                                            Fechada em: {displayDateTime(meeting.timeClose)}
                                                        </label>
                                                    )                                        
                                                }
                                            </div>
                                            <div className="uk-width-1-2@s">
                                                <label className="uk-form-label" htmlFor="note">Observação</label>
                                                <textarea
                                                    id="note" 
                                                    name="note"
                                                    className="uk-textarea uk-form-small uk-text-uppercase" 
                                                    rows={6} 
                                                    style={{resize: 'none'}}
                                                    defaultValue={meeting?.note}
                                                    ref={register}                                
                                                />
                                                <small className="uk-text-danger">{errors.note?.message}</small>
                                            </div>
                                            <div className="uk-width-1-4@s">
                                                <label className="uk-form-label uk-margin-small-right" htmlFor="type">Oficial?</label>
                                                <input className="uk-checkbox uk-margin-left" 
                                                    type="checkbox"
                                                    defaultValue={`${meeting.isOficial}`}
                                                    checked={isOficial}
                                                    ref={register}
                                                    onChange={(e) => {
                                                        setIsOficial(e.target.checked);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a className="uk-accordion-title" href="#">Ata</a>
                                    <div className="uk-accordion-content">
                                        <div className="uk-grid-small" uk-grid="true">                                    
                                            <div className="uk-width-1-1@s">
                                                <button 
                                                    id="btn-print"
                                                    className="uk-button uk-button-primary uk-margin-left uk-button-small" 
                                                    type="button"
                                                    disabled={isRunningPrintAgenda}
                                                    onClick={()=> handleDownloadAgenda()}
                                                >                                                    
                                                    {   
                                                        isRunningPrintAgenda ? 
                                                        <div uk-spinner="true; ratio:0.5"></div> : 
                                                        <span className="uk-margin-small-right" uk-icon={`icon: download;`}></span>
                                                    }
                                                    Baixar Ata
                                                </button>
                                            </div>
                                            <div className="uk-width-1-1@s">
                                                <Editor 
                                                    editorState={editorState}
                                                    onEditorStateChange={(editorState: EditorState) => setEditorState(editorState)}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"                                                 
                                                    toolbar={{
                                                        options: ['inline', 'history'],
                                                        inline: { inDropdown: false, options:[] },
                                                        history: { inDropdown: true }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="uk-modal-footer">
                            <div className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center" uk-grid="true">
                                <Link to="/reuniao/lista" className="uk-button uk-button-default uk-margin-left">
                                    Voltar
                                </Link>
                                <button 
                                    id="btn-save"
                                    disabled={formState.isSubmitting || isReadOnly}
                                    className="uk-button uk-button-primary uk-margin-left" 
                                    type="submit">                                    
                                    {formState.isSubmitting && <div uk-spinner="true"></div>}
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>

                    {
                        (meeting && (meeting?.id ?? 0)) > 0 && (
                            <ul uk-accordion="true">
                                <li>
                                    <a className="uk-accordion-title" href="#">Anexos</a>
                                    <div className="uk-accordion-content">
                                        <MeetingDocList meetingId={meeting?.id ?? 0}/>
                                    </div>
                                </li>
                            </ul>
                        )
                    }
                </div>
            </>   
        )
    )
}