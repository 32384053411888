import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";

import "react-toastify/dist/ReactToastify.css";
import Param from "../../../models/Param";
import { displayDateTime } from "../../../utils/masks";
import MemberDeletedDetail from "../member-deleted-detail/MemberDeletedDetail";
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { authenticationService } from "../../../services/auth.service";

interface MemberListDTO {
  id: number;
  name: string;
  ecclesiasticalFunction: any;
  congregation: any;
  modifiedDate: Date;
}

export default function MemberDeletedList() {
  const [refresh, setRefresh] = useState<boolean>();
  const [totalMembers, setTotalMembers] = useState(0);
  const [seeDetail, setSeeDetail] = useState<boolean>(false);
  const [termSearch, setTermSearch] = useState<string>("");
  const [congregations, setCongregations] = useState<Param[]>([]);
  const [congSearch, setCongSearch] = useState<number>(0);
  var memberSelected = useRef(0);
  const navigate = useNavigate();
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: MemberListDTO) => (
        <span>{row.name}</span>
      )
    },
    {
      name: "Função",
      selector: "ecclesiasticalFunction.description",
      sortable: true,
    },
    {
      name: "Congregação",
      sortable: true,
      cell: (row: MemberListDTO) => (
        <span>{row.congregation.description}</span>
      )
    },
    {
      name: "Removido em",
      sortable: true,
      cell: (row: MemberListDTO) => (
        <span>{displayDateTime(row.modifiedDate)}</span>
      )
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: MemberListDTO) => (
        <div className="uk-flex">
          <a href="#" uk-tooltip="title: Ver Detalhes"
            className="uk-margin-right"
            onClick={(ev) => { ev.preventDefault(); handleDetail(row); }}
          >
            <FontAwesomeIcon icon={faEye} />
          </a>

          {
            authenticationService.isAdmin() && (
              <Link to={`/membros/${row.id}`} 
                  uk-tooltip="title: Visualizar"
                  className="uk-margin-right"
                  target={"blank"}
              >
                  <FontAwesomeIcon icon={faSearch} />
              </Link>
            )
          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSeeDetail(false);
    init();
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
      // Perform the search operation with delayedSearchTerm
      // This will be called only when the user stops typing for the specified delay time
      setRefresh(!refresh);
  }, [delayedSearchTerm]);

  const init = async () => {
    const dataCongregations = await api.get("congregations/dashboard");
    setCongregations(dataCongregations.data);
  };

  const fetchMembers = async (page: number, limit: number) => {
    try {
      setTotalMembers(0);

      let params = {
        page: page,
        limit: limit,
        idCongregation: congSearch,
      } as any;

      if (termSearch) {
        params = { ...params, name: termSearch?.toUpperCase() };
      }

      const response = await api.get(`/members/deleted`, {
        params: params,
      });

      setTotalMembers(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };  

  const handleSearchByNane = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
        setTermSearch(search);
    }
  }

  const handleSearch = async (congregation: number) => {
    await setCongSearch(congregation);

    setRefresh(!refresh);
  };

  const handleDetail = async (row: MemberListDTO) => {
    memberSelected.current = row.id;
    setSeeDetail(true);
  };

  const dialogClose = () => {
    setSeeDetail(false);
  };

  return (
    <div id="page-member-deleted-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Membros Removidos
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/secretaria"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div>Total: {totalMembers}</div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearchByNane(ev.target.value);
                }}
              />
            </div>
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <label
                className="uk-form-label uk-margin-right"
                htmlFor="congregationId"
              >
                Congregação:
              </label>
              <div
                uk-form-custom="target: > * > span:first-child"
                className="uk-width-1-2@m"
              >
                <select
                  className="uk-form-small"
                  id="congregationId"
                  name="congregationId"
                  value={congSearch}
                  onChange={(e) => handleSearch(+(e.target.value))}
                >
                  <option value="" key={`congregation0`}>
                    Todas
                  </option>
                  {congregations.map((f) => {
                    return (
                      <option value={f.id} key={`congregation${f.id}`}>
                        {f.description}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{ textTransform: "none" }}
                  type="button"
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
            </div>
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchMembers}
            refresh={refresh}
          />
        </div>
        <MemberDeletedDetail
          memberId={memberSelected.current}
          open={seeDetail}
          afterClose={dialogClose}
        />
      </div>
    </div>
  );
}
