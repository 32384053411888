import { authenticationService } from '../../services/auth.service';
import DashboardAuxiliarSec from '../dashboard-auxiliar-sec/DashboardAuxiliarSec';
import DashboardMain from '../dashboard-main/DashboardMain';

export default function Dashboard() {

    return (
        <div>
            { 
                authenticationService.isAuxSec() ?
                    <DashboardAuxiliarSec/>
                :
                    <DashboardMain/>
            }
        </div>
    )
}