import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastOptions from "../../../utils/toastOptions";
import Param from "../../../models/Param";
import { utils } from "../../../utils/utils";
import TablePagination from "../../../components/TablePagination";
import { faPrint, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { displayDate } from "../../../utils/masks";

interface MemberListDTO {
  id: number;
  name: string;
  ecclesiasticalFunction: any;
  congregation: any;
  historic: any;
}

export default function MemberBaptismList() {
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>();
  const [totalMembers, setTotalMembers] = useState(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [termSearch, setTermSearch] = useState<string>("");
  const [congregations, setCongregations] = useState<Param[]>([]);
  const [congSearch, setCongSearch] = useState<number>(0);
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');

  const columns = [
    {
      name: "Nome",
      sortable: true,
      grow: 2,
      cell: (row: MemberListDTO) => <span>{row.name}</span>
    },
    {
      name: "Congregação",
      sortable: true,
      cell: (row: MemberListDTO) => <span>{row.congregation.description}</span>
    },
    {
      name: "Batismo",
      sortable: true,
      cell: (row: any) => <span>{displayDate(row.baptismDate)}</span>
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: MemberListDTO, index: number) => (
        <div>
          <Link
            to={`/membros/${row.id}`}
            target={"blank"}
            uk-tooltip="title: Visualizar"
            className='uk-margin-small-right' 
          >
            <FontAwesomeIcon icon={faSearch} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());
    init();
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500); // You can adjust the delay time according to your preferences

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    // Perform the search operation with delayedSearchTerm
    // This will be called only when the user stops typing for the specified delay time
    setRefresh(!refresh);
  }, [delayedSearchTerm]);
  
  const init = async () => {
    const dataCongregations = await api.get("congregations/dashboard");
    setCongregations(dataCongregations.data);
  };

  const fetchMembers = async (page: number, limit: number) => {
    try {
      setTotalMembers(0);

      let params = {
        page: page,
        limit: limit,
        idCongregation: congSearch,
      } as any;

      if (termSearch) {
        params = { ...params, name: termSearch?.toUpperCase() };
      }

      const response = await api.get(`members/next-baptism`, {
        params: params,
      });

      setTotalMembers(response.data.total);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handlePrint = async () => {
    setIsRunning(true);
    try {
      const { data } = await api.post("/members/next-baptism/report", {
        name: termSearch,
        idCongregation: congSearch,
      });
      const name = `lista-batismo-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setIsRunning(false);
    }
  };

  const handleSearch = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handleCongregation  = async (congregation: number) => {
    await setCongSearch(congregation);
    setRefresh(!refresh);
  };
  
  return (
    <div id="page-member-no-card-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Lista de Batismo
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to={"/secretaria"}
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-grid-small uk-margin-top uk-flex uk-flex-middle" uk-grid="true">
            <div className="uk-margin-right">Total: {totalMembers}</div>
            <button
              type="button"
              className="uk-button uk-button-primary uk-button-small uk-flex uk-flex-middle"
              disabled={isRunning || totalMembers == 0}
              onClick={() => handlePrint()}
            >
              <span>
                Imprimir Lista
              </span>
              {isRunning ? (
                <div
                  className="uk-margin-small-left"
                  uk-spinner="true; ratio:0.5"
                ></div>
              ) : (
                <FontAwesomeIcon className="uk-margin-small-left" icon={faPrint} />
              )}
            </button>
          </div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input"
                type="search"
                placeholder="Pesquise por nome..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearch(ev.target.value);
                }}
              />
            </div>
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <label
                className="uk-form-label uk-margin-right"
                htmlFor="congregationId"
              >
                Congregação:
              </label>
              <div
                uk-form-custom="target: > * > span:first-child"
                className="uk-width-1-2@m"
              >
                <select
                  className="uk-form"
                  id="congregationId"
                  name="congregationId"
                  value={congSearch}
                  onChange={(e) => handleCongregation(+e.target.value)}
                >
                  <option value="" key={`congregation0`}>
                    Todas
                  </option>
                  {congregations.map((f) => {
                    return (
                      <option value={f.id} key={`congregation${f.id}`}>
                        {f.description}
                      </option>
                    );
                  })}
                </select>
                <button
                  className="uk-button uk-button-default uk-form uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{ textTransform: "none" }}
                  type="button"
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
            </div>
          </div>
          <TablePagination
            columns={columns}
            fetchData={fetchMembers}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
