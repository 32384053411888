import * as dev from './environment.dev';
import * as prod from './environment.prod';

let environment = prod.environment;

if (process.env.NODE_ENV==="development") {
    environment = dev.environment;
}

export default environment = environment;
