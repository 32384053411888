import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import {
  calcYearsOld,
  CPFIsValid,
  cpfMask,
  dateMask,
  displayDate,
  displayDateTime,
  handleFocus,
  paymentType,
  phoneMask,
  showKnowAbout,
} from "../../../utils/masks";
import { Gender } from "../../../models/enums/Gender";
import { Link, useParams } from "react-router-dom";
import { getValidator } from "../../../utils/validatorsYup";

import { authenticationService } from "../../../services/auth.service";

import "../../shared/modal.css";
import "./event-person-edit.css";
import "../../shared/button.css";
import { StatusType } from "../../../models/enums/StatusType";
import IPersonEventDTO from "../../../models/IPersonEvent";
import Param from "../../../models/Param";
import { Church } from "../../../models/enums/Church";

type RouteParams = {
  id: string;
  idEvent: string;
}

export default function EventPersonEdit() {
  const params = useParams<RouteParams>();
  const [person, setPerson] = useState<IPersonEventDTO>();
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [canChange, setCanChange] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isIndividual, setIsIndividual] = useState<boolean>(true);
  const [congregations, setCongregations] = useState<Param[]>([]);
  const [church, setChurch] = useState<Church>();

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Nome obrigatório")
      .max(100, (val) => `Máximo ${val.max} caracteres`)
      .min(3, (val) => `Mínimo ${val.min} caracteres`),
    gender: yup.string().required("Gênero obrigatório"),
    document: yup
      .string()
      .required("CPF obrigatório")
      .test("register-validate", "CPF Inválido", (value) => CPFIsValid(value)),
    phone: yup.string(),
    email: yup.string().max(100, (val) => `Máximo ${val.max} caracteres`),
    birthDate: getValidator({ isDate: true }).required(
      "Nascimento obrigatório"
    ),
    pastorName: yup.string()
      .when("church", {
        is: Church.OTHER,
        then: yup.string().required('Pastor obrigatório').max(100, (val) => `Máximo ${val.max} caracteres`)
      }),
    churchName: yup.string()
      .when("church", {
        is: Church.OTHER,
        then: yup.string().required('Igreja obrigatória').max(100, (val) => `Máximo ${val.max} caracteres`)
      }),
    partnerName: yup.string()
      .when("isIndividual", {
        is: false,
        then: yup.string().required("Cônjuge Nome obrigatório"),
      })
      .max(100, (val) => `Máximo ${val.max} caracteres`),
    partnerDocument: yup.string()
      .when("isIndividual", {
        is: false,
        then: yup.string().required("Cônjuge CPF obrigatório")
    }),
    partnerBirthDate: getValidator({ isOptionalDate: true })
      .when("isIndividual", {
        is: false,
        then: getValidator({ isDate: true, message: "Cônjuge Nascimento obrigatório" })
    }),
    eventNote: yup.string()
      .max(250, (val) => `Máximo ${val.max} caracteres`),
    church: yup.string()
      .required('Igreja obrigatória'),
    congregation: yup.string()
      .when("church", {
        is: Church.ADBELEM,
        then: yup.string().required("Congregação obrigatório")
      })
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    
    init();
  }, [params.id]);

  const init = async () => {
    const dataCongregations = await api.get('congregations/dashboard');
    setCongregations(dataCongregations.data);

    const id = +(params.id ?? 0);

    const adding = id ? id <= 0 : true;

    if (!adding) {
      handleEdit(id);
    }

    setDisabled(
      (authenticationService.isAuxSec() && !adding) ||
        authenticationService.isReadOnly()
    );

    setCanChange(authenticationService.canChange());
  };

  const handleEdit = async (id: number) => {
    try {
      const { data } = await api.get(`/events/${params.idEvent}/people/${id}`);

      setPerson(data.item);
      setIsIndividual(data.item.isIndividual);
      setChurch(data.item.church);

    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.warning(m, toastOptions);
      });
    }
  };

  const onSubmit = async (data: IPersonEventDTO) => {
    try {
      data.id = person?.id ?? 0;
      data.isIndividual = isIndividual;
      data.idPersonPartner = person?.idPersonPartner;
      data.church = church ?? Church.ADBELEM;

      const { data: result } = await api.put(`/events/${params.idEvent}/people`, data);
      toast.success("Pessoa atualizada com sucesso!", toastOptions);

      result.item.item.id = 0;
      setPerson(result.item.item);
      result.item.item.id = params.id;
      setPerson(result.item.item);
      
      setRefresh(!refresh);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  return (params.id && !person) ? (
    <div className="uk-flex uk-flex-center">
      <div uk-spinner="true; ratio:2">Carregando...</div>
    </div>
  ) : (
    <div id="page-edit-person-event">      
      <form
        onSubmit={handleSubmit<IPersonEventDTO>(onSubmit)}
        onReset={reset}
      >
        <div className="uk-modal-body">
          <div className="uk-grid-small" uk-grid="true">
            <div className="uk-width-1-2@s">
              <label className="uk-form-label" htmlFor="name">Nome</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                className="uk-input uk-form-small"
                defaultValue={person?.name}
                style={{ textTransform: "uppercase" }}
                autoComplete={"off"}
                onFocus={handleFocus}
                ref={register}
              />
              <small className="uk-text-danger">{errors.name?.message}</small>
            </div>
            <div className="uk-width-1-4@s">
              <label className="uk-form-label" htmlFor="gender">Sexo</label>
              <div
                uk-form-custom="target: > * > span:first-child"
                className="uk-width-1"
              >
                <select
                  className="uk-form-small"
                  id="gender"
                  name="gender"
                  defaultValue={person?.gender}
                  ref={register}
                >
                  <option value="" key={`gender0`}>Selecione...</option>
                  <option value={Gender.FEMALE} key={`gender1`}>Feminino</option>
                  <option value={Gender.MALE} key={`gender2`}>Masculino</option>
                </select>
                <button
                  className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                  style={{ textTransform: "none" }}
                  type="button"
                  tabIndex={-1}
                >
                  <span></span>
                  <span uk-icon="icon: chevron-down"></span>
                </button>
              </div>
              <small className="uk-text-danger">
                {errors.gender?.message}
              </small>
            </div>
            <div className="uk-width-1-4@s">
              <label className="uk-form-label" htmlFor="document">CPF</label>
              <input
                type="text"
                id="document"
                name="document"
                placeholder="999.999.999-99"
                className="uk-input uk-form-small"
                defaultValue={cpfMask(person?.document ?? '')}
                autoComplete={"off"}
                ref={register}
                onFocus={handleFocus}
                onChange={(e) => {
                  e.target.value = cpfMask(e.target.value);
                }}
              />
              <small className="uk-text-danger">
                {errors.document?.message}
              </small>
            </div>
            <div className="uk-width-1-2@s">
              <label className="uk-form-label" htmlFor="email">E-mail</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="email@email.com"
                className="uk-input uk-form-small"
                defaultValue={person?.email}
                autoComplete={"off"}
                ref={register}
              />
              <small className="uk-text-danger">
                {errors.email?.message}
              </small>
            </div>
            <div className="uk-width-1-4@s">
              <label className="uk-form-label" htmlFor="birthDate">
                Nascimento {calcYearsOld(person?.birthDate.toString())}
              </label>
              <input
                type="type"
                id="birthDate"
                name="birthDate"
                placeholder="99/99/9999"
                className="uk-input uk-form-small"
                defaultValue={displayDate(person?.birthDate?.toString())}
                autoComplete={"off"}
                onFocus={handleFocus}
                ref={register}
                onChange={(e) => {
                  e.target.value = dateMask(e.target.value);
                }}
              />
              <small className="uk-text-danger">
                {errors.birthDate?.message}
              </small>
            </div>
            <div className="uk-width-1-4@m">
              <label className="uk-form-label" htmlFor="phone">Telefone</label>
              <input type="text" 
                id="phone" 
                name="phone" 
                placeholder="(99) 99999-9999" 
                className="uk-input uk-form-small"
                defaultValue={phoneMask(person?.phone ?? '')}
                ref={register}
                autoComplete={"off"}
                onChange={(e) => { e.target.value = phoneMask(e.target.value) }}
              />
              <small className="uk-text-danger">{errors.phone?.message}</small>
            </div>
            <div className="uk-width-1-3@m uk-flex uk-flex-middle">              
              <label className="uk-form-label uk-margin-small-right">
                <input className="uk-radio uk-margin-small-right" 
                  type="radio" 
                  name="church" 
                  value={Church.ADBELEM}
                  defaultValue={person?.church}
                  checked={church == Church.ADBELEM}
                  ref={register}
                  onChange={(e) => {
                    setChurch(Church.ADBELEM)
                  }}
                />
                AD Belém Uberaba
              </label>
              
              <label className="uk-form-label uk-margin-small-right">
                <input className="uk-radio uk-margin-small-right"
                  type="radio"
                  name="church" 
                  value={Church.OTHER}
                  defaultValue={person?.church}
                  checked={church == Church.OTHER}
                  ref={register}
                  onChange={(e) => {
                    setChurch(Church.OTHER);
                  }}
                />
                Outro Ministério
              </label>
            </div>
            {
              church == Church.ADBELEM && <>              
                <div className="uk-width-1-3@m">
                  <label className="uk-form-label" htmlFor="congregation">Congregação:</label>
                  <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                    <select className="uk-form-small" id="congregation" 
                      name="congregation"
                      defaultValue={person?.congregation}
                      ref={register}
                    >
                      {
                        congregations.map(f => {
                          return <option value={f.id} key={`congregation${f.id}`}>{f.description}</option>
                        })
                      }
                    </select>
                    <button 
                      className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                      style={{textTransform: 'none'}} 
                      type="button" 
                      tabIndex={-1}
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                  <small className="uk-text-danger">{errors.phone?.message}</small>
                </div>
              </>
            }
            {
              church == Church.OTHER && <>              
                <div className="uk-width-1-3@s">
                  <label className="uk-form-label" htmlFor="churchName">Igreja</label>
                  <input
                    type="text"
                    id="churchName"
                    name="churchName"
                    className="uk-input uk-form-small"
                    defaultValue={person?.churchName}
                    style={{textTransform: 'uppercase'}}
                    autoComplete={"off"}
                    ref={register}
                  />
                  <small className="uk-text-danger">{errors.churchName?.message}</small>
                </div>
                <div className="uk-width-1-3@s">
                  <label className="uk-form-label" htmlFor="pastorName">Pastor</label>
                  <input
                    type="text"
                    id="pastorName"
                    name="pastorName"
                    className="uk-input uk-form-small"
                    defaultValue={person?.pastorName}
                    style={{textTransform: 'uppercase'}}
                    autoComplete={"off"}
                    ref={register}
                  />
                  <small className="uk-text-danger">
                    {errors.pastorName?.message}
                  </small>
                </div>
              </>
            }            
            <div className="uk-width-1-1@m">
              <label className="uk-form-label uk-margin-small-right" htmlFor="type">Inscrição Individual?</label>
              <input className="uk-checkbox"
                name="isIndividual"
                defaultValue={`${person?.isIndividual}`}
                checked={isIndividual}
                ref={register}
                onChange={(e) => {
                    setIsIndividual(e.target.checked);
                }}
                type="checkbox"/>
            </div>
            {
              !isIndividual && (
                <div className="uk-width-1-1@s">
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-1-2@s">
                      <label className="uk-form-label" htmlFor="partnerName">Cônjuge</label>
                      <input
                        type="text"
                        id="partnerName"
                        name="partnerName"
                        placeholder="Nome do Cônjuge"
                        className="uk-input uk-form-small"
                        defaultValue={person?.partnerName}
                        style={{ textTransform: "uppercase" }}
                        autoComplete={"off"}
                        ref={register}
                      />
                      <small className="uk-text-danger">
                        {errors.partnerName?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-4@s">
                      <label className="uk-form-label" htmlFor="partnerDocument">Cônjuge CPF</label>
                      <input
                        type="text"
                        id="partnerDocument"
                        name="partnerDocument"
                        placeholder="999.999.999-99"
                        className="uk-input uk-form-small"
                        defaultValue={cpfMask(person?.partnerDocument ?? '')}
                        autoComplete={"off"}
                        ref={register}
                        onFocus={handleFocus}
                        onChange={(e) => {
                          e.target.value = cpfMask(e.target.value);
                        }}
                      />
                      <small className="uk-text-danger">
                        {errors.partnerDocument?.message}
                      </small>
                    </div>
                    <div className="uk-width-1-4@s">
                      <label className="uk-form-label" htmlFor="partnerBirthDate">
                      Cônjuge Nascimento {calcYearsOld(person?.partnerBirthDate?.toString())}
                      </label>
                      <input
                        type="type"
                        id="partnerBirthDate"
                        name="partnerBirthDate"
                        placeholder="99/99/9999"
                        className="uk-input uk-form-small"
                        defaultValue={displayDate(person?.partnerBirthDate?.toString())}
                        autoComplete={"off"}
                        onFocus={handleFocus}
                        ref={register}
                        onChange={(e) => {
                          e.target.value = dateMask(e.target.value);
                        }}
                      />
                      <small className="uk-text-danger">
                        {errors.partnerBirthDate?.message}
                      </small>
                    </div>  
                  </div>
                </div>
              )
            }
          </div>
          <div className="uk-grid-small" uk-grid="true">            
            <div className="uk-width-1-2@s">
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1@s">
                  <label className="uk-form-label" htmlFor="note">
                    Observação da Inscrição
                  </label>
                  <textarea
                    id="note"
                    name="note"
                    className="uk-form-small uk-textarea"
                    defaultValue={person?.note}
                    rows={4}
                    style={{resize: 'none', textTransform: 'uppercase'}}
                    disabled={true}
                    ref={register}
                  />
                  <small className="uk-text-danger">
                    {errors.note?.message}
                  </small>
                </div>                
              </div>
              <div className="uk-width-1@m">
                <label className="uk-form-label" htmlFor="howToKnow">Como soube?</label>
                <input
                  type="text"
                  id="howToKnow"
                  name="howToKnow"
                  className="uk-input uk-form-small"
                  defaultValue={showKnowAbout(person?.howToKnow ?? '')}
                  disabled={true}
                  autoComplete={"off"}
                  ref={register}
                />
                <small className="uk-text-danger">
                  {errors.howToKnow?.message}
                </small>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <label className="uk-form-label" htmlFor="eventNote">Observações</label>
              <textarea
                id="eventNote" 
                name="eventNote"
                className="uk-textarea uk-form-small" 
                rows={4} 
                style={{resize: 'none', textTransform: 'uppercase'}}
                defaultValue={person?.eventNote}
                autoComplete={"off"}
                ref={register}
              />
              <small className="uk-text-danger">{errors.eventNote?.message}</small>
            </div>
          </div>
          {
            person?.paidDate && (
              <div className="uk-grid-small" uk-grid="true">
                <div className="uk-width-1-2@s">
                  <span className="uk-text-italic uk-text-small uk-text-bold uk-text-success">
                    Inscrição Paga em {`${displayDate(person?.paidDate.toString())}`} - via {paymentType(person.paymentType ?? '')}
                  </span>
                </div>
              </div>
            )          
          }
          {person?.id && canChange && (
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-2@s">
                <span className="uk-text-italic uk-text-small">
                  Inscrição feita em
                </span>
                <span className="uk-text-muted uk-text-small uk-margin-small-left">
                  {displayDateTime(person?.createdDate)}
                </span>
              </div>
              {person.modifiedBy && (
                <div className="uk-width-1-2@s">
                  <span className="uk-text-italic uk-text-small">
                    Alterado por:
                  </span>
                  <span className="uk-text-muted uk-text-small uk-margin-small-left">
                    {`${person?.modifiedBy} - ${displayDateTime(person?.modifiedDate)}`}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="uk-modal-footer uk-margin-bottom">
          <div className="uk-grid-small uk-flex uk-flex-center" uk-grid="true">
            <div className="uk-text-center">
              <Link
                to={`/eventos/${params.idEvent}/pessoas`}
                className="uk-button uk-button-default uk-button-small uk-margin-left uk-width-1-1"
              >
                Voltar
              </Link>
            </div>
            {person?.status != StatusType.INACTIVE && (
              <>
                <div className="uk-text-center">
                  <button
                    id="btn-save"
                    disabled={
                      formState.isSubmitting ||
                      isDisabled
                    }
                    className="uk-button uk-button-primary uk-button-small uk-margin-left uk-width-1-1"
                    type="submit"
                  >
                    {formState.isSubmitting && <div uk-spinner="true"></div>}
                    Salvar
                  </button>
                </div>                
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
