import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect, ChangeEvent } from "react";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { authenticationService } from "../../../services/auth.service";
import { dateMask, displayDate, handleFocus, timeMask } from "../../../utils/masks";
import ISchedule from "../../../models/ISchedule";
import { getValidator } from "../../../utils/validatorsYup";
import Modal from "../../../components/modal";

interface Params {
  id: number;
  docId?: number;
  add: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

export default function ScheduleAddEdit(props: Params) {
  const { id, add, afterHandleSubmit, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<ISchedule>();
  const [isReadOnly, setReadOnly] = useState<boolean>(true);

  const schema = yup.object().shape({
    date: getValidator({isDate: true}).required('Data obrigatória'),
    hour: getValidator({isTime: true}).required('Hora obrigatória'),
    description: yup.string().max(100, (val) => `Máximo ${val.max} caracteres`).required("Descrição obrigatória"),
    note: yup.string().max(80, (val) => `Máximo ${val.max} caracteres`),
  });

  const {
    register,
    handleSubmit,
    reset,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setReadOnly(authenticationService.isReadOnly());
    setSchedule(undefined);
    setModelOpen(add);
    if (id) {
      handleEdit(id);
    }
  }, [id, add]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setModelOpen(false);
    afterCloseModal();
  };

  const onSubmit = async (data: ISchedule) => {
    try {
      if (!schedule?.id) {
        await api.post("schedules", data);
        toast.success("Evento cadastrado com sucesso!", toastOptions);
      } else {
        data.id = schedule.id;
        await api.put("schedules", data);
        toast.success("Evento atualizado com sucesso!", toastOptions);
      }
      handleCloseModal();
      afterHandleSubmit();
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const handleEdit = async (id: number) => {
    try {
      const { data } = await api.get(`schedules/${id}`);
      setSchedule(data.item);
      setModelOpen(true);
    } catch (e: any) {
      if (e.messages) {
        e.messages.forEach((m: string) => {
          toast.warning(m, toastOptions);
        });
      }
    }
  };

  const handleDate = (e: ChangeEvent<HTMLInputElement>)=> {
    e.target.value = dateMask(e.target.value);

    //TODO: Find same date event
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">
            {id ? "Editar Evento" : "Adicionar Evento"}
          </h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={handleCloseModal}
        />
        <form onSubmit={handleSubmit<ISchedule>(onSubmit)} onReset={reset}>
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1-3@s">
                <label className="uk-form-label" htmlFor="date">Data</label>
                <input type="text" 
                  id="date" 
                  name="date" 
                  placeholder="99/99/9999" 
                  className="uk-input uk-form-small"
                  defaultValue={displayDate(schedule?.date?.toString())}
                  autoComplete={"off"}
                  onFocus={handleFocus}
                  ref={register}
                  onChange={(e) => { handleDate(e) }}
                />
                <small className="uk-text-danger">{errors.date?.message}</small>
              </div>
              <div className="uk-width-1-3@s">
                <label className="uk-form-label" htmlFor="hour">Hora</label>
                <input type="time" 
                  id="hour" 
                  name="hour"
                  className="uk-input uk-form-small"
                  defaultValue={schedule?.hour}
                  autoComplete={"off"}
                  onFocus={handleFocus}
                  ref={register}
                  onChange={(e) => { e.target.value = timeMask(e.target.value) }}
                />
                <small className="uk-text-danger">{errors.hour?.message}</small>
              </div>
              <div className="uk-width-1@s">
                <label className="uk-form-label" htmlFor="description">Descrição</label>
                <input 
                  id="description" 
                  name="description"
                  className="uk-textarea uk-form-small"
                  defaultValue={schedule?.description}
                  autoComplete={"off"}
                  ref={register} />
                <small className="uk-text-danger">{errors.description?.message}</small>
              </div>             
              <div className="uk-width-1@s">
                <label className="uk-form-label" htmlFor="note">
                  Observação
                </label>
                <input
                  id="note"
                  name="note"
                  className="uk-textarea uk-form-small"
                  defaultValue={schedule?.note}
                  autoComplete={"off"}
                  ref={register}
                />
                <small className="uk-text-danger">{errors.note?.message}</small>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={handleCloseModal}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={formState.isSubmitting || isReadOnly}
                className="uk-button uk-button-primary uk-margin-left"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
