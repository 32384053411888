import { Link } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';
import MemberCredentialCard from "../member/member-credential-card/MemberCredentialCard";
import MemberDeleted from "../member/member-deleted/MemberDeleted";
import ScheduleList from "../schedule/schedule-list/ScheduleList";
import MeetingCard from "../meeting/meeting-card/MeetingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

export default function DashboardSecretary() {
    const print = async () => {
        const link = document.createElement('a');
        link.href = 'docs/ficha-cadastro.pdf';
        link.download = 'Ficha de Cadastro.pdf';
        link.click();
    }

    const handleDownloadFicha = () => {
        const link = document.createElement('a');
        link.href = 'docs/ficha-pedido-recepção.pdf';
        link.download = 'Pedido Recepção Eclesiástica.pdf';
        link.click();
    };

    const handleDownloadEnclosing = () => {
        const link = document.createElement('a');
        link.href = 'docs/anexo-obreiro.pdf';
        link.download = 'Anexo Obreiros.pdf';
        link.click();  
    };

    return (
        <div id="page-dashboad-secretary" className="uk-section uk-section-muted uk-padding-small">
            <div className="uk-container">
                <div className="uk-grid-small uk-flex-center uk-text-center uk-margin-bottom" uk-grid="true">
                    <Link className="uk-button uk-button-success uk-button-small uk-margin-left" 
                        to={`/secretaria/cartas`}>
                            Cartas Emitidas
                    </Link>
                    <Link className="uk-button uk-button-danger uk-button-small uk-margin-left" 
                        to={`/secretaria/anuidades`}>
                            Anuidades
                    </Link>
                    <div className="uk-button-group uk-button-small">
                        <button 
                            id="btn-print"
                            className="uk-button uk-button-default uk-button-small" 
                            type="button"
                        >
                            <FontAwesomeIcon className="uk-margin-small-right" icon={faPrint} />
                            Impressões
                        </button>
                        <div className="uk-inline">
                            <button
                                className="uk-button uk-button-default uk-button-small" 
                                type="button"
                            >
                                <span uk-icon="icon:triangle-down"></span>
                            </button>
                            <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;">
                                <ul className="uk-nav uk-dropdown-nav">
                                    <li>
                                        <a href="#" onClick={(ev) => { ev.preventDefault(); print(); }}
                                        className='print'>
                                            Ficha de Cadastro
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(ev) => { ev.preventDefault(); handleDownloadFicha(); }}
                                            className='print'>
                                            Ficha Recepção Eclesiástica
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(ev) => { ev.preventDefault(); handleDownloadEnclosing(); }}
                                            className='print'>
                                            Anexo Obreiros
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-grid-small uk-margin-bottom" uk-grid="true">
                    <div className="uk-width-1-3@l">
                        <MeetingCard />                        
                    </div>
                    <div className="uk-width-1-3@l">
                        <MemberCredentialCard />                        
                    </div>
                    <div className="uk-width-1-3@l">
                        <MemberDeleted />
                    </div>
                    <div className="uk-width-1@l">
                        <ScheduleList />
                    </div>
                </div>
            </div>
        </div>
    )
}