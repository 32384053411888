import { useState, useEffect } from 'react';
import api from '../../../services/api';

import 'react-toastify/dist/ReactToastify.css';

import { displayDateTime } from '../../../utils/masks';
import TablePagination from '../../../components/TablePagination';
import Modal from '../../../components/modal';

interface Params {
    memberId: number;
    open: boolean;    
    afterClose: any;
}

interface HistDTO {
    id: number;
    reason: string;
    note: string;
    modifiedDate: Date;
    createdBy: string;
}

export default function MemberDeletedDetail(props: Params) {
    const { memberId, open, afterClose } = props;

    const [modalIsOpen, setModelOpen] = useState(false);

    const columns = [
        {
            name: 'Motivo',
            sortable: true,
            cell: (row: HistDTO) => (
              <span>{row.reason}</span>
            )
        },
        {
            name: 'Observação',
            sortable: true,
            grow: 3,
            cell: (row: HistDTO) => (
                <span>          
                    {row.note}          
                </span>
            )
        },
        {
            name: 'Removido por',
            selector: 'createdBy',
            sortable: true
        },
        {
            name: 'Removido em',
            sortable: true,
            cell: (row: HistDTO) => (
                <span>          
                    {displayDateTime(row.modifiedDate)} 
                </span>
            )
        }
    ];

    useEffect(() => {
        setModelOpen(open);
    }, [memberId, open]);

    const closeModal = () =>{
        handleCloseModal();
    }

    const handleCloseModal = () => {
        setModelOpen(false);
        afterClose();
    }

    const fetchHist = async (page: number, limit: number) => {
        try {
            const response = await api.get(`members/${memberId}/historic/removed`, { 
                params: { 
                    page: page, 
                    limit: limit
                } 
            });

            return response;
        } catch {
            return {data: [], total: 0}
        }
    };

    return (
        <>        
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-small"
                shouldCloseOnOverlayClick={false}            
            > 
                <div className="uk-modal-header">
                    <h2 className="uk-modal-title">Histórico</h2>
                    <TablePagination
                        columns={columns}
                        fetchData={fetchHist}
                    />
                </div>
                <button 
                    className="uk-modal-close-default" 
                    uk-close="true" 
                    type="button" 
                    onClick={handleCloseModal}
                />                
            </Modal>
        </>
    )
}