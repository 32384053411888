import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect, ChangeEvent, useRef } from 'react';
import api from '../../../services/api';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MaritalStatus } from '../../../models/enums/MaritalStatus';

import Member from '../../../models/Member';
import toastOptions from '../../../utils/toastOptions';
import { calcYearsOld, CEPMask, CPFIsValid, cpfMask, dateMask, displayDate, displayDateTime, filesAllowed, handleFocus, numberMask, phoneMask } from '../../../utils/masks';
import { Gender } from '../../../models/enums/Gender';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Param from '../../../models/Param';
import { getValidator } from '../../../utils/validatorsYup';

import states from '../../../assets/br.citys.json';
import { authenticationService } from '../../../services/auth.service';
import MemberDocList from '../member-docs-list/MemberDocList';
import { utils } from '../../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faCamera, faHourglassHalf, faPrint, faXmark } from '@fortawesome/free-solid-svg-icons';
import MemberCredencialRequest from '../member-credential-request/MemberCredencialRequest';

import '../../shared/modal.css';
import './member-add-edit.css';
import '../../shared/button.css';
import DialogConfirm from '../../../components/DialogConfirm';
import MemberHistoricList from '../member-historic-list/MemberHistoricList';
import { TypeHistory } from '../../../models/enums/TypeHistory';
import MemberMeetingsList from '../member-meetings-list/MemberMeetingsList';
import { StatusType } from '../../../models/enums/StatusType';
import MemberMinisterDocList from '../member-minister-docs-list/MemberMinisterDocList';
import MemberNoteHandle from '../member-note-handle/MemberNoteHandle';
import MemberLetterRecommendation from '../member-letter-recommendation/MemberLetterRecommendation';

type MemberParams = {
    id: string;
}

export default function MemberAddEdit() {
    const params = useParams<MemberParams>();
    const navigate = useNavigate();
    const [member, setMember] = useState<Member>();
    const [functions, setFunctions] = useState<Param[]>([]);
    const [congregations, setCongregations] = useState<Param[]>([]);
    const [cities, setCities] = useState<string[]>([]);
    const [citiesBorn, setCitiesBorn] = useState<string[]>([]);
    const [image, setImage] = useState<File | string>();
    const [isReadOnly, setReadOnly] = useState<boolean>(true);
    const [isAuxSec, setAuxSec] = useState<boolean>(true);
    const [isAdding, setAdding] = useState<boolean>(true);
    const [isDisabled, setDisabled] = useState<boolean>(true);
    const [hasCard, setHasCard] = useState<boolean>(false);
    const [isRunning, setRunning] = useState<boolean>(false);
    const [isRunningPrint, setRunningPrint] = useState<boolean>(false);
    const [isPrintLetter, setPrintLetter] = useState<boolean>(false);
    const [isRunningBirthdayCard, setRunningBirthdayCard] = useState<boolean>(false);
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [requestCredential, setRequestCredential] = useState<boolean>(false);
    const [hasCredentialRequested, setCredentialRequested] = useState<boolean | undefined>();
    const [canChange, setCanChange] = useState<boolean>(false);
    const [isDialogDelete, setIsDialogDelete] = useState<boolean>(false);
    const [isCancellingCred, setCancellingCred] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const dropdownOptsRef = useRef(null);

    const schema = yup.object().shape({
        name: yup.string().required('Nome obrigatório')
            .max(100, (val) => `Máximo ${val.max} caracteres`)
            .min(3, (val) => `Mínimo ${val.min} caracteres`),
        gender: yup.string().required('Gênero obrigatório'),
        document: yup.string().required('CPF obrigatório')
            .test('register-validate', "CPF Inválido",(value) => CPFIsValid(value)),
        register: yup.string().required('RG obrigatório')
            .max(20, (val) => `Máximo ${val.max} caracteres`),
        motherName: yup.string().required('Nome da mãe obrigatório')
            .max(100, (val) => `Máximo ${val.max} caracteres`)
            .min(3, (val) => `Mínimo ${val.min} caracteres`),
        fatherName: yup.string()
            .max(100, (val) => `Máximo ${val.max} caracteres`),
        birthDate: getValidator({isDate: true}).required('Nascimento obrigatório'),
        since: getValidator({isDate: true}).required('Membro desde obrigatório'),
        baptismDate: getValidator({isDate: true}).required('Data batismo obrigatório'),
        maritalStatus: yup.string().required('Estado civil obrigatório'),
        lifePartnerName: yup.string()
        .when('maritalStatus', {
            is: MaritalStatus.MARRIED,
                then: yup.string().required('Nome do cônjuge obrigatório')
            })
            .max(100, (val) => `Máximo ${val.max} caracteres`),
        cityBirth: yup.string().required('Cidade nascimento obrigatória'),
        stateBirth: yup.string().required('Estado nascimento obrigatório'),
        ecclesiasticalFunctionId: getValidator({isNumeric: true}).required('Função obrigatória'),
        congregationId: getValidator({isNumeric: true}).required('Congregação obrigatória'),
        cardNumber: getValidator({isNumeric: true}),        
        address: yup.string()
            .max(100, (val) => `Máximo ${val.max} caracteres`),
        phone: yup.string(),
        state: yup.string(),
        city: yup.string(),
        note: yup.string()
        .max(250, (val) => `Máximo ${val.max} caracteres`),
        addressLine2: yup.string()
            .max(100, (val) => `Máximo ${val.max} caracteres`),
        neighborhood: yup.string()
        .max(50, (val) => `Máximo ${val.max} caracteres`),
        numberAddress: getValidator({isNumeric: true}),
        zipCode: yup.string(),
        hasCard: getValidator({isNumeric: true}),
        email: yup.string()
            .max(100, (val) => `Máximo ${val.max} caracteres`),
        baptismSpiritDate: getValidator({isOptionalDate: true})
            .when('ecclesiasticalFunctionId', (functionId: number, schema: any) => {
                return [2, 3, 4, 5, 6, 8].indexOf(functionId) >= 0 ?
                getValidator({isDate: true, message: `Batismo no Espírito Santo é obrigatório quando é Obreiro`}) : getValidator({isOptionalDate: true})
            }),
        hasAcceptedCongrats: getValidator({isNumeric: true})
    });
    
    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(schema),
    });
    
    useEffect(() => {        
        const init = async()=> {
            const dataFunctions = await api.get(`params/ecclesiasticals`);
            setFunctions(dataFunctions.data);
            const dataCongregations = await api.get('congregations/dashboard');
            setCongregations(dataCongregations.data);

            const id = +(params.id ?? 0);

            const adding = id ? id <= 0: true;
            setAdding(adding);

            if (!adding) {
                handleEdit(id);                
            }

            setReadOnly(authenticationService.isReadOnly());
            setAuxSec(authenticationService.isAuxSec());

            setDisabled((authenticationService.isAuxSec() && !adding) || authenticationService.isReadOnly());

            setCanChange(authenticationService.canChange());
        }
    
        init();
    }, [params.id]);

    const handleEdit = async (id: number) => {
        try {
            const { data } = await api.get(`members/${id}`);
        
            setMember(data.item);    
            setValue('gender', data.item.gender);
            setValue('maritalStatus', data.item.maritalStatus);
            setValue('stateBirth', data.item.stateBirth);
            selectCitiesBorn(data.item.stateBirth);
            setValue('cityBirth', data.item.cityBirth);
            setValue('state', data.item.state);
            selectCities(data.item.state);
            setValue('city', data.item.city);
            setValue('ecclesiasticalFunctionId', data.item.ecclesiasticalFunctionId);
            setValue('congregationId', data.item.congregationId);
            setHasCard(data.item.hasCard);
            setImage(data.item.photo);

            const { data: dataCredential } = await api.get(`/members/${id}/credential/request-open`);

            setCredentialRequested(dataCredential.item);

        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.warning(m, toastOptions);
            });
        }
    }
    
    const onSubmit = async (data: Member) => {
        try {
            data.hasCard = hasCard;
            data.hasAcceptedCongrats = (member?.hasAcceptedCongrats ?? true);
            const formData = new FormData();

            data.id = member?.id;
            formData.append('data', JSON.stringify(data));

            if (image && (member?.photo != image)) {
                formData.append('image', image);
            }

            if (!member?.id) {
                const result = await api.post('members', formData);
                toast.success('Membro cadastrado com sucesso!', toastOptions);

                navigate(`/membros/${result.data.item.id}`);
            } else {
                const { data: result } = await api.put('members', formData);
                toast.success('Membro atualizado com sucesso!', toastOptions);

                const m = Object.assign(member, {});
                result.item.item.id = 0;
                setMember(result.item.item);
                result.item.item.id = m.id;
                setMember(result.item.item);
            }

            setRefresh(!refresh);
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        }
    }        

    const selectCitiesBorn = (state: string) => {
        const citiesFromState = states.filter(s => s.initial === state).shift()?.cities;
        if (citiesFromState) {
            setCitiesBorn(citiesFromState);
        }
    }

    const selectCities = (state: string) => {
        const citiesFromState = states.filter(s => s.initial === state).shift()?.cities;
        if (citiesFromState) {
            setCities(citiesFromState);
        }
    }

    const handleSelectImage = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;

        const img = event.target.files[0];

        if (!filesAllowed(img)) {
            toast.warn('Permitido apenas imagens', toastOptions);
            return;
        }

        setImage(img);
    }

    const handleCardNumber = (newCardNumber: string) => {
        const card = +(newCardNumber);

        setHasCard(false);
        
        if (card > 0) {
            setHasCard(true);
        }
    }

    const handlePrint = async() => {
        setRunning(true);
        setRunningPrint(true);
        try {
            const { data } = await api.get(`members/${member?.id}/report`);
            reportDone();
            
            const name = `${member?.name.toLocaleLowerCase().split(' ')[0]}-ficha-${new Date().getTime()}.pdf`;
            utils.printPDF(name, data.item);
        } catch (e: any) {
            reportDone();
            e.messages?.forEach((m: string) => {
                toast.warning(m, toastOptions);
            });
        }
    }

    const handlePrintRecommendation = async() => {
        setPrintLetter(true);
        (dropdownOptsRef.current as any).classList.remove('uk-open');
    }

    const handleBirthCard = async() => {
        if (!member?.hasAcceptedCongrats) {
            toast.info('Membro optou por não receber cartões de aniversário');
            return;
        }

        setRunning(true);
        setRunningBirthdayCard(true);
        try {
            const { data } = await api.get(`members/${member?.id}/birthday-card`);

            reportDone();
            
            if (data.success) {
                const name = `${member?.name.toLocaleLowerCase().split(' ')[0]}-aniversário-${new Date().getTime()}.png`;
                utils.printPNG(name, data.item);
            } else {
                data.messages?.forEach((m: string) => {
                    toast.warning(m, toastOptions);
                });   
            }
                        
        } catch (e: any) {
            reportDone();
            e.messages?.forEach((m: string) => {
                toast.warning(m, toastOptions);
            });
        }
    }

    const reportDone = () => {
        setRunning(false);
        setRunningPrint(false);
        setPrintLetter(false);
        setRunningBirthdayCard(false);
    }   
    
    const afterHandleSubmitCredential = () => {
        setRequestCredential(false);
        setCredentialRequested(true);
        setRefresh(!refresh);
    }
    
    const afterCloseCredentialModal = (reason: TypeHistory) => {
        if (reason == TypeHistory.CREDENTIAL_CHANGE_FUNCTION || !member?.cardNumber) {
            window.location.reload();
        }
        setRequestCredential(false);
    }
    
    const afterHandleSubmitLetter = () => {
        setPrintLetter(false);
    }

    const afterCloseLetterlModal = () => {
        setPrintLetter(false);
    }

    const isButtonCredentialDisable = () => {
        return hasCredentialRequested || formState.isSubmitting || isReadOnly || member?.status == StatusType.INACTIVE;
    }    
    
    const isButtonPrintDisable = () => {
        return isRunning || isAdding || isDisabled || formState.isSubmitting;
    }

    const dialogClose = () => {
        setIsDialogDelete(false);
    }

    const dialogConfirm = async (confirmation: any) => {     
        setIsDialogDelete(false);
        setCancellingCred(true);
        try {
            const { data } = await api.delete(`/members/${member?.id}/credential/abort`);
            setCredentialRequested(false);
            toast.success('Solicitação de Carteirinha cancelada com sucesso!', toastOptions);
            setRefresh(!refresh);
            window.location.reload();
        } catch (e: any) {
            e.messages?.forEach((m: string) => {
                toast.error(m, toastOptions);
            });
        } finally {
            setCancellingCred(false);
        }
    }

    const handleCEP = (value: string) => {
        const cep = value;

        if (cep.length == 9) {            
            api.get(`https://viacep.com.br/ws/${cep}/json/`).then((resp: any) => {
                const { data } = resp;
                if (!data.erro) {
                    setValue('neighborhood', data.bairro);
                    setValue('address', data.logradouro);
                    setValue('state', data.uf);
                    selectCities(data.uf);
                    setValue('city', data.localidade);
                }
                
                if (data.logradouro) {                        
                    document.getElementById('numberAddress')?.focus();
                } else {
                    document.getElementById('address')?.focus();
                }
            });
        }

        setValue('zipCode', cep)

        return CEPMask(value);
    }

    return (
        ((params.id && !member) || functions.length === 0 || congregations.length === 0) ? (
            <div className="uk-flex uk-flex-center">
                <div uk-spinner="true; ratio:2">Carregando...</div>
            </div>
        ) :

        <div id="page-add-edit-member">
            {
                (!isAuxSec && !isAdding) && (
                    <ul uk-tab="true">
                        <li className={tabIndex == 0 ? 'uk-active' : ''} onClick={()=> setTabIndex(0)}>
                            <a href="#" onClick={(ev) => { ev.preventDefault(); }}>Dados</a>
                        </li>
                        <li className={tabIndex == 1 ? 'uk-active' : ''} onClick={()=> setTabIndex(1)}>
                            <a href="#" onClick={(ev) => { ev.preventDefault(); }}>Documentos</a>
                        </li>
                        <li className={tabIndex == 2 ? 'uk-active' : ''} onClick={()=> setTabIndex(2)}>
                            <a href="#" onClick={(ev) => { ev.preventDefault(); }}>Histórico</a>
                        </li>
                        <li className={tabIndex == 3 ? 'uk-active' : ''} onClick={()=> setTabIndex(3)}>
                            <a href="#" onClick={(ev) => { ev.preventDefault(); }}>Reuniões</a>
                        </li>
                        {
                            [5, 6].includes(member?.ecclesiasticalFunctionId || 0) && (
                                <li className={tabIndex == 4 ? 'uk-active' : ''} onClick={()=> setTabIndex(4)}>
                                    <a href="#" onClick={(ev) => { ev.preventDefault(); }}>Anuidades</a>
                                </li>
                            )                                        
                        }
                    </ul>
                )
            }

            <form onSubmit={handleSubmit<Member>(onSubmit)} onReset={reset} hidden={tabIndex != 0}>
                <div className="uk-modal-body">
                    <div className="uk-grid-small" uk-grid="true">
                        <div className="uk-width-1-1@s">
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-4@s">
                                    <label className="uk-form-label" htmlFor="document">Nº Carteirinha</label>
                                    <input type="text" 
                                        id="cardNumber" 
                                        name="cardNumber" 
                                        placeholder="999999" 
                                        className="uk-input uk-form-small"
                                        defaultValue={member?.cardNumber}
                                        autoComplete={"off"}
                                        ref={register}
                                        onFocus={handleFocus}
                                        onChange={(e) => { 
                                            handleCardNumber(e.target.value);  
                                            e.target.value = numberMask(e.target.value) 
                                        }}
                                    />
                                    <small className="uk-text-danger">{errors.cardNumber?.message}</small>
                                </div>
                                <div className="uk-width-1-4@s">
                                    <label className="uk-form-label" htmlFor="ecclesiasticalFunctionId">Função Eclesiástica</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="ecclesiasticalFunctionId" 
                                            name="ecclesiasticalFunctionId"
                                            defaultValue={member?.ecclesiasticalFunctionId}
                                            disabled={isDisabled}
                                            ref={register}
                                        >
                                            <option value="">Selecione...</option>
                                            {
                                                functions.map(f => {
                                                    return <option value={f.id} key={`ecclessiastical${f.id}`}>{f.description}</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.ecclesiasticalFunctionId?.message}</small>
                                </div>
                                <div className="uk-width-1-4@s">
                                    <label className="uk-form-label" htmlFor="congregationId">Congregação</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="congregationId" 
                                            name="congregationId"
                                            defaultValue={member?.congregationId}
                                            disabled={isDisabled}
                                            ref={register}
                                        >
                                            <option value="" key={`congregation0`}>Selecione...</option>
                                            {
                                                congregations.map(f => {
                                                    return <option value={f.id} key={`congregation${f.id}`}>{f.description}</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.congregationId?.message}</small>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-3-4@s">
                            <div className="uk-width-1-1@s">
                                <label className="uk-form-label" htmlFor="name">Nome</label>
                                <input type="text" 
                                    id="name" 
                                    name="name" 
                                    placeholder="Nome" 
                                    className="uk-input uk-form-small" 
                                    defaultValue={member?.name}
                                    style={{textTransform: 'uppercase'}}
                                    autoComplete={"off"}
                                    onFocus={handleFocus}
                                    ref={register} />
                                <small className="uk-text-danger">{errors.name?.message}</small>
                            </div>
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-3@s">
                                    <label className="uk-form-label" htmlFor="gender">Sexo</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="gender" 
                                            name="gender"
                                            defaultValue={member?.gender}
                                            ref={register}
                                        >
                                            <option value="" key={`gender0`}>Selecione...</option>
                                            <option value={Gender.FEMALE} key={`gender1`}>Feminino</option>                                            
                                            <option value={Gender.MALE} key={`gender2`}>Masculino</option>                                          
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.gender?.message}</small>
                                </div>
                                <div className="uk-width-1-3@s">
                                    <label className="uk-form-label" htmlFor="document">CPF</label>
                                    <input type="text" 
                                        id="document" 
                                        name="document" 
                                        placeholder="999.999.999-99" 
                                        className="uk-input uk-form-small"
                                        defaultValue={member?.document}
                                        autoComplete={"off"}
                                        ref={register}
                                        onFocus={handleFocus}
                                        onChange={(e) => { e.target.value = cpfMask(e.target.value) }}
                                    />
                                    <small className="uk-text-danger">{errors.document?.message}</small>
                                </div>
                                <div className="uk-width-1-3@s">
                                    <label className="uk-form-label" htmlFor="register">RG</label>
                                    <input type="text" 
                                        id="register" 
                                        name="register" 
                                        placeholder="AA 99.999.999" 
                                        className="uk-input uk-form-small"
                                        defaultValue={member?.register}
                                        style={{textTransform: 'uppercase'}}
                                        autoComplete={"off"}
                                        onFocus={handleFocus}
                                        ref={register} />
                                    <small className="uk-text-danger">{errors.register?.message}</small>
                                </div> 
                                <div className="uk-width-1-3@s">
                                    <label className="uk-form-label" htmlFor="birthDate">
                                        Nascimento {calcYearsOld(member?.birthDate.toString())}
                                    </label>
                                    <input type="type" 
                                        id="birthDate" 
                                        name="birthDate" 
                                        placeholder="99/99/9999" 
                                        className="uk-input uk-form-small"
                                        defaultValue={displayDate(member?.birthDate?.toString())}
                                        autoComplete={"off"}
                                        onFocus={handleFocus}
                                        ref={register}
                                        onChange={(e) => { e.target.value = dateMask(e.target.value) }}
                                    />
                                    <small className="uk-text-danger">{errors.birthDate?.message}</small>
                                </div>
                                <div className="uk-width-1-3@s">
                                    <label className="uk-form-label" htmlFor="stateBirth">Estado Nasc.</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="stateBirth" 
                                            name="stateBirth"
                                            defaultValue={member?.stateBirth}
                                            ref={register}
                                            disabled={isDisabled}
                                            onChange={(el) => selectCitiesBorn(el.target.value)}
                                        >
                                            <option value="" key="stateBirth0">Selecione...</option>
                                            {
                                                states.map(f => {
                                                    return <option value={f.initial} key={`stateBirth${f.initial}`}>{f.name}</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.stateBirth?.message}</small>
                                </div>
                                <div className="uk-width-1-3@s">
                                    <label className="uk-form-label" htmlFor="cityBirth">Cidade Natal</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="cityBirth" 
                                            name="cityBirth"
                                            defaultValue={member?.cityBirth}
                                            ref={register}
                                            disabled={isDisabled || citiesBorn.length === 0}
                                        >
                                            <option value="" key={`cityBirth0`}>Selecione...</option>
                                            {
                                                citiesBorn.map(c => {
                                                    return <option value={c} key={c}>{c}</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.cityBirth?.message}</small>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-4@s uk-card uk-card-default uk-card-body uk-flex uk-flex-column uk-flex-middle">
                            {   
                                image ? 
                                <img
                                    style={{height:85, width: 85, marginBottom: 1}}
                                    src={typeof(image) == "string" ? `data:image/jpeg;base64,${image}` : URL.createObjectURL(image)}
                                    alt="Imagem Membro"
                                /> : 
                                <span uk-icon="icon: user; ratio: 4"></span>
                            }
                            {
                                member?.status != StatusType.INACTIVE && (
                                    <div className="js-upload" uk-form-custom="true">                                
                                        <input type="file" id='file-input-photo'
                                            accept="image/jpg, image/png, image/jpeg, image/webp"
                                            multiple={false}
                                            onChange={handleSelectImage} 
                                            disabled={isDisabled}
                                        />
                                        <button className="uk-button uk-button-danger uk-button-small" 
                                            type="button" 
                                            tabIndex={-1}
                                            disabled={isDisabled}
                                        >
                                            Foto
                                            <FontAwesomeIcon className="uk-margin-small-left" icon={faCamera} />
                                        </button>
                                    </div>
                                )
                            }
                        </div>                            
                        <div className="uk-width-1-2@s">
                            <label className="uk-form-label" htmlFor="motherName">Nome da Mãe</label>
                            <input type="text" 
                                id="motherName" 
                                name="motherName" 
                                placeholder="Nome da Mãe" 
                                className="uk-input uk-form-small" 
                                defaultValue={member?.motherName}
                                style={{textTransform: 'uppercase'}}
                                autoComplete={"off"}
                                ref={register} />
                            <small className="uk-text-danger">{errors.motherName?.message}</small>
                        </div>
                        <div className="uk-width-1-2@s">
                            <label className="uk-form-label" htmlFor="fatherName">Nome do Pai</label>
                            <input type="text" 
                                id="fatherName" 
                                name="fatherName" 
                                placeholder="Nome do Pai" 
                                className="uk-input uk-form-small" 
                                defaultValue={member?.fatherName}
                                style={{textTransform: 'uppercase'}}
                                autoComplete={"off"}
                                ref={register} />
                            <small className="uk-text-danger">{errors.fatherName?.message}</small>
                        </div>                                                       
                        <div className="uk-width-1-4@s">                                   
                            <div className="uk-form-controls">
                                <label className="uk-form-label" htmlFor="maritalStatus">Estado Civil</label>
                                <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                    <select className="uk-form-small"
                                        id="maritalStatus" 
                                        name="maritalStatus"
                                        defaultValue={member?.maritalStatus}
                                        disabled={isDisabled}
                                        ref={register}
                                    >
                                        <option value="" key={`maritalStatus0`}>Selecione...</option>
                                        <option value={MaritalStatus.MARRIED} key={`maritalStatus1`}>Casado(a)</option>                                            
                                        <option value={MaritalStatus.DIVORCED} key={`maritalStatus2`}>Divorciado(a)</option>                                            
                                        <option value={MaritalStatus.SINGLE} key={`maritalStatus3`}>Solteiro(a)</option>                                            
                                        <option value={MaritalStatus.WIDOWER} key={`maritalStatus4`}>Viúvo(a)</option>                                            
                                    </select>
                                    <button 
                                        className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                        style={{textTransform: 'none'}} 
                                        type="button" 
                                        tabIndex={-1}
                                    >
                                        <span></span>
                                        <span uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                            <small className="uk-text-danger">{errors.maritalStatus?.message}</small>
                        </div>
                        <div className="uk-width-1-2@s">
                            <label className="uk-form-label" htmlFor="lifePartnerName">Cônjuge</label>
                            <input type="text" 
                                id="lifePartnerName" 
                                name="lifePartnerName" 
                                placeholder="Nome do Cônjuge" 
                                className="uk-input uk-form-small" 
                                defaultValue={member?.lifePartnerName}
                                style={{textTransform: 'uppercase'}}
                                autoComplete={"off"}
                                ref={register}
                            />
                            <small className="uk-text-danger">{errors.lifePartnerName?.message}</small>
                        </div>                        
                        <div className="uk-width-1-4@s">
                            <label className="uk-form-label" htmlFor="since">Membro Desde</label>
                            <input type="text" 
                                id="since" 
                                name="since" 
                                placeholder="99/99/9999" 
                                className="uk-input uk-form-small"
                                defaultValue={displayDate(member?.since.toString())}
                                autoComplete={"off"}
                                onFocus={handleFocus}
                                ref={register}
                                onChange={(e) => { e.target.value = dateMask(e.target.value) }}
                            />
                            <small className="uk-text-danger">{errors.since?.message}</small>
                        </div>
                        <div className="uk-width-1-4@s">
                            <label className="uk-form-label" htmlFor="baptismDate">Data Batismo</label>
                            <input type="text" 
                                id="baptismDate" 
                                name="baptismDate" 
                                placeholder="99/99/9999" 
                                className="uk-input uk-form-small"
                                defaultValue={displayDate(member?.baptismDate.toString())}
                                autoComplete={"off"}
                                onFocus={handleFocus}
                                ref={register}
                                onChange={(e) => { e.target.value = dateMask(e.target.value) }}
                            />
                            <small className="uk-text-danger">{errors.baptismDate?.message}</small>
                        </div>
                        <div className="uk-width-1-4@s">
                            <label className="uk-form-label" htmlFor="baptismSpiritDate">Data Batismo Espírito Santo</label>
                            <input type="text" 
                                id="baptismSpiritDate" 
                                name="baptismSpiritDate" 
                                placeholder="99/99/9999" 
                                className="uk-input uk-form-small"
                                defaultValue={displayDate(member?.baptismSpiritDate?.toString())}
                                autoComplete={"off"}
                                onFocus={handleFocus}
                                ref={register}
                                onChange={(e) => { e.target.value = dateMask(e.target.value) }}
                            />
                            <small className="uk-text-danger">{errors.baptismSpiritDate?.message}</small>
                        </div>
                        
                        <div className="uk-width-1-2@s">
                            <label className="uk-form-label" htmlFor="email">E-mail</label>
                            <input type="text" 
                                id="email" 
                                name="email" 
                                placeholder="email@email.com" 
                                className="uk-input uk-form-small" 
                                defaultValue={member?.email}
                                autoComplete={"off"}
                                ref={register} />
                            <small className="uk-text-danger">{errors.email?.message}</small>
                        </div>                        
                    </div>
                    <div className="uk-grid-small" uk-grid="true">
                        <div className="uk-width-1-2@s">
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-4@s">
                                    <label className="uk-form-label" htmlFor="zipCode">CEP</label>
                                    <input type="text" 
                                        id="zipCode" 
                                        name="zipCode" 
                                        placeholder="00000-000" 
                                        className="uk-input uk-form-small" 
                                        defaultValue={member?.zipCode}
                                        autoComplete={"off"}
                                        ref={register}
                                        onFocus={handleFocus}
                                        onChange={(e) => { e.target.value = handleCEP(e.target.value) }}
                                    />
                                    <small className="uk-text-danger">{errors.zipCode?.message}</small>
                                </div> 
                                <div className="uk-width-3-4@s">
                                    <label className="uk-form-label" htmlFor="address">Endereço</label>
                                    <input type="text" 
                                        id="address" 
                                        name="address" 
                                        placeholder="Endereço" 
                                        className="uk-input uk-form-small" 
                                        defaultValue={member?.address}
                                        style={{textTransform: 'uppercase'}}
                                        autoComplete={"off"}
                                        ref={register}
                                    />
                                    <small className="uk-text-danger">{errors.address?.message}</small>
                                </div> 
                            </div>
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-4@s">
                                    <label className="uk-form-label" htmlFor="numberAddress">Número</label>
                                    <input type="text" 
                                        id="numberAddress" 
                                        name="numberAddress"
                                        placeholder="00000" 
                                        className="uk-input uk-form-small"
                                        defaultValue={member?.numberAddress}
                                        ref={register}
                                        autoComplete={"off"}
                                        onFocus={handleFocus}
                                        onChange={(e) => { e.target.value = numberMask(e.target.value) }}
                                    />
                                    <small className="uk-text-danger">{errors.numberAddress?.message}</small>
                                </div> 
                                <div className="uk-width-3-4@s">
                                    <label className="uk-form-label" htmlFor="addressLine2">Complemento</label>
                                    <input type="text" 
                                        id="addressLine2" 
                                        name="addressLine2" 
                                        placeholder="Complemento" 
                                        className="uk-input uk-form-small" 
                                        defaultValue={member?.addressLine2}
                                        style={{textTransform: 'uppercase'}}
                                        autoComplete={"off"}
                                        ref={register}
                                    />
                                    <small className="uk-text-danger">{errors.addressLine2?.message}</small>
                                </div> 
                            </div>
                            <div className="uk-grid-small" uk-grid="true">                                
                                <div className="uk-width-1-2@m">
                                    <label className="uk-form-label" htmlFor="neighborhood">Bairro</label>
                                    <input type="text" 
                                        id="neighborhood" 
                                        name="neighborhood" 
                                        placeholder="Bairro" 
                                        className="uk-input uk-form-small" 
                                        defaultValue={member?.neighborhood}
                                        style={{textTransform: 'uppercase'}}
                                        autoComplete={"off"}
                                        ref={register}
                                    />
                                    <small className="uk-text-danger">{errors.neighborhood?.message}</small>
                                </div> 
                                <div className="uk-width-1-2@m">
                                    <label className="uk-form-label" htmlFor="phone">Telefone</label>
                                    <input type="text" 
                                        id="phone" 
                                        name="phone" 
                                        placeholder="(99) 99999-9999" 
                                        className="uk-input uk-form-small"
                                        defaultValue={member?.phone}
                                        ref={register}
                                        autoComplete={"off"}
                                        onChange={(e) => { e.target.value = phoneMask(e.target.value) }}
                                    />
                                    <small className="uk-text-danger">{errors.phone?.message}</small>
                                </div>
                            </div>
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-2@m">
                                    <label className="uk-form-label" htmlFor="stateBirth">Estado</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="state" 
                                            name="state"
                                            defaultValue={member?.state}
                                            ref={register}
                                            disabled={isDisabled}
                                            onChange={(el) => selectCities(el.target.value)}
                                        >
                                            <option value="" key="state0">Selecione...</option>
                                            {
                                                states.map(f => {
                                                    return <option value={f.initial} key={`state${f.initial}`}>{f.name}</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.state?.message}</small>
                                </div>
                                <div className="uk-width-1-2@m">
                                    <label className="uk-form-label" htmlFor="city">Cidade</label>
                                    <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                                        <select className="uk-form-small"
                                            id="city" 
                                            name="city"
                                            defaultValue={member?.city}
                                            ref={register}
                                            disabled={isDisabled || cities.length === 0}
                                        >
                                            <option value="" key={`city0`}>Selecione...</option>
                                            {
                                                cities.map(c => {
                                                    return <option value={c} key={`city${c}`}>{c}</option>
                                                })
                                            }
                                        </select>
                                        <button 
                                            className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                                            style={{textTransform: 'none'}} 
                                            type="button" 
                                            tabIndex={-1}
                                        >
                                            <span></span>
                                            <span uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                    <small className="uk-text-danger">{errors.city?.message}</small>
                                </div>                                
                            </div>                            
                        </div>
                        <div className="uk-width-1-2@s">
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1@s">                                    
                                    {
                                        isAdding ? [
                                            <label className="uk-form-label" htmlFor="note">Observações</label>,
                                            <textarea
                                                id="note" 
                                                name="note"
                                                className="uk-textarea uk-form-small uk-text-uppercase" 
                                                rows={4} 
                                                style={{resize: 'none'}}
                                                defaultValue={member?.note}
                                                autoComplete={"off"}
                                                ref={register}
                                            />,
                                            <small className="uk-text-danger">{errors.note?.message}</small>
                                        ] : (
                                            <MemberNoteHandle memberId={member?.id ?? 0} canChange={canChange} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (member?.id && canChange) && (
                            <div className="uk-grid-small" uk-grid="true">
                                <div className="uk-width-1-2@s">
                                    <span className="uk-text-italic uk-text-small">Adicionado por:</span>
                                    <span className="uk-text-muted uk-text-small uk-margin-small-left">
                                        {`${member?.createdBy} - ${displayDateTime(member?.createdDate)}`}
                                    </span>                                    
                                </div>  
                                {
                                    member.modifiedBy &&
                                        <div className="uk-width-1-2@s">
                                            <span className="uk-text-italic uk-text-small">Alterado por:</span>
                                            <span className="uk-text-muted uk-text-small uk-margin-small-left">
                                                {`${member?.modifiedBy} - ${displayDateTime(member?.modifiedDate)}`}
                                            </span> 
                                        </div>  
                                }     
                            </div>
                        )
                    }
                </div>
                <div className="uk-modal-footer uk-margin-bottom">
                    <div className="uk-grid-small uk-flex uk-flex-center" uk-grid="true">
                        <div className="uk-text-center">   
                            <Link to="/membros" className="uk-button uk-button-default uk-margin-left uk-width-1-1">
                                Voltar
                            </Link>
                        </div>
                        {
                            member?.status != StatusType.INACTIVE && <>                            
                                <div className="uk-text-center">
                                    <button 
                                        id="btn-save"
                                        disabled={formState.isSubmitting || 
                                            functions.length === 0 || 
                                            congregations.length === 0 || 
                                            isDisabled
                                        }
                                        className="uk-button uk-button-primary uk-margin-left uk-width-1-1" 
                                        type="submit">                                    
                                        {formState.isSubmitting && <div uk-spinner="true"></div>}
                                        Salvar
                                    </button>
                                </div>
                                <div className="uk-text-center">
                                    {
                                        (!isAdding && !isDisabled) &&
                                            <div className="uk-button-group uk-width-1-1">
                                                <button 
                                                    id="btn-print"
                                                    disabled={isButtonPrintDisable()}
                                                    className="uk-button uk-button-default uk-margin-left uk-button-small" 
                                                    type="button"
                                                >
                                                    <FontAwesomeIcon className="uk-margin-small-right" icon={faPrint} />
                                                    Imprimir
                                                </button>
                                                <div className="uk-inline">
                                                    <button 
                                                        className="uk-button uk-button-default" 
                                                        type="button"
                                                        disabled={isButtonPrintDisable()}
                                                    >
                                                        <span uk-icon="icon:  triangle-down"></span>
                                                    </button>
                                                    <div uk-dropdown="mode: click; boundary: ! .uk-button-group; boundary-align: true;"
                                                        ref={dropdownOptsRef}>
                                                        <ul className="uk-nav uk-dropdown-nav">
                                                            <li>
                                                                <a href="#" onClick={(ev) => { ev.preventDefault(); handlePrint(); }}
                                                                className='print'>
                                                                    {isRunningPrint && <div uk-spinner="true; ratio:0.5"></div>}
                                                                    Ficha de Cadastro
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" onClick={(ev) => { ev.preventDefault(); handlePrintRecommendation(); }}
                                                                    className='print'>
                                                                    {isPrintLetter && <div uk-spinner="true; ratio:0.5"></div>}
                                                                    Carta de Recomendação
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" onClick={(ev) => { ev.preventDefault(); handleBirthCard(); }}
                                                                    className='print'>
                                                                    {isRunningBirthdayCard && <div uk-spinner="true; ratio:0.5"></div>}
                                                                    Cartão de Aniversário
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                                {
                                    (!isAdding && !isReadOnly) && (
                                        <div className="uk-text-center">
                                            <button 
                                                id="btn-credencial"
                                                disabled={isButtonCredentialDisable()}
                                                className="uk-button uk-button-primary uk-button-success uk-margin-left uk-width-1-1" 
                                                type="button"
                                                onClick={()=> { setRequestCredential(true); }}
                                            >                                    
                                                {
                                                    (hasCredentialRequested == undefined) ? 
                                                        (<div uk-spinner="true"></div>) : (
                                                            <FontAwesomeIcon 
                                                                icon={hasCredentialRequested ? faHourglassHalf : faAddressCard} 
                                                                className="uk-margin-small-right"
                                                            />
                                                        )
                                                }
                                                {
                                                    hasCredentialRequested ? 'Carteirinha Solicitada' : 'Solicitar Carteirinha'
                                                }
                                            </button>
                                        </div>
                                    )                            
                                }
        
                                {
                                    (!isReadOnly && !isAdding && hasCredentialRequested) && (
                                        <div className="uk-text-center">
                                            <button 
                                                id="btn-credencial-cancel"
                                                className="uk-button uk-button-primary uk-button-danger uk-margin-left uk-width-1-1" 
                                                type="button"
                                                disabled={formState.isSubmitting}
                                                onClick={()=> { setIsDialogDelete(true); }}
                                                uk-tooltip="title: Cancelar Solicitação Carteirinha"
                                            >                                    
                                                {
                                                    (hasCredentialRequested == undefined || isCancellingCred) ? 
                                                        (<div uk-spinner="true"></div>) : (
                                                            <FontAwesomeIcon 
                                                                icon={faXmark} 
                                                                className="uk-margin-small-right"
                                                            />
                                                        )
                                                }
                                                Credencial
                                            </button>
                                        </div>
                                    )
                                }
                            </>
                        }
                    </div>
                </div>
            </form>

            {
                (!isAuxSec && !isAdding) && (
                    <>
                        <div className="uk-modal-body" hidden={tabIndex != 1}>
                            <MemberDocList memberId={member?.id ?? 0}/>
                        </div>
                        <div className="uk-modal-body" hidden={tabIndex != 2}>
                            <MemberHistoricList 
                                memberId={member?.id ?? 0} 
                                refresh={refresh}
                                memberCurrent={member}
                            />                            
                        </div>
                        <div className="uk-modal-body" hidden={tabIndex != 3}>
                            <MemberMeetingsList memberId={member?.id ?? 0}/>
                        </div>
                        <div className="uk-modal-body" hidden={tabIndex != 4}>
                            <MemberMinisterDocList memberId={member?.id ?? 0}/>
                        </div>                        
                    </>
                )
            }

            <MemberCredencialRequest 
                open={requestCredential} 
                id={member?.id ?? 0}
                ecclesiasticalFunction={member?.ecclesiasticalFunction}
                afterHandleSubmit={afterHandleSubmitCredential}
                afterCloseModal={afterCloseCredentialModal}
            />

            <MemberLetterRecommendation 
                open={isPrintLetter} 
                id={member?.id ?? 0}
                name={member?.name ?? ''}
                ecclesiasticalFunction={member?.ecclesiasticalFunction}
                afterHandleSubmit={afterHandleSubmitLetter}
                afterCloseModal={afterCloseLetterlModal}
            />
            
            <DialogConfirm 
                open={isDialogDelete} 
                text={['Desejar realmente cancelar a solicitação de credencial?']} 
                afterClose={dialogClose}
                handleConfirmation={(confirmationCode) => dialogConfirm(confirmationCode)}
            />
       </div>
    )
}