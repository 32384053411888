import { BehaviorSubject } from 'rxjs';
import environment from '../environment';
import { UserRole } from '../models/enums/UserRole';
import UserLogin from '../models/UserLogin';
import api from './api';

const currentUserSubject = new BehaviorSubject<UserLogin | null>(null);

if (localStorage.getItem(environment.userStorage) != null) {
    const user = localStorage.getItem(environment.userStorage);
    currentUserSubject.next(user ? JSON.parse(user) : null);
}

export const authenticationService = {
    login,
    logout,
    removeUserLogged,
    currentUser: currentUserSubject,
    get currentUserValue (): any { return currentUserSubject.value },
    userRole,
    showRole,
    canChange,
    isAdmin,
    isReadOnly,
    isAuxSec,
    isRoutine
};

async function login(username: string, password: string) {
    const response = await api.post('login', { 'username': username, 'password': password });

    if (response.data.success) {
        localStorage.setItem(environment.userStorage, JSON.stringify(response.data.item));
        currentUserSubject.next(response.data.item);

        return response.data.item;
    }
}

async function logout(redirectTo: any) {
    await api.post('logout', { token: currentUserSubject.value?.refreshToken });
    removeUserLogged();
    window.location.href = `/entrar${(redirectTo && typeof(redirectTo) == "string") ? `?redirecionar=${redirectTo}` : ''}`;
}

async function removeUserLogged() {
    localStorage.removeItem(environment.userStorage);
    localStorage.removeItem(`${environment.storage}member-congregation`);
    currentUserSubject.next(null);
}

function userRole() {
    return showRole(currentUserSubject.value?.role);
}

function showRole(role: string | undefined) {
    switch (role) {
        case UserRole.ADMIN:
            return "Admin";
        case UserRole.AUX_SECRETARY:
            return "Aux. Secreteria";
        case UserRole.READ:
            return "Somente Leitura";
        case UserRole.SECRETARY:
            return "Secretaria";
        case UserRole.ROUTINE:
            return "Rotina";
        default:
            return "Falha de Perfil";
    }
}

export function canChange() {
    return isAdmin() || currentUserSubject.value?.role == `${UserRole.SECRETARY}`;
}

export function isAdmin() {
    return `${UserRole.ADMIN}` == currentUserSubject.value?.role;
}

export function isReadOnly() {
    if (!currentUserSubject.value) return true;
    return [`${UserRole.READ}`, `${UserRole.ROUTINE}`].includes(currentUserSubject.value?.role);
}

export function isAuxSec() {
    return `${UserRole.AUX_SECRETARY}` == currentUserSubject.value?.role;
}

export function isRoutine() {
    return `${UserRole.ROUTINE}` == currentUserSubject.value?.role;
}