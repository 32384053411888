import { useEffect, useState } from "react";
import TablePagination from "../../../components/TablePagination";
import api from "../../../services/api";

import "react-toastify/dist/ReactToastify.css";

import { displayDateTime } from "../../../utils/masks";
import IHistDTO from "../../../models/IHistDTO";
import { StatusType } from "../../../models/enums/StatusType";
import { TypeHistory } from "../../../models/enums/TypeHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import MemberHistoricDetail from "../member-historic-detail/MemberHistoricDetail";
import Member from "../../../models/Member";
import DialogConfirm from "../../../components/DialogConfirm";

interface Params {
  memberId: number;
  refresh: boolean;
  memberCurrent?: Member;
}

export default function MemberHistoricList(props: Params) {
  const { memberId, refresh, memberCurrent } = props;

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [member, setMember] = useState();
  const [openNote, setOpenNote] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  
  const columns = [
    {
      name: "Histórico",
      grow: 2,
      cell: (row: IHistDTO) => <label className={row.status == StatusType.INACTIVE ? 'uk-text-danger' : ''}>{row.reason}</label>
    },
    {
      name: "Obs.",
      cell: (row: IHistDTO) => (
        [TypeHistory.CHANGE_RECORDS].indexOf(row.reason) < 0 ? (
          <label 
            uk-tooltip={`title: ${row.note}`}
            className={row.status == StatusType.INACTIVE ? 'uk-text-danger' : ''}
            onClick={(ev)=> { ev.preventDefault(); handleNote(row); }}
          >
            {row.note?.slice(0, 50)}{(row.note?.length??0) > 50 && '...'}
          </label>
        ) : (
          <a href="#" onClick={(ev)=> { ev.preventDefault(); handleDetail(row); }}
            uk-tooltip="title: Ver Diferenças"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
        )
      )
    },
    {
      name: "Realizado",
      grow: 2,
      cell: (row: IHistDTO) => (
        <div className="uk-flex uk-flex-column">
          <label className={row.status == StatusType.INACTIVE ? 'uk-text-danger' : ''}>
            Por: {row.createdBy}<br/>
            Em: {displayDateTime(row.createdDate)}
          </label>
          {
            row.status == StatusType.INACTIVE && (
              <label className="uk-text-danger">
                <strong>Cancelado em: {displayDateTime(row.modifiedDate)}</strong>
                <br/>
                Por: {row.modifiedBy}
              </label>
            )
          }
          {
            (row.status == StatusType.ACTIVE && row.historyClosedOn) && (
              <label className="uk-text-success">
                <strong>Entregue em: {displayDateTime(row.modifiedDate)}</strong>
                <br/>
                Por: {row.modifiedBy}
              </label>
            )
          }
        </div>
      )
    }
  ];

  useEffect(() => {
  }, []);

  const fetchHist = async (page: number, limit: number) => {
    try {
      const response = await api.get(`members/${memberId}/historic`, {
        params: {
          page: page,
          limit: limit
        },
      });

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const afterCloseModal = () => {
    setOpenDetail(false);
    setMember(undefined);
  }

  const handleDetail = (row: any) => {
    setMember(row.note);
    setOpenDetail(true);
  }

  const handleNote = (row: any) => {
    setNote(row.note);
    setOpenNote(true);
  }

  return (
    <div id="page-member-historic">   
      <TablePagination
        columns={columns}
        fetchData={fetchHist}
        refresh={refresh}
      />
      <MemberHistoricDetail 
        open={openDetail} 
        memberChanged={member}
        memberCurrent={memberCurrent}
        afterCloseModal={afterCloseModal}
      />
      <DialogConfirm 
        open={openNote}
        text={[note]}
        handleConfirmation={()=>setOpenNote(false)}
        afterClose={()=>setOpenNote(false)}
      />
    </div>
  );
}
