import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import route from "../routes";

import './navbar.css';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';

import { authenticationService } from '../services/auth.service';
import UserLogin from "../models/UserLogin";
import { UserRole } from "../models/enums/UserRole";
import AutoComplete from "./AutoComplete";
import IAutoCompleteObject from "../models/IAutoCompleteObject";
import { useAuth } from "./auth/AuthContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        purple: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
    }),
);

const Navbar = () => {
    const [userLogged, setUserLogged] = useState<UserLogin>();
    const [dataMembersOpts, setDataMembersOpts] = useState<[]>([]);
    const { logout } = useAuth();
    const { pathname } = useLocation();
    const classes = useStyles();    

    useEffect(() => {
        setUserLogged(authenticationService.currentUserValue as UserLogin);
    }, [authenticationService.currentUserValue]);

    const handleClickItemMenu = () => {
        const ele = document.getElementById("menu-toggle");
        if (ele) {
            ele.click();
        }
    }

    if (pathname.indexOf('entrar') > -1) {
        return null;
    }

    if (pathname.indexOf('/reuniao/em-andamento') < 0) {
        const ele = document.getElementById("navbar-main");
        if (ele) {
            ele.className = 'uk-navbar uk-navbar-container uk-margin';
        }
    }

    const onAfterAutoCompleteChange = async (selected: IAutoCompleteObject) => {
        if (selected.id > 0) {
            window.location.href = `/membros/${selected.id}`;
        }
    }

    return (
        <nav className="uk-navbar uk-navbar-container uk-margin" id="navbar-main">
            <div className="uk-navbar-left">
                <a className="uk-navbar-toggle"
                    href="#"
                    uk-toggle="target: #offcanvas-nav-primary"
                    id="menu-toggle"
                >
                    <span uk-icon="icon: menu"></span>
                </a>
            </div>
            <div className="uk-navbar-center uk-flex-wrap" style={{width: '30vw'}}>
                <label className="uk-form-label uk-margin-small-right">Pesquise Por Nome</label>
                <AutoComplete
                    urlFetch="members/name/"
                    suggestions={dataMembersOpts}
                    onAfterChange={onAfterAutoCompleteChange}
                />
            </div>
            {
                userLogged &&
                    <div className="uk-navbar-right">
                        <div className="uk-flex uk-flex-column uk-text-right">
                            <label style={{ fontSize: 10}}>
                                {userLogged.name}
                            </label>
                            <label style={{fontSize: 10}}>
                                { authenticationService.userRole() }
                                { (userLogged.congregations && userLogged.congregations?.length > 0) && (<span><br/>({userLogged.congregations?.map(c => c.description).join(', ')})</span>)}
                            </label>
                        </div>
                        <div className="uk-margin-right uk-text-center">
                            <div className={classes.root}>
                                <Avatar className={classes.purple}>
                                    <label uk-tooltip={`title: ${userLogged.name}; pos: left`}>
                                        {
                                            userLogged.name.toUpperCase().split(" ")[0][0]
                                        }
                                        {
                                            userLogged.name.toUpperCase().split(" ").length > 1 &&
                                            userLogged.name.toUpperCase().split(" ")[1][0]
                                        }
                                    </label>
                                </Avatar>
                            </div>
                        </div>                        
                    </div>
            }
            <div id="offcanvas-nav-primary"
                uk-offcanvas="overlay: true; mode: reveal;"
            >
                <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                    <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical uk-nav-parent-icon"
                        uk-nav="multiple: true">
                        {
                            route.filter(r => r?.icon && r?.path !== '/').map(r => {
                                if (authenticationService.currentUser.value) {
                                    const userRole = authenticationService.currentUser.value?.role as UserRole;
                                    if (r && !(r.denied && r.denied?.includes(userRole))) {
                                        return (
                                            <li className={`${pathname === r.path ? "uk-active" : ""}`} key={r.icon}>
                                                <Link to={r.path}
                                                    onClick={handleClickItemMenu}
                                                    className="uk-flex-center uk-flex-middle"
                                                >
                                                    <span className="uk-margin-small-right" uk-icon={`icon: ${r.icon};`}></span>
                                                    <span>{r.label}</span>
                                                </Link>                                            
                                            </li>                        
                                        )
                                    }
                                }

                            })
                        }
                        <li key={'logout'}>
                            <a  href="#" onClick={(ev) => { 
                                ev.preventDefault(); 
                                authenticationService.logout('');
                                logout();
                            }}
                                className="uk-flex-center uk-flex-middle"
                            >
                                <span className="uk-margin-small-right" uk-icon={`icon: sign-out;`}></span>
                                <span>Sair</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )    
}

export default Navbar;

