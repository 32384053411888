import { faFile, faSearch, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import TablePagination from "../../../components/TablePagination";
import IMeetingOpenDTO from "../../../models/IMeetingOpenDTO";
import Meeting from "../../../models/Meeting";
import UserLogin from "../../../models/UserLogin";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";
import { displayDateTime } from "../../../utils/masks";

import "./meeting-list.css";
import moment from "moment";
import { utils } from "../../../utils/utils";
import { toast } from "react-toastify";
import toastOptions from "../../../utils/toastOptions";

export default function DashboardSecretary() {
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [isAuxSec, setAuxSec] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>();
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [meetingOpened, setMeetingOpened] = useState<IMeetingOpenDTO>();
  const [years, setYears] = useState<string[]>([]);
  const [currentYear, setCurrentYear] = useState<string>("Todos os anos");
  const [termSearch, setTermSearch] = useState<string>("");
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [ixAgenda, setIxAgenda] = useState<number>(-1);

  const columns = [
    {
      name: "Reunião",
      sortable: true,
      grow: 2,
      cell: (row: Meeting) => <span>{row.title}</span>,
    },
    {
      name: "Fechada",
      sortable: true,
      cell: (row: Meeting) => <span>{displayDateTime(row.timeClose)}</span>,
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: Meeting, index: number) => (
        <div>
          <Link to={`/reuniao/${row.id}`} 
            uk-tooltip="title: Visualizar"
            className="uk-margin-right"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Link>
          {
            row.agenda && 
              <a href="#" uk-tooltip="title: Ver Ata" 
                className='uk-margin-right' 
                onClick={(ev) => { ev.preventDefault(); handleAgendaView(row, index) }}
              >
                {(ixAgenda == index) ? <div uk-spinner="true; ratio:0.5"></div> : <FontAwesomeIcon icon={faFile} />}
              </a>
          }
          <Link
            to={`/reuniao/${row.id}/membros`}
            uk-tooltip="title: Membros Presentes"
            className="uk-margin-right"
          >
            <FontAwesomeIcon icon={faUsers} />
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());
    setAuxSec(authenticationService.isAuxSec());
    init();
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(termSearch);
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [termSearch]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [delayedSearchTerm]);

  const init = async () => {
    handleYears();
  };

  const fetchMeetings = async (page: number, limit: number) => {
    try {
      setTotalMeetings(0);

      let params = {
        page: page,
        limit: limit,
        year: currentYear.length > 4 ? -1: currentYear,
        user: authenticationService.isAuxSec() ? (authenticationService.currentUserValue as UserLogin).id : null,
      } as any;

      if (termSearch) {
        params = { ...params, agendaTerm: termSearch?.toUpperCase() };
      }

      const response = await api.get('meetings', {
        params: params
      });

      setTotalMeetings(response.data.total);

      const { data: dataMeeting } = await api.get('meetings/open');

      setMeetingOpened(dataMeeting.item);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  const handleYears = () => {
    setYears([]);

    setYears(["Todos os anos"])

    for (let i = moment().year(); i >= 2022; i--) {
      setYears(array => [...array, `${i}`]);
    }
  }

  const handleYear = async(newYear: string) => {
    setCurrentYear(newYear);
    setRefresh(!refresh);
  }

  const handleSearch = async (search: string) => {
    if (search.length > 2 || search.length === 0) {
      setTermSearch(search);
    }
  };

  const handleAgendaView = async (meeting: Meeting, ix: number) => {
    try {      
      setIxAgenda(ix);
      const { data } = await api.get(`meetings/${meeting?.id}/agenda/report`);   
      const name = `${new Date(meeting.createdDate).toLocaleDateString().replaceAll('/', '-')}-ata-${new Date().getTime()}.pdf`;
      utils.printPDF(name, data.item);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.warning(m, toastOptions);
      });
    } finally {
      setIxAgenda(-1);
    }
  }

  return (
    <div id="page-meeting-list">
      <div className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom">
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Reuniões de Obreiros
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to="/secretaria"
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          <div className="uk-flex uk-flex-middle">
            Reuniões: {totalMeetings}
          </div>
          <div className="uk-grid-small uk-margin-top" uk-grid="true">
            <div className="uk-search uk-search-default uk-width-1-2@m">
              <span
                className="uk-search-icon-flip"
                uk-search-icon="true"
              ></span>
              <input
                className="uk-search-input uk-form-small"
                type="search"
                placeholder="Pesquise no conteúdo das atas..."
                style={{ textTransform: "uppercase" }}
                onChange={(ev) => {
                  handleSearch(ev.target.value);
                }}
              />
            </div>
            <div className="uk-search uk-search-default uk-width-1-4@m">
              <div className="uk-flex uk-flex-middle">                
                <label className="uk-form-label uk-margin-small-right" htmlFor="year">Ano</label>
                <div className="uk-form-controls">
                  <div uk-form-custom="target: > * > span:first-child" className="uk-width-1">
                    <select className="uk-form-small"
                      id="year" 
                      placeholder="Ano"
                      name="year"
                      value={currentYear}
                      onChange={(ev) => handleYear(ev.target.value)}
                    >       
                      {
                        years?.map(y => {
                            return <option key={`ano${y}`} value={`${y}`}>{y}</option>
                        })
                      }  
                    </select>
                    <button 
                      className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                      style={{textTransform: 'none'}} 
                      type="button" 
                      tabIndex={-1}
                    >
                      <span></span>
                      <span uk-icon="icon: chevron-down"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {meetingOpened && (
            <Link
              className="uk-button uk-button-primary uk-button-small uk-margin-left"
              to="/reuniao/em-andamento"
            >
              Acessar reunião em andamento <span uk-icon="arrow-right"></span>
            </Link>
          )}
          <TablePagination
            columns={columns}
            fetchData={fetchMeetings}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
