import moment from 'moment';
import { Gender } from '../models/enums/Gender';
import { TypeFile } from '../models/enums/TypeFile';
import { TypeHistory } from '../models/enums/TypeHistory';
import { AnnuityTypeFile } from '../models/enums/AnnuityTypeFile';
import { KnowAbout } from '../models/enums/KnowAbout';
import { PaymentType } from '../models/enums/PaymentType';

export const cpfMask = (value: string) => {
    return value
      .replace(/\D/g, '') // change any character diferent of the number
      .replace(/(\d{3})(\d)/, '$1.$2') // get 2 number groups. The 1st with 3 then 2nd with one, after grab the 1st group add a dot next to 2nd group
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
}

export const dateMask = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1');    
}

export const displayDate = (date: undefined | string) => {
    if (date) {
        if (date.indexOf("*") > 0) {
            return date;
        }
        
        const newDate = moment(date.split('T')[0]);
        
        return moment(newDate).format('DD/MM/YYYY');
    }

    return date;
}

export const displayDate2 = (date: Date | undefined) => {
    if (date) { 
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate());
        return moment(newDate).format('DD/MM/yyyy');
    }

    return date;
}

export const calcYearsOld = (date: undefined | string) => {
    if (date) {
        if (date.indexOf("*") > 0) {
            return '';
        }
        const today = moment(new Date());
        const newDate = moment(date);

        var duration = moment.duration(today.diff(newDate));

        return ` - Idade: ${duration.asYears().toString().split(".")[0]} anos`;
    }

    return date;
}

export const displayDateTime = (date: Date | undefined) => {
    if (date) { 
        const newDate = moment(date);
        return moment(newDate).format('DD/MM/yyyy HH:mm:ss');
    }

    return date;
}

export const displayTime = (date: Date | undefined) => {
    if (date) { 
        const newDate = moment(date);
        return moment(newDate).format('HH:mm:ss');
    }

    return date;
}

export const numberMask = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/\[^0-9]/g, '');
}

export const phoneMask = (value: string) => {
    if (value && value.length > 3) {
        const noFormat = value.replace(/\D/g, '');
        if (noFormat[2] != '9') {
            return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
        }
    }
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
}

export const CEPMask = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
}

export const timeMask = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1:$2')
        .replace(/(:\d{2})\d+?$/, '$1');
}

export const handleFocus = (event: any) => event.target.select();

export const filesAllowed = (file: File, allowPDF: boolean = true) => {
    const type = file.type.toLocaleLowerCase();
    const isImage = ["image/jpg", "image/png", "image/jpeg", "image/webp"].includes(type);

    if (!isImage && !allowPDF) {
        return false;
    }

    if (!isImage && allowPDF && type.indexOf("pdf") < 0) {
        return false;
    }

    return true;
}

export function showMemberTypeFile(file: string | undefined) {
    switch (file) {
        case TypeFile.EXCHANGE:
            return "Carta de Mudança";
        case TypeFile.RECOMMENDATION:
            return "Carta de Recomendação";
        case TypeFile.CREDENCIAL:
            return "Credencial";
        case TypeFile.RECEPTION:
            return "Carta de Recepção";
        case TypeFile.LGPD_TERM:
            return "Termo LGPD";
        case TypeFile.MARRIED_LETTER:
            return "Certidão de Casamento";
        default:
            return "Outro";
    }
}

export function showMeetingTypeFile(file: string | undefined) {
    switch (file) {
        case TypeFile.AGENDA_SIGNED:
            return "Ata Assinada";
        default:
            return "Outro";
    }
}

export function showAnnuityTypeFile(file: string | undefined) {
    switch (file) {
        case AnnuityTypeFile.CGADB:
            return "CGADB";
        case AnnuityTypeFile.CONFRADESP:
            return "CONFRADESP";
        default:
            return "Outra";
    }
}


export function CPFIsValid(cpf?: string | null) {	
    if (!cpf || cpf == null) {
        return true;
    }

    let add;
    let rev;
	cpf = cpf.replace(/[^\d]+/g,'');	
	if(cpf == '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length != 11 || 
		cpf == "00000000000" || 
		cpf == "11111111111" || 
		cpf == "22222222222" || 
		cpf == "33333333333" || 
		cpf == "44444444444" || 
		cpf == "55555555555" || 
		cpf == "66666666666" || 
		cpf == "77777777777" || 
		cpf == "88888888888" || 
		cpf == "99999999999")
			return false;		
	// Valida 1o digito	
	add = 0;	
	for (let i=0; i < 9; i ++)		
		add += parseInt(cpf.charAt(i)) * (10 - i);	
		rev = 11 - (add % 11);	
		if (rev == 10 || rev == 11)		
			rev = 0;	
		if (rev != parseInt(cpf.charAt(9)))		
			return false;		
	// Valida 2o digito	
	add = 0;	
	for (let i = 0; i < 10; i ++)		
		add += parseInt(cpf.charAt(i)) * (11 - i);	
	rev = 11 - (add % 11);	
	if (rev == 10 || rev == 11)	
		rev = 0;	
	if (rev != parseInt(cpf.charAt(10)))
		return false;		
	return true;   
}

export function memberTitle(member: any) {
    let ecclesiasticalFunction = member.ecclesiasticalFunction.description;
    
    if (ecclesiasticalFunction.toUpperCase().startsWith("MEMBRO")) {
        return "";
    }

    if (ecclesiasticalFunction.toUpperCase().startsWith("COOPERADOR")) {
        ecclesiasticalFunction = `${member.gender}` == Gender.MALE ? "Cooperador" : "Cooperadora";
    }

    return ecclesiasticalFunction;
}

export function showDeleteType(type: string | undefined) {
    switch (type) {
        case TypeHistory.CHANGE_CHURCH:
            return "Mudança de Ministério";
        case TypeHistory.CHANGE_CITY:
            return "Mudança de Cidade";
        case TypeHistory.LEAVE:
            return "Saiu da Igreja";
        case TypeHistory.OTHER:
            return "Outros";
        default:
            return "Não Identificado";
    }
}

export function showKnowAbout(howToKnow: string) {
    switch (howToKnow) {
        case KnowAbout.FACEBOOK:
            return "Facebook";
        case KnowAbout.INSTAGRAM:
            return "Instagram";
        case KnowAbout.SITE:
            return "Site adbelemuberaba.com.br";
        case KnowAbout.PASTOR:
            return "Meu pastor me incentivou";
        case KnowAbout.MEETING:
            return "Reunião de Obreiros";
        case KnowAbout.OTHER:
            return "Outro";
        default:
            return "-";
    }
}

export function paymentType(paymentType: string) {
    switch (paymentType) {
        case PaymentType.CASH:
            return "Dinheiro";
        case PaymentType.PIX:
            return "Pix";
        case PaymentType.OTHER:
            return "Outro";
        default:
            return "*";
    }
}