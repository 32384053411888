import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import "react-toastify/dist/ReactToastify.css";

export default function MemberDeleted() {
  const [totalMembers, setTotalMembers] = useState<number>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    fetchMembers();
  };

  const fetchMembers = async () => {
    try {
      let params = {
        page: 1,
        limit: 1,
      } as any;

      const response = await api.get(`/members/deleted`, {
        params: params,
      });

      setTotalMembers(response.data.total);

      return response;
    } catch {
      return { total: 0 };
    }
  };

  return (
    <div
      className="uk-card uk-card-success uk-card-small uk-card-hover"
      style={{ minHeight: "100%" }}
    >
      <div className="uk-card-header">
        <div className="uk-grid-small uk-flex-middle" uk-grid="true">
          <div className="uk-width-expand">
            <h3 className="uk-card-title uk-margin-remove-bottom">
              Membros Removidos
            </h3>
          </div>
        </div>
      </div>
      <div className="uk-card-body">
        {totalMembers == undefined ? (
          <div style={{ color: "#FFFFFF" }} uk-spinner="true; ratio:2">
            Carregando...
          </div>
        ) : (
          <h1 className="uk-heading-xlarge">{totalMembers}</h1>
        )}
      </div>
      <div className="uk-card-footer uk-margin-medium-top">
        <Link to="/membros/removidos">
          Visualizar <span uk-icon="search"></span>
        </Link>
      </div>
    </div>
  );
}
