import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TablePagination from '../../../components/TablePagination';
import api from '../../../services/api';

import 'react-toastify/dist/ReactToastify.css';

import { displayTime } from '../../../utils/masks';

interface Params {
    memberId: number;
}

export default function MemberMeetingsList(props: Params) {
    const { memberId } = props;

    const navigate = useNavigate();
    const [refresh, doRefresh] = useState(false);
    
    const columns = [
        {
            name: 'Reunião',
            grow: 2,
            cell: (row: any) => (
                <label>
                    {row.meeting.title}
                </label>
            )
        },
        {
            name: 'Presente?',
            cell: (row: any) => (
                <label className={`${!row.member && 'uk-text-danger uk-text-bold'}`}>
                    <span>
                        {row.member ? `Entrou às ${displayTime(row.member.timeArrived)}` : 'AUSENTE'}
                    </span>
                </label>
            )
        }
    ];  
    
    useEffect(()=> {
    }, []);
    
    const fetchMeetings = async (page: number, limit: number) => {
        try {
            const response = await api.get(`/members/${memberId}/meetings`, { 
                params: { 
                    page: page, 
                    limit: limit
                }
            });
    
            return response;
        } catch {
            return {data: [], total: 0}
        }
    };

    return (
        <div id="page-member-meetings">      
            <TablePagination
                columns={columns}
                fetchData={fetchMeetings}
                refresh={refresh}
            />
        </div>
    )
}