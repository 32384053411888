import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div id='not-found-page' uk-height-viewport="min-height: 100vh">
      <div className="uk-section uk-section-muted" uk-height-viewport="min-height: 100vh">
        <div className="uk-container uk-container-small uk-text-center">
          <p className="uk-text-large uk-text-lead uk-text-light">Desculpe...Página não encontrada 😟</p>
          
          <div className="uk-flex uk-flex-center uk-grid-small" uk-grid="true">
            <div className="uk-width-1-3">
              <button
                className="uk-button uk-button-primary uk-text-center" 
                type="button">
                <div className="uk-inline-clip uk-transition-toggle">
                  <div className="uk-transition-scale-up uk-transition-opaque">
                    <Link
                      className="uk-button uk-button-primary"
                      to={'/entrar'}>
                        Entrar
                    </Link>
                  </div>
                </div>
              </button>
            </div>

            <div className="uk-width-1-3">
              <button
                className="uk-button uk-button-primary uk-text-center" 
                type="button">
                <div className="uk-inline-clip uk-transition-toggle">
                  <div className="uk-transition-scale-up uk-transition-opaque">
                    <Link
                      className="uk-button uk-button-primary"
                      to={'/inicio'}>
                        Dashboard
                    </Link>
                  </div>
                </div>   
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}