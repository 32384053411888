import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState, useEffect, ChangeEvent } from "react";
import User from "../../../models/User";
import api from "../../../services/api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import toastOptions from "../../../utils/toastOptions";
import { authenticationService } from "../../../services/auth.service";
import { TypeFile } from "../../../models/enums/TypeFile";
import { filesAllowed, handleFocus, numberMask } from "../../../utils/masks";
import { getValidator } from "../../../utils/validatorsYup";
import { AnnuityTypeFile } from "../../../models/enums/AnnuityTypeFile";
import MemberAnnuity from "../../../models/MemberAnnuity";
import Modal from "../../../components/modal";

interface Params {
  memberId: number;
  docId?: number;
  add: boolean;
  afterHandleSubmit: any;
  afterCloseModal: any;
}

export default function MinisterDocAddEdit(props: Params) {
  const { memberId, docId, add, afterHandleSubmit, afterCloseModal } = props;

  const [modalIsOpen, setModelOpen] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [memberAnnuity, setFile] = useState<MemberAnnuity>();
  const [isReadOnly, setReadOnly] = useState<boolean>(true);
  const [doc, setDoc] = useState<File | string>();

  const schema = yup.object().shape({
    type: yup.string().required("Tipo obrigatório"),
    year: getValidator({ isNumeric: true }).required("Ano é obrigatório"),
    note: yup.string().when("type", {
      is: TypeFile.OTHERS,
      then: yup
        .string()
        .required("Observação obrigatória quando o tipo é [Outro]"),
    }),
  });

  const {
    register,
    handleSubmit,
    reset,
    errors,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setReadOnly(authenticationService.isReadOnly());
    setModelOpen(add);
    setDoc(undefined);
    if (docId) {
      handleEdit(docId);
    }
  }, [docId, add]);

  const closeModal = () => {
    handleCloseModal();
    afterCloseModal();
  };

  const handleCloseModal = () => {
    setUser(undefined);
    setModelOpen(false);
    afterCloseModal();
  };

  const onSubmit = async (data: MemberAnnuity) => {
    try {
      const formData = new FormData();

      data.id = memberAnnuity?.id;
      data.idMember = memberId;
      data.idFile = memberAnnuity?.file.id ?? 0;
      formData.append("data", JSON.stringify(data));

      if (doc) {
        formData.append("doc", doc);
      }

      if (!memberAnnuity?.id) {
        await api.post("files/annuity", formData);
        toast.success("Anuidade cadastrada com sucesso!", toastOptions);
      } else {
        await api.put("files/annuity", formData);
        toast.success("Anuidade atualizada com sucesso!", toastOptions);
      }
      handleCloseModal();
      afterHandleSubmit();
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    }
  };

  const handleEdit = async (id: number) => {
    try {
      const { data } = await api.get(`files/annuity/${id}/file`);
      setFile(data.item);
      setDoc(data.item.file);
      setModelOpen(true);
    } catch (e: any) {
      if (e.messages) {
        e.messages.forEach((m: string) => {
          toast.warning(m, toastOptions);
        });
      }
    }
  };

  const handleSelectDoc = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const img = event.target.files[0];

    if (!filesAllowed(img)) {
      toast.warn("Permitido apenas imagens ou PDF", toastOptions);
      return;
    }

    setDoc(img);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-small"
        shouldCloseOnOverlayClick={false}
      >
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">
            {user?.id ? "Editar Anuidade" : "Adicionar Anuidade"}
          </h2>
        </div>
        <button
          className="uk-modal-close-default"
          uk-close="true"
          type="button"
          onClick={handleCloseModal}
        />
        <form onSubmit={handleSubmit<MemberAnnuity>(onSubmit)} onReset={reset}>
          <div className="uk-modal-body">
            <div className="uk-grid-small" uk-grid="true">
              <div className="uk-width-1@s uk-flex uk-flex-column uk-flex-middle">
                <span uk-icon="icon: file-text; ratio: 4"></span>
                {doc && <label>{(doc as File).name}</label>}
                <div className="js-upload" uk-form-custom="true">
                  <input
                    type="file"
                    id="file-input-photo"
                    multiple={false}
                    onChange={handleSelectDoc}
                  />
                  <button
                    className="uk-button uk-button-danger uk-button-small"
                    type="button"
                    tabIndex={-1}
                  >
                    {doc ? "Documento" : "Selecione..."}
                  </button>
                </div>
              </div>
              <div className="uk-width-1-4@s">
                <div className="uk-width-1@s">
                  <label className="uk-form-label" htmlFor="year">
                    Ano
                  </label>
                  <input
                    type="text"
                    id="year"
                    name="year"
                    placeholder="0000"
                    className="uk-input uk-form-small"
                    defaultValue={memberAnnuity?.year}
                    ref={register}
                    autoComplete={"off"}
                    onFocus={handleFocus}
                    maxLength={4}
                    onChange={(e) => {
                      e.target.value = numberMask(e.target.value);
                    }}
                  />
                  <small className="uk-text-danger">
                    {errors.year?.message}
                  </small>
                </div>
                <div className="uk-width-1@s">
                  <div className="uk-form-controls">
                    <label className="uk-form-label" htmlFor="type">
                      Convenção
                    </label>
                    <div
                      uk-form-custom="target: > * > span:first-child"
                      className="uk-width-1"
                    >
                      <select
                        className="uk-form-small"
                        id="type"
                        placeholder="Perfil"
                        name="type"
                        defaultValue={memberAnnuity?.type}
                        ref={register}
                      >
                        <option value="">Selecione...</option>
                        <option value={AnnuityTypeFile.CGADB}>CGADB</option>
                        <option value={AnnuityTypeFile.CONFRADESP}>
                          CONFRADESP
                        </option>
                        <option value={AnnuityTypeFile.OTHER}>Outra</option>
                      </select>
                      <button
                        className="uk-button uk-button-default uk-form-small uk-width-1 uk-flex uk-flex-middle uk-flex-between"
                        style={{ textTransform: "none" }}
                        type="button"
                        tabIndex={-1}
                      >
                        <span></span>
                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                  </div>
                  <small className="uk-text-danger">
                    {errors.type?.message}
                  </small>
                </div>
              </div>
              <div className="uk-width-3-4@s">
                <label className="uk-form-label" htmlFor="note">
                  Observações
                </label>
                <textarea
                  id="note"
                  name="note"
                  className="uk-textarea uk-form-small uk-text-uppercase"
                  rows={4}
                  style={{ resize: "none" }}
                  defaultValue={memberAnnuity?.note}
                  autoComplete={"off"}
                  ref={register}
                />
                <small className="uk-text-danger">{errors.note?.message}</small>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer">
            <div
              className="uk-grid-small uk-child-width-1-4@s uk-flex-center uk-text-center"
              uk-grid="true"
            >
              <button
                className="uk-button uk-button-default uk-modal-close uk-margin-left"
                onClick={handleCloseModal}
                type="button"
              >
                Cancelar
              </button>
              <button
                disabled={formState.isSubmitting || isReadOnly}
                className="uk-button uk-button-primary uk-margin-left"
                type="submit"
              >
                {formState.isSubmitting && <div uk-spinner="true"></div>}
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
